import {
  TextField,
  Box,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  Switch,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { Toastify } from "@/utils/toast";
import * as yup from "yup";
import "./Aspect.step.styles.scss";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import { TCountry } from "@/features/profile/types";
import { InlineIcon } from "@iconify/react";
import { MultipleSelectChip } from "@/components/MultipleSelectChip/MultipleSelectChip";
import {
  apiSaveAspectFemale,
  apiSaveAspectMale,
} from "@/features/profile/api/profile.api";
import { store } from "@/store";
import { updateMemberProfile } from "@/store/reducers/member/member.actions";
import classNames from "classnames";
import { checkTrueOrFalse } from "../../../utils/account.utils";
import { TMemberProfile } from "@/features/auth/types";
import { MuiTelInput } from "mui-tel-input";
import * as _ from "lodash";

interface AspectStepProps {
  chooseStep: React.Dispatch<React.SetStateAction<number>>;
  scrollTop: () => void;
}
const AspectStep = ({ chooseStep, scrollTop }: AspectStepProps) => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const [loading, setLoading] = useState(false);

  const tenues_vestimentaires_femmes_list = [
    "Ne porte pas encore le voile",
    "Porte le turban",
    "Porte le Hijab",
    "Porte le Jilbab",
    "Porte le Niqab",
    "L'Abaya",
  ];

  const [origine, setOrigine] = useState<TCountry>({
    country:
      memberProfileFromStore?.infos?.religiousAspects?.hijraCountry || "",
    abbreviation:
      memberProfileFromStore?.infos?.religiousAspects?.hijraCountryCode || "",
  });

  const handleOrigineChange = (event: any, value: TCountry | null) => {
    console.log(value);
    if (value == null) {
      setOrigine({ country: "", abbreviation: "" });
      formik.setFieldValue("pays_hijra", "");
    } else {
      setOrigine(value as TCountry);
    }
  };

  const [countries, setCountries] = React.useState<
    Array<{ country: string; abbreviation: string }>
  >([]);

  useEffect(() => {
    const countries_json_data = require("@/assets/json/countries.json");
    setCountries(countries_json_data);

    console.log("Heyy", memberProfileFromStore?.infos?.religiousAspects);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tenue_vestimentaire_femmeArrayManager = (
    not_wear_headscarf: boolean,
    wear_turban: boolean,
    wear_hijab: boolean,
    wear_jilbab: boolean,
    wear_niqab: boolean,
    wear_abaya: boolean
  ): string[] => {
    let result: string[] = [];

    not_wear_headscarf && result.push("Ne porte pas encore le voile");
    wear_turban && result.push("Porte le turban");
    wear_hijab && result.push("Porte le Hijab");
    wear_jilbab && result.push("Porte le Jilbab");
    wear_niqab && result.push("Porte le Niqab");
    wear_abaya && result.push("L'Abaya");

    return result;
  };

  const originInputref = useRef(null);

  const handleChangePhoneValue = (newValue: string) => {
    formik.setFieldValue("mahram_phone_number", newValue);
  };

  const validationSchema = yup.object({
    savants: yup
      .string()
      .required(" Les savants que vous suivez sont obligatoires "),
    predicateurs: yup
      .string()
      .required(" Les prédicateurs que vous écoutez sont obligatoires "),
    tenue_vestimentaire_homme: yup.string().test({
      name: "required_tenue_homme",
      message: " la tenue de l'homme est obligatoire",
      test: function (value) {
        let result = true;
        if (
          memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ===
            "male" &&
          value === undefined
        ) {
          result = false;
        }
        return result;
      },
    }),
    tenue_vestimentaire_femme: yup.array().test({
      name: "required_tenue_femme",
      message: " la tenue de la femme est obligatoire",
      test: function (value) {
        let result = true;
        if (
          memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ===
            "female" &&
          value?.length === 0
        ) {
          result = false;
        }
        return result;
      },
    }),
    pays_hijra: yup.string().when("origine", (val, schema) => {
      if (formik.values.hijra) {
        if (originInputref.current) {
          let origin_val =
            (originInputref as React.MutableRefObject<any>).current.value || "";
          if (origin_val === "") {
            return yup.string().required("le pays est obligatoire");
          } else if (getAbbreviation(origin_val) === "") {
            setOrigine({
              country: origin_val,
              abbreviation: getAbbreviation(origin_val),
            });
            return yup.string().test({
              name: "incorrect_country",
              message: " Ce pays n'est pas valide ",
              test: function (value) {
                let result = true;
                if (getAbbreviation(origin_val) === "") {
                  result = false;
                }
                return result;
              },
            });
          } else {
            setOrigine({
              country: origin_val,
              abbreviation: getAbbreviation(origin_val),
            });
            return yup.string().notRequired();
          }
        } else {
          return yup.string().required("le pays est obligatoire");
        }
      } else {
        return yup.string().notRequired();
      }
    }),
  });

  const formik = useFormik({
    initialValues: {
      salafi: checkTrueOrFalse(
        memberProfileFromStore?.infos?.religiousAspects?.isSalafi
      ),
      tenue_vestimentaire_femme:
        tenue_vestimentaire_femmeArrayManager(
          memberProfileFromStore?.infos?.religiousAspects?.femaleDressCode
            ?.notWearHeadscarf,
          memberProfileFromStore?.infos?.religiousAspects?.femaleDressCode
            ?.wearTurban,
          memberProfileFromStore?.infos?.religiousAspects?.femaleDressCode
            ?.wearHijab,
          memberProfileFromStore?.infos?.religiousAspects?.femaleDressCode
            ?.wearJilbab,
          memberProfileFromStore?.infos?.religiousAspects?.femaleDressCode
            ?.wearNiqab,
          memberProfileFromStore?.infos?.religiousAspects?.femaleDressCode
            ?.wearAbaya
        ) || [],
      tenue_vestimentaire_homme:
        memberProfileFromStore?.infos?.religiousAspects?.maleDressCode || "",
      savants:
        memberProfileFromStore?.infos?.religiousAspects?.favoriteScholars || "",
      predicateurs:
        memberProfileFromStore?.infos?.religiousAspects?.favoritePreachers ||
        "",
      jurisprudence:
        memberProfileFromStore?.infos?.religiousAspects
          ?.haveSchoolOfJurisprudence || "",
      hijra: checkTrueOrFalse(
        memberProfileFromStore?.infos?.religiousAspects?.isInHijra
      ),
      pays_hijra: origine.country || "",
      souhait_hijra: checkTrueOrFalse(
        memberProfileFromStore?.infos?.religiousAspects?.likeToMakeHijra
      ),
      temps_hijra:
        memberProfileFromStore?.infos?.religiousAspects?.whenToMakeHijra || "",
      demenager: checkTrueOrFalse(
        memberProfileFromStore?.infos?.religiousAspects?.agreeToMove
      ),
      accept_married_man: checkTrueOrFalse(
        memberProfileFromStore?.infos?.religiousAspects?.acceptMarriedMan
      ),
      have_mahram: checkTrueOrFalse(
        memberProfileFromStore?.infos?.religiousAspects?.haveMahram
      ),
      mahram_phone_number:
        memberProfileFromStore?.infos?.religiousAspects?.mahramPhoneNumber ||
        "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);

      if (_.isEqual(formik.initialValues, values)) {
        Toastify(
          `Aucun changement apporté à votre aspect réligieux.`,
          "warning",
          3000
        );
      } else {
        if (
          memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ===
          "female"
        ) {
          const dataToSend = {
            category: "religiousAspects",
            isSalafi: values.salafi,
            maleDressCode: "",
            femaleDressCode: {
              notWearHeadscarf:
                (values.tenue_vestimentaire_femme as string[]).indexOf(
                  "Ne porte pas encore le voile"
                ) > -1,
              wearTurban:
                (values.tenue_vestimentaire_femme as string[]).indexOf(
                  "Porte le turban"
                ) > -1,
              wearHijab:
                (values.tenue_vestimentaire_femme as string[]).indexOf(
                  "Porte le Hijab"
                ) > -1,
              wearJilbab:
                (values.tenue_vestimentaire_femme as string[]).indexOf(
                  "Porte le Jilbab"
                ) > -1,
              wearNiqab:
                (values.tenue_vestimentaire_femme as string[]).indexOf(
                  "Porte le Niqab"
                ) > -1,
              wearAbaya:
                (values.tenue_vestimentaire_femme as string[]).indexOf(
                  "L'Abaya"
                ) > -1,
            },
            favoriteScholars: values.savants.trim(),
            favoritePreachers: values.predicateurs.trim(),
            haveSchoolOfJurisprudence: values.jurisprudence.trim(),
            isInHijra: values.hijra,
            hijraCountry: origine.country,
            hijraCountryCode: origine.abbreviation,
            likeToMakeHijra: values.souhait_hijra,
            whenToMakeHijra: values.temps_hijra.trim(),
            agreeToMove: values.demenager,
            acceptMarriedMan: values.accept_married_man,
            haveMahram: values.have_mahram,
            mahramPhoneNumber: values.mahram_phone_number.trim(),
          };
          console.log(dataToSend);
          aspectStepSubmit(dataToSend);
        } else {
          const dataToSend = {
            category: "religiousAspects",
            isSalafi: values.salafi,
            maleDressCode: values.tenue_vestimentaire_homme.trim(),
            femaleDressCode: {
              notWearHeadscarf: false,
              wearTurban: false,
              wearHijab: false,
              wearJilbab: false,
              wearNiqab: false,
              wearAbaya: false,
            },
            favoriteScholars: values.savants.trim(),
            favoritePreachers: values.predicateurs.trim(),
            haveSchoolOfJurisprudence: values.jurisprudence.trim(),
            isInHijra: values.hijra,
            hijraCountry: origine.country.trim(),
            hijraCountryCode: origine.abbreviation.trim(),
            likeToMakeHijra: values.souhait_hijra,
            whenToMakeHijra: values.temps_hijra.trim(),
            agreeToMove: values.demenager,
            acceptMarriedMan: values.accept_married_man,
            haveMahram: values.have_mahram,
            mahramPhoneNumber: values.mahram_phone_number.trim(),
          };
          console.log(dataToSend);
          aspectStepSubmit(dataToSend);
        }
      }
    },
  });

  const aspectStepSubmit = (data: any) => {
    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
    setLoading(true);

    if (
      memberProfileFromStore?.infos?.identity?.gender.toLowerCase() === "female"
    ) {
      apiSaveAspectFemale(data)
        .then((response: any) => {
          setLoading(false);
          console.log(response);
          const profile = response.data as TMemberProfile;
          store.dispatch(updateMemberProfile({ ...profile }));
          Toastify(
            `Les informations de votre aspect réligieux ont bien été soumises à notre équipe de modération`,
            "success",
            5000
          );
          chooseStep(2);
          scrollTop();
        })
        .catch((error: any) => {
          setLoading(false);

          console.log(error);
          error?.response?.data?.message &&
            Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });
    } else {
      apiSaveAspectMale(data)
        .then((response: any) => {
          setLoading(false);
          console.log(response);
          const profile = response.data as TMemberProfile;
          store.dispatch(updateMemberProfile({ ...profile }));
          Toastify(
            `Les informations de votre aspect réligieux ont bien été soumises à notre équipe de modération`,
            "success",
            5000
          );
          chooseStep(2);
          scrollTop();
        })
        .catch((error: any) => {
          setLoading(false);

          console.log(error);
          error?.response?.data?.message &&
            Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });
    }
  };

  const reset = () => {
    formik.setValues({
      salafi: false,
      tenue_vestimentaire_femme: [],
      tenue_vestimentaire_homme: "",
      savants: "",
      predicateurs: "",
      jurisprudence: "",
      hijra: false,
      pays_hijra: "",
      souhait_hijra: false,
      temps_hijra: "",
      demenager: false,
      accept_married_man: false,
      have_mahram: false,
      mahram_phone_number: "",
    });
    setOrigine({ country: "", abbreviation: "" });
  };

  const submitClick = () => {
    if (Object.keys(formik.errors).length > 0) {
      Toastify(
        `Il y a une erreur dans le formulaire. Veuillez remplir correctement le formulaire svp!`,
        "warning",
        5000
      );
    }
  };

  const getAbbreviation = (selected_country: string): string => {
    let result = "";

    result =
      countries.find((x) => x.country === selected_country)?.abbreviation || "";

    return result;
  };

  return (
    <form className="form-part-aspect" onSubmit={formik.handleSubmit}>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={4} columnSpacing={2}>
          <Grid item xs={12} sm={12} lg={6}>
            <div className="switch-container">
              <Typography> Êtes-vous salafi ? </Typography>
              <FormGroup>
                <FormControlLabel
                  label={formik.values.salafi ? "Oui" : "Non"}
                  control={
                    <Switch
                      name="salafi"
                      checked={formik.values.salafi}
                      onChange={formik.handleChange}
                    />
                  }
                />
              </FormGroup>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={6}>
            {memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ===
              "male" && (
              <>
                <TextField
                  placeholder="Votre tenue vestimentaire"
                  variant="outlined"
                  label="Votre tenue vestimentaire"
                  name="tenue_vestimentaire_homme"
                  onChange={formik.handleChange}
                  value={formik.values.tenue_vestimentaire_homme}
                  error={
                    formik.touched.tenue_vestimentaire_homme &&
                    Boolean(formik.errors.tenue_vestimentaire_homme)
                  }
                  helperText={
                    formik.touched.tenue_vestimentaire_homme &&
                    (formik.errors.tenue_vestimentaire_homme as string)
                  }
                  type="text"
                />
              </>
            )}

            {memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ===
              "female" && (
              <>
                <MultipleSelectChip
                  name="tenue_vestimentaire_femme"
                  selectOptions={tenues_vestimentaires_femmes_list}
                  value={formik.values.tenue_vestimentaire_femme}
                  onChange={formik.handleChange}
                  labelId="id-select-tenue-femme"
                  label="Votre tenue vestimentaire"
                  errorText={formik.errors.tenue_vestimentaire_femme}
                  error={
                    formik.touched.tenue_vestimentaire_femme &&
                    Boolean(formik.errors.tenue_vestimentaire_femme)
                  }
                />
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <div
              className={classNames("text-area", {
                error: formik.touched.savants && Boolean(formik.errors.savants),
              })}
            >
              <Typography className="text-area-label">
                {" "}
                Les savants que vous suivez
              </Typography>
              <textarea
                className="text-area-input"
                rows={4}
                name="savants"
                onChange={formik.handleChange}
                value={formik.values.savants}
              ></textarea>
              {formik.touched.savants && Boolean(formik.errors.savants) && (
                <FormHelperText>
                  {" "}
                  {formik.errors.savants as string}{" "}
                </FormHelperText>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <div
              className={classNames("text-area", {
                error:
                  formik.touched.predicateurs &&
                  Boolean(formik.errors.predicateurs),
              })}
            >
              <Typography className="text-area-label">
                {" "}
                Les prédicateurs que vous écoutez{" "}
              </Typography>
              <textarea
                className="text-area-input"
                rows={4}
                name="predicateurs"
                onChange={formik.handleChange}
                value={formik.values.predicateurs}
              ></textarea>
              {formik.touched.predicateurs &&
                Boolean(formik.errors.predicateurs) && (
                  <FormHelperText>
                    {" "}
                    {formik.errors.predicateurs as string}{" "}
                  </FormHelperText>
                )}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <TextField
              placeholder="Suivez-vous une école de jurisprudence ? Si oui laquelle?"
              variant="outlined"
              label="Suivez-vous une école de jurisprudence ?"
              name="jurisprudence"
              onChange={formik.handleChange}
              value={formik.values.jurisprudence}
              error={
                formik.touched.jurisprudence &&
                Boolean(formik.errors.jurisprudence)
              }
              helperText={
                formik.touched.jurisprudence &&
                (formik.errors.jurisprudence as string)
              }
              type="text"
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={12} xl={6}>
            <div className="switch-container">
              <Typography> Êtes-vous en hijra ? </Typography>
              <FormGroup>
                <FormControlLabel
                  label={formik.values.hijra ? "Oui" : "Non"}
                  control={
                    <Switch
                      name="hijra"
                      checked={formik.values.hijra}
                      onChange={formik.handleChange}
                    />
                  }
                />
              </FormGroup>
            </div>
          </Grid>

          {formik.values.hijra && (
            <>
              <Grid item xs={12} sm={12} lg={6}>
                <Autocomplete
                  id="country-select-origine"
                  options={countries}
                  value={origine}
                  onChange={handleOrigineChange}
                  autoHighlight
                  getOptionLabel={(option) => option.country}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <InlineIcon
                        style={{ borderRadius: "50%" }}
                        icon={`flag:${option.abbreviation.toLowerCase()}-1x1`}
                      />{" "}
                      &nbsp;
                      {option.country}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <>
                      <InlineIcon
                        className="flag"
                        icon={`flag:${origine.abbreviation.toLowerCase()}-1x1`}
                      />
                      <TextField
                        {...params}
                        placeholder="Dans quel pays êtes-vous ?"
                        variant="outlined"
                        label="Dans quel pays êtes-vous ?"
                        name="pays_hijra"
                        inputRef={originInputref}
                        onChange={formik.handleChange}
                        value={formik.values.pays_hijra}
                        error={
                          formik.touched.pays_hijra &&
                          Boolean(formik.errors.pays_hijra)
                        }
                        helperText={
                          formik.touched.pays_hijra && formik.errors.pays_hijra
                        }
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
            </>
          )}

          {!formik.values.hijra && (
            <>
              <Grid item xs={12} sm={12} lg={12} xl={6}>
                <div className="switch-container">
                  <Typography>
                    {" "}
                    Souhaitez-vous faire la hijra ? Si oui, quand ?{" "}
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      label={formik.values.souhait_hijra ? "Oui" : "Non"}
                      control={
                        <Switch
                          name="souhait_hijra"
                          checked={formik.values.souhait_hijra}
                          onChange={formik.handleChange}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>
              {formik.values.souhait_hijra && (
                <>
                  <Grid item xs={12} sm={12} lg={12}>
                    <TextField
                      placeholder="Dans combien de temps ?"
                      variant="outlined"
                      label="Dans combien de temps ?"
                      name="temps_hijra"
                      onChange={formik.handleChange}
                      value={formik.values.temps_hijra}
                      error={
                        formik.touched.temps_hijra &&
                        Boolean(formik.errors.temps_hijra)
                      }
                      helperText={
                        formik.touched.temps_hijra &&
                        (formik.errors.temps_hijra as string)
                      }
                      type="text"
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          {memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ===
            "male" && (
            <>
              <Grid item xs={12} sm={12} lg={12}>
                <div className="switch-container">
                  <Typography> Acceptez-vous de déménager ? </Typography>
                  <FormGroup>
                    <FormControlLabel
                      label={formik.values.demenager ? "Oui" : "Non"}
                      control={
                        <Switch
                          name="demenager"
                          checked={formik.values.demenager}
                          onChange={formik.handleChange}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>
            </>
          )}

          {memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ===
            "female" && (
            <>
              <Grid item xs={12} sm={12} lg={6}>
                <div className="switch-container">
                  <Typography> Acceptez-vous de déménager ? </Typography>
                  <FormGroup>
                    <FormControlLabel
                      label={formik.values.demenager ? "Oui" : "Non"}
                      control={
                        <Switch
                          name="demenager"
                          checked={formik.values.demenager}
                          onChange={formik.handleChange}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <div className="switch-container">
                  <Typography> Acceptez-vous les hommes mariés ? </Typography>
                  <FormGroup>
                    <FormControlLabel
                      label={formik.values.accept_married_man ? "Oui" : "Non"}
                      control={
                        <Switch
                          name="accept_married_man"
                          checked={formik.values.accept_married_man}
                          onChange={formik.handleChange}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                lg={formik.values.have_mahram ? 6 : 12}
              >
                <div className="switch-container">
                  <Typography> Avez-vous un Tuteur ? </Typography>
                  <FormGroup>
                    <FormControlLabel
                      label={formik.values.have_mahram ? "Oui" : "Non"}
                      control={
                        <Switch
                          name="have_mahram"
                          checked={formik.values.have_mahram}
                          onChange={formik.handleChange}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </Grid>
              {formik.values.have_mahram && (
                <Grid item xs={12} sm={12} lg={6}>
                  <MuiTelInput
                    placeholder="Téléphone de votre Tuteur"
                    variant="outlined"
                    label="Téléphone de votre Tuteur"
                    onChange={handleChangePhoneValue}
                    name="mahram_phone_number"
                    value={formik.values.mahram_phone_number}
                    error={
                      formik.touched.mahram_phone_number &&
                      Boolean(formik.errors.mahram_phone_number)
                    }
                    helperText={
                      formik.touched.mahram_phone_number &&
                      (formik.errors.mahram_phone_number as string)
                    }
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>

      <Grid
        container
        rowSpacing={4}
        columnSpacing={2}
        sx={{ justifyContent: "flex-end", marginTop: "30px" }}
      >
        <Grid item xs={12} sm={12} lg={3} xl={2}>
          <Button
            variant="outlined"
            color="tertiary"
            onClick={() => {
              reset();
            }}
          >
            {" "}
            Effacer
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} xl={5}>
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            variant="contained"
            color="primary"
            type="submit"
            onClick={submitClick}
          >
            {" "}
            Valider et passer à pratiques réligieuses
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default AspectStep;
