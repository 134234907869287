import { TMemberCurrentExchange } from "@/features/profile/types";
import {
  Button,
  IconButton,
  Modal,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import EchangeInfos from "../components/Echange-Infos/EchangeInfos";
import EchangeMessage from "../components/Echanges-message/EchangeMessage";
import "./DetailEchange.styles.scss";
import { store } from "@/store";
import { updateOpenDiscussion } from "@/store/reducers/general_param/general_param.actions";
import { InlineIcon } from "@iconify/react";
import { useTour } from "@reactour/tour";
import { selectTourStepIsOpen } from "@/store/reducers/general_param/general_param.selector";
import { useSelector } from "react-redux";

interface DetailEchangeProps {
  memberCurrentExchange: TMemberCurrentExchange;
}
const DetailEchange = ({ memberCurrentExchange }: DetailEchangeProps) => {
  const theme = useTheme();
  const matches_xl = useMediaQuery(theme.breakpoints.down("xl"));
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const { setIsOpen } = useTour();
  const tour_step_is_open_from_store = useSelector(selectTourStepIsOpen);

  const [toogleTopPartInConversation, setToogleTopPartInConversation] =
    React.useState(true);
  const handleToogleTopPartInConversation = () =>
    setToogleTopPartInConversation(!toogleTopPartInConversation);

  useEffect(() => {
    if (matches_xl) {
      setIsOpen(tour_step_is_open_from_store);
    }
  }, [matches_xl, setIsOpen, tour_step_is_open_from_store]);

  return (
    <>
      <div className="detail-exchange-container">
        {!matches_xl && (
          <div className="infos-part">
            {" "}
            <EchangeInfos
              isDiscussing={true}
              memberCurrentExchange={memberCurrentExchange}
            />
          </div>
        )}
        {matches_xl && (
          <div className="option-part">
            <Button
              color="primary"
              className="back-btn"
              startIcon={<InlineIcon icon="akar-icons:arrow-back" />}
              onClick={() => {
                store.dispatch(updateOpenDiscussion(false));
              }}
            >
              Retour
            </Button>

            <Button
              color="primary"
              variant="contained"
              className="detail-btn"
              startIcon={
                <InlineIcon
                  icon={
                    toogleTopPartInConversation
                      ? "heroicons-outline:chevron-up"
                      : "heroicons-outline:chevron-down"
                  }
                />
              }
              onClick={handleToogleTopPartInConversation}
            >
              {toogleTopPartInConversation ? "Masquer détails" : "Voir détails"}
            </Button>

            <Button
              color="primary"
              className="opt-btn"
              startIcon={<InlineIcon icon="icon-park-twotone:help" />}
              onClick={handleOpenModal}
            >
              Aide
            </Button>
          </div>
        )}
        <div className="message-part">
          {" "}
          <EchangeMessage
            toogleTopPartInConversation={toogleTopPartInConversation}
            setToogleTopPartInConversation={setToogleTopPartInConversation}
            memberCurrentExchange={memberCurrentExchange}
          />
        </div>
      </div>

      {/* MODALS PART */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openModal} mountOnEnter unmountOnExit>
          <div className="modal-detail_exchange-container">
            <div className="modal-detail_exchange-container__title-part">
              <IconButton
                className="back-icon"
                size="medium"
                color="secondary"
                onClick={handleCloseModal}
              >
                <InlineIcon icon="tabler:chevron-left" />
              </IconButton>
              <div className="title-infos-username-age">
                <div className="username-age">
                  <p className="username">Options de conversation</p>
                </div>
              </div>
              <IconButton
                className="close-icon"
                size="medium"
                color="secondary"
                onClick={handleCloseModal}
              >
                <InlineIcon icon="iconamoon:close-bold" />
              </IconButton>
            </div>
            <div className="modal-detail_exchange-container__body-part">
              <>
                <EchangeInfos
                  isDisplayedInModal={true}
                  isDiscussing={true}
                  memberCurrentExchange={memberCurrentExchange}
                />
              </>
            </div>
          </div>
        </Slide>
      </Modal>
    </>
  );
};

export default DetailEchange;
