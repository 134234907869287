import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";

export const apiGetSubscriptionPlan = (): Promise<AxiosResponse<any, any>> => {
  return axios.get("/payment-s/api/v1/payments-plans-subscriptions");
};
export const apiActivateAccount = (
  data: any
): Promise<AxiosResponse<any, any>> => {
  return axios.post("/payment-s/api/v1/purchases", data);
};
export const apiActivateAccountPaypal = (
  data: any
): Promise<AxiosResponse<any, any>> => {
  return axios.post("/payment-s/api/v1/purchases", data);
};

export const apiDesactivateAccount = (
  data: string,
  dataTosend: any
): Promise<AxiosResponse<any, any>> => {
  return axios.patch(
    "/payment-s/api/v1/purchases/" + data + "/cancel-purchase",
    dataTosend
  );
};

export const apiGetPurchaseInfo = (
  data: string
): Promise<AxiosResponse<any, any>> => {
  return axios.get("/payment-s/api/v1/purchases/" + data);
};

export const apiDeleteAccount = (
  data: any
): Promise<AxiosResponse<any, any>> => {
  return axios.post("/auth-s/api/v1/auth/delete/by-member", data);
};
