import React from "react";
import { routePaths } from "@/config";
import { selectToken } from "@/store/reducers/member/member.selector";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const token = useSelector(selectToken);

  return (
    <>{token.length === 0 ? children : <Navigate to={routePaths.profile} />}</>
  );
};

export default ProtectedRoute;
