import {
  TUserConnected,
  TMemberInfos,
  TMemberCurrentExchange,
} from "@/features/profile/types";
import { MemberAction } from "./member.actions";
import memberTypes from "./member.types";
import { TMemberProfile } from "@/features/auth/types";

interface IMemberReducerState {
  user_connected: TUserConnected | null;
  member_infos: TMemberInfos | null;
  member_profile: TMemberProfile | null;
  member_current_exchange: TMemberCurrentExchange | null;
  token: string;
  progress: number;
  location: {
    latitude: number;
    longitude: number;
  };
  nbre_message_notif: number;
  nbre_favorite_notif: number;
  nbre_exchange_notif: number;
  new_modification_submit: boolean;
}

const INITIAL_STATE = {
  user_connected: null,
  member_infos: null,
  member_profile: null,
  member_current_exchange: null,
  token: "",
  progress: 10,
  location: { latitude: 0, longitude: 0 },
  nbre_message_notif: 0,
  nbre_favorite_notif: 0,
  nbre_exchange_notif: 0,
  new_modification_submit: false,
};

const memberReducer = (
  state: IMemberReducerState = INITIAL_STATE,
  action: MemberAction
): IMemberReducerState => {
  switch (action.type) {
    case memberTypes.GET_USER_CONNECTED:
      return {
        ...state,
        user_connected: action.payload,
      };
    case memberTypes.GET_USER_SUBSCRIBED:
      return {
        ...state,
        member_infos: action.payload,
      };

    case memberTypes.UPDATE_MEMBER_PROFILE:
      return {
        ...state,
        member_profile: action.payload,
      };

    case memberTypes.UPDATE_MEMBER_CURRENT_EXCHANGE:
      return {
        ...state,
        member_current_exchange: action.payload,
      };

    case memberTypes.GET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case memberTypes.UPDATE_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };

    case memberTypes.UPDATE_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    case memberTypes.UPDATE_PROFILE:
      return {
        ...state,
        member_profile: {
          ...(state.member_profile as TMemberProfile),
          infos: {
            ...(state.member_profile as TMemberProfile).infos,
            profile: action.payload,
          },
        },
      };

    case memberTypes.UPDATE_RELIGIOUS_ASPECT:
      return {
        ...state,
        member_profile: {
          ...(state.member_profile as TMemberProfile),
          infos: {
            ...(state.member_profile as TMemberProfile).infos,
            religiousAspects: action.payload,
          },
        },
      };

    case memberTypes.UPDATE_RELIGIOUS_PRACTICES:
      return {
        ...state,
        member_profile: {
          ...(state.member_profile as TMemberProfile),
          infos: {
            ...(state.member_profile as TMemberProfile).infos,
            religiousPractices: action.payload,
          },
        },
      };

    case memberTypes.UPDATE_HEALTH_AND_DISABILITY:
      return {
        ...state,
        member_profile: {
          ...(state.member_profile as TMemberProfile),
          infos: {
            ...(state.member_profile as TMemberProfile).infos,
            healthAndDisability: action.payload,
          },
        },
      };

    case memberTypes.UPDATE_IS_APPROVED:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            is_approved: action.payload,
          },
        },
      };

    case memberTypes.UPDATE_IS_ACTIVE:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            is_active: action.payload,
          },
        },
      };

    case memberTypes.UPDATE_IS_ONLINE:
      return {
        ...state,
        member_profile: {
          ...(state.member_profile as TMemberProfile),
          availability: {
            ...(state.member_profile as TMemberProfile).availability,
            isAvailable: action.payload,
          },
        },
      };

    case memberTypes.UPDATE_IS_FRESHNEW:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            is_fresh_new: action.payload,
          },
        },
      };

    case memberTypes.UPDATE_IS_FICHE_FILLED:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            are_main_infos_provided: action.payload,
          },
        },
      };

    case memberTypes.UPDATE_IS_SUBSCRIPTION_ACTIVE:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            is_subscription_active: action.payload,
          },
        },
      };

    case memberTypes.UPDATE_NOTIFICATION:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          notifications: action.payload,
        },
      };

    case memberTypes.UPDATE_MESSAGE_NOTIF:
      return {
        ...state,
        nbre_message_notif: action.payload,
      };

    case memberTypes.UPDATE_FAVORITE_NOTIF:
      return {
        ...state,
        nbre_favorite_notif: action.payload,
      };

    case memberTypes.UPDATE_EXCHANGE_NOTIF:
      return {
        ...state,
        nbre_exchange_notif: action.payload,
      };

    case memberTypes.UPDATE_PENDING_CANCEL_CONVERSATION:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            pending_cancel_conversation: action.payload,
          },
        },
      };

    case memberTypes.UPDATE_ACCEPT_EXCHANGE:
      return {
        ...state,
        user_connected: {
          ...(state.user_connected as TUserConnected),
          account: {
            ...(state.user_connected as TUserConnected).account,
            has_conversation: action.payload,
          },
        },
      };

    case memberTypes.UPDATE_NEW_MODIFICATION_SUBMIT:
      return {
        ...state,
        new_modification_submit: action.payload,
      };

    default:
      return state;
  }
};

export default memberReducer;
