import { AuthRoutes } from "@/features/auth";
import { Navigate, RouteObject } from "react-router-dom";
import { routePaths } from "../config";
import ProtectedRoute from "./ProtectedRoutes";
import { LandingRoutes } from "@/features/landing";

export const publicRoutes: RouteObject[] = [
  {
    path: routePaths.auth + "/*",
    element: (
      <ProtectedRoute>
        {" "}
        <AuthRoutes />{" "}
      </ProtectedRoute>
    ),
  },
  { path: "*", element: <Navigate to={`${routePaths.auth}`} /> },
  // {
  //   path: "",
  //   element: <LandingRoutes />,
  // },
  // { path: "*", element: <Navigate to="" /> },
];
