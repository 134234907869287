import Checklist from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import ImageTool from "@editorjs/image";
import InlineCode from "@editorjs/inline-code";
import List from "@editorjs/list";
import Underline from "@editorjs/underline";
import Warning from "@editorjs/warning";
import Alert from "editorjs-alert";
const Header = require("editorjs-header-with-alignment");
const ColorPlugin = require("editorjs-text-color-plugin");
const Paragraph = require("editorjs-paragraph-with-alignment");
export const EDITOR_JS_TOOLS = {
  // Block tools
  header: {
    class: Header,
    inlineToolbar: true,
    config: {
      placeholder: "Entrer un titre",
      levels: [1, 2, 3],
      defaultLevel: 1,
    },
  },
  image: {
    class: ImageTool,
    config: {
      endpoints: {
        byFile:
          "https://8eeed80be3c401f9ca7452154d79cf6b.serveo.net/api/v1/categories/create", // Your backend file uploader endpoint
        byUrl:
          "https://8eeed80be3c401f9ca7452154d79cf6b.serveo.net/api/v1/assets/static/images/categories/", // Your endpoint that provides uploading by Url
      },
    },
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  // list: {
  //   class: List,
  //   inlineToolbar: true,
  //   config: {
  //     style: "unordered",
  //   },
  // },
  list: {
    class: List,
    inlineToolbar: true,
    config: {
      style: "ordered",
    },
  },
  delimiter: {
    class: Delimiter,
    inlineToolbar: true,
    shortcut: "CMD+SHIFT+D",
  },
  checklist: {
    class: Checklist,
    inlineToolbar: true,
  },
  alert: {
    class: Alert,
    inlineToolbar: true,
    shortcut: "CMD+SHIFT+A",
  },

  // title: Title,
  // quote: Quote,
  warning: Warning,
  // Inline tools
  Marker: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      defaultColor: "#FFBF00",
      type: "marker",
      icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`,
    },
  },
  Color: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      colorCollections: [
        "#000000",
        "#DEB86B",
        "#FEF8EC",
        "#73103E",
        "#d62828",
        "#fcbf49",
        "#EC7878",
        "#0070FF",
        "#03A9F4",
        "#00BCD4",
        "#4CAF50",
        "#8BC34A",
        "#CDDC39",
        "#FFF",
      ],
      type: "text",
      // customPicker: true, // add a button to allow selecting any colour
    },
  },
  inlineCode: InlineCode,

  underline: {
    class: Underline,
    shortcut: "CMD+SHIFT+U",
  },
};
