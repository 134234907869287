import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import { InlineIcon } from "@iconify/react";
import { Button, IconButton } from "@mui/material";
import { HighlightOffRounded } from "@mui/icons-material";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import "./ModalRemindPay.styles.scss";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";
import { TMemberProfile } from "@/features/auth/types";

interface CModalRemindPayProps {
  open: boolean;
}

const CModalRemindPay = ({ open }: CModalRemindPayProps) => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const [openM, setOpenM] = useState(open);
  const navigate = useNavigate();

  const closeModal = () => {
    setOpenM(false);
  };
  const proceedPayment = () => {
    setOpenM(false);
    navigate(
      routePaths.profileChildren.subscriptionChildren
        .desactivateSubscriptionAbsolute,
      { replace: true }
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openM}
      onClose={closeModal}
    >
      <Grow in={openM}>
        <div className="c-modal-remind-pay ">
          <IconButton
            className="close-icon"
            size="large"
            color="inherit"
            onClick={closeModal}
          >
            <HighlightOffRounded />
          </IconButton>
          <img
            src={require(
              "@/assets/images/png/avatar-" +
                (memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ||
                  "male") +
                ".png"
            )}
            alt="sidebar-logo"
            className="modal-avatar"
          />

          <h3>
            <b> PAIEMENT </b>
          </h3>
          <br />
          <p>
            Votre compte n'est pas actif.
            <br />
            Pour profiter pleinement de l'application veuillez procéder au
            &nbsp;
            <b> paiement de votre abonnement</b>
          </p>

          <Button
            className="btn-modal"
            variant="contained"
            color="primary"
            onClick={proceedPayment}
            endIcon={<InlineIcon icon="fluent:payment-32-regular" />}
          >
            Procéder au paiement
          </Button>
        </div>
      </Grow>
    </Modal>
  );
};

export default CModalRemindPay;
