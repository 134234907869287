import { InlineIcon } from "@iconify/react";
import {
  Button,
  Chip,
  Grow,
  InputAdornment,
  SpeedDial,
  SpeedDialAction,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import InfiniteScroll from "react-infinite-scroll-component";
import ImamSpeakerCard, {
  TImamSpeakerCard,
} from "../../Elements/ImamSpeakerCard/ImamSpeakerCard";
import "./ImamSpeakers.styles.scss";
import NoData from "@/components/No-data/NoData";
import { apiGetImamSpeakers } from "../../api/imams.api";
import { Toastify } from "@/utils/toast";
import useUserRestrictions from "@/hooks/useUserRestriction";
import UserRestrictionDisplayer from "@/components/UserRestrictionDisplayer/UserRestrictionDisplayer";
import ModalUserRestrictionWhenPaid from "@/components/Modal-user-restriction-when-paid/ModalUserRestrictionWhenPaid";

type TFilteredValue = "imam" | "prédicateur" | "";
const CtImamSpeakers = () => {
  const { isUserFullAccessAutorized } = useUserRestrictions();
  const [activeButton, setActiveButton] = useState<TFilteredValue>("");

  const theme = useTheme();
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchedText, setSearchedText] = useState("");
  const handleSearchChange = (e: any) => {
    setSearchedText(e?.target?.value);
  };

  const actions = [
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "Tout" },
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "Imam" },
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "Prédicateur" },
  ];

  const [loading, setLoading] = useState(false);
  const [fakeItems, setFakeItems] = useState<TImamSpeakerCard[]>([]);
  const [page, setPage] = useState(1);
  const [hasmore, setHasmore] = useState(false);

  const fetchMoreData = () => {
    setPage(page + 1);
    console.log(page);

    apiGetImamSpeakers()
      .then((response: any) => {
        console.log(response);

        if (page === response.number_of_pages) {
          setHasmore(false);
        }

        let tempList: TImamSpeakerCard[] = response.items.map((data: any) => {
          return {
            name: data.name,
            firstname: "",
            title: data.role === "speaker" ? "prédicateur" : data.role,
            cursus: data.course,
            reference: data.reference,
            youtube: data.youtube,
            facebook: data.facebook,
            twitter: data.twitter,
            instagram: data.instagram,
            telegram: data.telegram,
          };
        });

        console.log([...tempList]);
        setFakeItems([...tempList]);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  useEffect(() => {
    isUserFullAccessAutorized && setLoading(true);

    isUserFullAccessAutorized &&
      apiGetImamSpeakers()
        .then((response: any) => {
          setLoading(false);
          console.log(response);

          let tempList: TImamSpeakerCard[] = response.data.result.map(
            (data: any) => {
              return {
                name: data.lastname,
                firstname: data.firstname,
                title: data.role === "SPEAKER" ? "prédicateur" : "imam",
                cursus: data.course,
                reference: data.reference,
                youtube: data.socialMedia.youtube,
                facebook: data.socialMedia.facebook,
                twitter: data.socialMedia.twitter,
                instagram: data.socialMedia.instagram,
                telegram: data.socialMedia.telegram,
              };
            }
          );

          console.log([...tempList]);
          setFakeItems([...tempList]);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          error?.response?.data?.message &&
            Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserFullAccessAutorized]);

  return (
    <>
      {isUserFullAccessAutorized ? (
        <div className="ct-imam-speakers">
          <div className="ct-imam-speakers__filter-part">
            <TextField
              placeholder="Nom de l'imam /du prédicateur"
              variant="outlined"
              label="Rechercher"
              name="recherche"
              className="searchInput"
              type="text"
              value={searchedText}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InlineIcon icon="bi:search" />
                  </InputAdornment>
                ),
              }}
            />

            {matches_sm && (
              <>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  className="ct-imam-speakers__filter-part__speed-dial"
                  direction="down"
                  icon={
                    <InlineIcon icon="ep:filter" style={{ fontSize: "20px" }} />
                  }
                >
                  {actions.map((action, index) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      tooltipOpen
                      onClick={() => {
                        if (index === 0) {
                          // Tout
                          setActiveButton("");
                        }
                        if (index === 1) {
                          // Imam
                          setActiveButton("imam");
                        }
                        if (index === 2) {
                          // Prédicateur
                          setActiveButton("prédicateur");
                        }
                      }}
                    />
                  ))}
                </SpeedDial>
                {activeButton !== "" && (
                  <Chip
                    label={activeButton.toUpperCase()}
                    onDelete={() => {
                      setActiveButton("");
                    }}
                    sx={{ width: "100%" }}
                  />
                )}
              </>
            )}

            {!matches_sm && (
              <>
                <Button
                  variant={activeButton === "imam" ? "outlined" : "contained"}
                  onClick={() => {
                    setActiveButton("imam");
                  }}
                  color="primary"
                  className="ct-imam-speakers__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  Imams
                </Button>
                <Button
                  variant={
                    activeButton === "prédicateur" ? "outlined" : "contained"
                  }
                  onClick={() => {
                    setActiveButton("prédicateur");
                  }}
                  color="primary"
                  className="ct-imam-speakers__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  Prédicateurs
                </Button>
                <Button
                  variant={activeButton === "" ? "outlined" : "contained"}
                  onClick={() => {
                    setActiveButton("");
                  }}
                  color="primary"
                  className="ct-imam-speakers__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  tout
                </Button>
              </>
            )}
          </div>
          {loading ? (
            <img
              src={require("@/assets/images/gif/loadmore.gif")}
              height="70"
              width="70"
              className="load-more"
              alt="load-more"
            />
          ) : (
            <PerfectScrollbar id="scrollableDiv">
              <InfiniteScroll
                className="ct-imam-speakers__body-part"
                scrollableTarget="scrollableDiv"
                dataLength={fakeItems.length}
                next={fetchMoreData}
                hasMore={hasmore}
                loader={
                  fakeItems
                    .filter((value) => {
                      const fullname = value.name + value.firstname;
                      return (
                        fullname
                          .toLocaleLowerCase()
                          .indexOf(searchedText.toLocaleLowerCase()) !== -1
                      );
                    })
                    .filter((value) => {
                      return value.title.indexOf(activeButton) !== -1;
                    }).length > 0 ? (
                    <img
                      src={require("@/assets/images/gif/loadmore.gif")}
                      className="load-more"
                      alt="load-more"
                    />
                  ) : (
                    <NoData />
                  )
                }
              >
                {fakeItems
                  .filter((value) => {
                    const fullname = value.name + value.firstname;
                    return (
                      fullname
                        .toLocaleLowerCase()
                        .indexOf(searchedText.toLocaleLowerCase()) !== -1
                    );
                  })
                  .filter((value) => {
                    return value.title.indexOf(activeButton) !== -1;
                  })
                  .map((value, index) => {
                    return (
                      <ImamSpeakerCard
                        key={index}
                        name={value.name}
                        firstname={value.firstname}
                        title={value.title}
                        cursus={value.cursus}
                        reference={value.reference}
                        youtube={value.youtube}
                        facebook={value.facebook}
                        twitter={value.twitter}
                        instagram={value.instagram}
                        telegram={value.telegram}
                      />
                    );
                  })}
              </InfiniteScroll>
            </PerfectScrollbar>
          )}
        </div>
      ) : (
        <>
          <Grow in={!isUserFullAccessAutorized} mountOnEnter unmountOnExit>
            <div className="ct-imam-speakers">
              <UserRestrictionDisplayer />
            </div>
          </Grow>
        </>
      )}
      {false && <ModalUserRestrictionWhenPaid />}
    </>
  );
};

export default CtImamSpeakers;
