import { axios } from "@/lib/axios";
import React, { useEffect, useState } from "react";
import { createReactEditorJS } from "react-editor-js";
import styled from "styled-components";
import { EDITOR_JS_TOOLS } from "./editor.tools";

const TextCGU: React.FC = () => {
  const [generalTerms, setGeneralTerms] = useState<any>();
  const getCGU = () => {
    setGeneralTerms(offlineCGU);
    axios
      .get("/param-s/api/v1/parameters/p/general-terms")
      .then((response) => {
        console.log(response.data.content);
        const content = JSON.parse(response.data.content);
        console.log(content);
        setGeneralTerms(onlineCGU(content));
      })
      .catch((error) => {
        setGeneralTerms(offlineCGU);
        console.log(error);
      });
  };

  useEffect(() => {
    getCGU();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const offlineCGU = () => {
    return (
      <TextContainer>
        <h1 className="main-title">
          {" "}
          CONDITIONS GÉNÉRALES D'UTILISATION - C.G.U.
        </h1>
        <div className="paragraphe">
          Le fait de s’inscrire comme membre au service d’intermédiaire proposé
          par Zawaj Sounnah emporte acceptation pleine et entière des présentes
          conditions générales d’utilisation. EN VOUS INSCRIVANT SUR CE SITE
          VOUS VOUS ENGAGEZ À :
          <ul style={{ paddingLeft: "30px" }}>
            <li>Respecter les présentes conditions d'utilisation.</li>
            <li>Être âgé(e) de 18 ans minimum.</li>
            <li>
              Ne pas avoir l'intention de rentrer en contact direct avec un
              membre sans passer par une tierce personne en dehors du site.
            </li>
            <li>
              Ne pas se servir du site pour une autre raison que sa vocation
              première qui est de rechercher sincèrement le zawaj.Ne pas
              diffuser d’informations illégales et répréhensibles ou liées au
              non-respect des personnes et des mineurs, des lois ou
              réglementations nationales ou internationales.
            </li>
            <li>
              Ne pas diffuser des textes explicitement sexuels et de façon
              générale des textes contraires aux bonnes moeurs Islamiques.
            </li>
            <li>
              Ne pas utiliser de langages injurieux, obscènes, discriminatoires
              ou violents.
            </li>
            <li>
              Rester polis et courtois avec les autres membres et modérateurs.
            </li>
            <li>
              Ne pas promouvoir un autre site internet ou un quelconque service
              commercial ou non.
            </li>
            <li>
              Ne pas utiliser la description de votre profil pour dénigrer,
              discriminer, juger, dénoncer ou critiquer, autrui
            </li>
            <li>
              Ne pas être intermédiaire pour des personnes qui ne sont pas
              membres (sauf si l’administration vous l’a autorisé).
            </li>
            <li>
              Ne pas solliciter de contribution financière de quelque nature que
              ce soit.
            </li>
            <li>Fournir des données réelles et sincères sur son profil.</li>
            <li>
              En cas de non-respect de ces obligations et les suivantes, nous
              nous verrons obligés d’appliquer une sanction allant de
              l’avertissement à la suppression de compte sans préavis ni
              remboursement. Si vous êtes en contact avec un membre qui ne
              respecte pas l’une de ces obligations, nous vous prions de bien
              vouloir nous en informer et nous agirons dans le respect de votre
              anonymat.
            </li>
          </ul>
        </div>

        <h3>LE PRINCIPE DU SITE :</h3>
        <p>
          Zawaj Sounnah est un site de mise en relation qui a pour vocation de
          mettre en relation des musulmans et des musulmanes célibataires,
          divorcé(e)s ou veufs(ves) souhaitant rencontrer un prétendant ou une
          prétendante afin de concrétiser un mariage. Pour vous aider à réaliser
          cet objectif Zawaj Sounnah met à votre disposition son site et ses
          fonctions pour trouver et communiquer avec des personnes qui
          correspondent à vos attentes et convictions religieuses.
        </p>

        <h3>QUI PEUT S'INSCRIRE ?</h3>
        <p>
          Ce service est destiné aux frères et sœurs suivant le coran et la
          sounnah selon la compréhension des premières générations (salafs)
          Également peuvent s’inscrire les musulmans et musulmanes
          pratiquant(e)s ou débutant(e)s (عوام) qui n’ont pas de mauvaises
          croyances et qui ne s'affilient à aucun groupe ou qui voudraient
          apprendre la croyance des pieux prédécesseurs.
        </p>

        <h3>QUI NE PEUT PAS S'INSCRIRE ?</h3>
        <p>
          Tout homme qui n'as pas les moyens financiers pour assurer pleinement
          la prise en charge de son épouse (loger, nourrir, vêtir). Tout homme
          ayant une situation ambiguë ne voulant pas informer sa famille de son
          mariage. Toute personne faisant partie d'un groupe tel que les frères
          musulmans, les acharites, les tablighs, les souffis, les tijanis, les
          maturidis, les coranistes, les khawarijs, takfiris et tout autre
          groupuscule égaré, Zawaj Sounnah n'acceptant aucunement les adeptes de
          ces courants.
        </p>

        <h3>L'INSCRIPTION : </h3>
        <p>
          Votre profil est publié après modération et validation par nos soins
          (sous 24 heures environ). Cependant vous pourrez utiliser toutes les
          fonctions du site immédiatement. Le nom d’utilisateur (pseudonyme) que
          vous choisissez lors de votre inscription garantit votre anonymat.
          Votre adresse email et numéro de téléphone ainsi que votre nom et
          prénom ne seront jamais visibles sur le site est seront confidentiels.
          <br />
          Lors de votre inscription, vous devez remplir honnêtement tous les
          champs du formulaire. Il est interdit de spécifier une coordonnée
          quelconque dans votre profil, cela comprend entre autres : Adresse
          postale, Numéro de Téléphone, Url de site web, Email, Adresse de
          messagerie instantanée ou Message publicitaire. D'une manière
          générale, nous nous réservons le droit de refuser à notre seule
          discrétion les profils que nous jugeons non conformes à l’esprit du
          site.
        </p>

        <h3>VÉRIFICATION DES PROFILS : </h3>
        <p>
          Après l'inscription, vous pourrez utiliser les services du site,
          néanmoins votre profil sera marqué comme non vérifié. Nos équipes se
          chargeront de lire attentivement votre profil afin de valider vos
          informations. Nous pourrons vous contacter par whatsApp ou par mail
          afin d’obtenir des informations complémentaires si besoin, si vous
          refusez cette vérification, votre profil restera marqué d’une croix
          rouge signalant aux autres membres que votre profil n'est pas vérifié,
          cela diminuera nettement la confiance des utilisateurs à votre
          encontre et vous risquerez de ne pas trouver de profil qui souhaite
          poursuivre avec vous.
        </p>

        <h3>MISE EN RELATION :</h3>
        <p>
          Après inscription vous pourrez consulter les profils du sexe opposé,
          vous aurez la possibilité de les mettre dans votre liste de favori, si
          vous souhaitez rentrer en discussion avec un profil, vous pourrez le
          faire, en cliquant sur l'icône de contact, vous rentrerez alors dans
          une discussion (sans mixité) vous, le prétendant et un administrateur
          ou modérateur de l'équipe Zawaj Sounnah, vos messages seront en
          attente jusqu'à validation par le service d'intermédiaires afin de
          s'assurer qu’aucun propos n'est contraire à la religion.
          <br />
          <br />
          <b>-L'échange de numéro : </b>
          <br />
          Il est strictement interdit de donner vos numéros personnels, réseaux
          sociaux ou toutes informations qui permettraient d'entrer en contact
          avec vous directement. Seul les sœurs sont autorisées à envoyer le
          numéro de leurs tuteurs. C'est au sœurs de se responsabiliser et
          d'envoyer les numéros de leurs maharims si elles veulent poursuivre en
          dehors de la plate-forme !
          <br />
          <br />
          <b>- La complaisance est interdite : </b>
          <br />
          Vous êtes des étrangers les uns pour les autres, il n'est donc pas
          permis de faire preuve de complaisance. De dire des mots doux ou
          flirter. Vous devez poser les questions les plus pertinentes et rester
          sur l'essentiel ! Tout message qui sera jugé complaisant sera refusé
          par le service de modération.
          <br />
          <br />
          <b>
            - Les lol,mdr,ptdr, … l'usage de smiley ( ainsi que leurs diminutifs
            ) sont interdits :{" "}
          </b>
          <br />
          Nous avons questionné les savants sur le fait de laisser passer ce
          genre d'expressions populaires. Ils nous on dit que vous n'êtes pas là
          pour rigoler, ou faire rire. Vous êtes là pour le mariage char3i,
          c'est pourquoi toutes ces formules de rires sont interdites dans le
          chat. Si vous ne voulez pas voir vos messages retirés alors ne les
          utilisez pas.
          <br />
          <br />
          <b>- Les descriptions physiques : </b>
          <br />
          Sur la plate-forme l'équipe de modération lis tous vos messages. La
          pudeur est incombée à la femme d'autant plus en dehors de son foyer,
          en espace public, au milieux de personnes étrangères. Les soeurs
          peuvent donner leur taille et leur poid ou la corpulence seulement. (
          Les mensurations n'étant pas autorisées non plus ). En dehors de cela
          tout autre qualificatif sur le physique sera rejeté.
          <br />
          <br />
          <b>- La transmission de liens :</b>
          <br />
          Les modérateurs se réservent le droit de refuser les liens de sites
          qu'ils ne connaissent pas. Ils peuvent cependant autoriser les liens
          de sites de fatwa comme 3ilm char3i.
          <br />
          <br />
          <b>-Les fatwa, rappels, hadith … :</b>
          <br />
          Toutes paroles liées à la science religieuse sans la source, le dalil
          seront rejetées.
          <br />
          <br />
          <b>-La communication autre que le français :</b>
          <br />
          Nous acceptons les salutations, remerciements… formules basiques en
          arabes néanmoins la plupart de nos membres étant francophone, et vos
          messages étant lus et contrôlés afin de vérifier que le contenu soit
          recevable, tous messages dans une langue étrangère ne sera pas validé.
          <br />
          <br />
          <b>
            -Les questions d'ordre intime devront être posées lors de la
            mouqabalah en présence du tuteur / mahram{" "}
          </b>
          <br />
          <br />
          <b>-La promotion de personnes déviantes :</b>
          <br />
          Étant un site suivant le coran et la sounnah selon la compréhension
          des pieux prédécesseurs, nous veillons à la croyance de nos membres et
          leur préservation. Nous ne sommes pas dans un état d'esprit de divison
          et nous estimons que l'aide au mariage chari est un droit réservé à
          toute personne sincère dans cette démarche. Néanmoins nous ne pouvons
          promouvoir des savants et prédicateurs contre qui des mise en garde
          ont été établies.
          <br />
          <br />
          <b>-L'évocation des péchés passés : </b>
          <br /> il n'y a pas de nécessité à évoquer les péchés sur lesquels
          nous avons fait le repentir.
          <br />
          <br />
          <b>-Les rencontres en lieux publics seuls : </b>
          <br />
          il faut la présence d'une tierce personne tel qu'autorisée pour les
          mouqabalah
          <br />
          <br />
          <b>-Les groupes whatsapp :</b>
          <br />
          suite à des enfreintes et dans le soucis de faire les causes optimales
          pour un respect des injonctions sacrées d'Allah, seuls les visio via
          zoom et google meet sont autorisés
          <br />
          <br />
          <b>Qu'Allah raffermisse nos cœurs sur la droiture !</b>
        </p>

        <h3>RÈGLES DES DISCUSSIONS:</h3>
        <p>
          Zawaj sounnah met à disposition son site pour que vous puissiez
          échanger avec les membres dans les règles de l'islam. Nos modérateurs
          sont présents et veillent à ce que votre discussion reste dans un
          cadre licite. C'est pour cela qu'il y a des règles d'usage à
          respecter. Les soeurs en période de viduité, ou dont la situation
          maritale est ambiguë, le mari n'ayant pas prononcé le divorce suite à
          une demande de khôl ou divorce qui n'est pas confirmé par une personne
          de science ne sont pas autorisées à s'inscrire Le partage des profils
          hors plateforme : nos membres payent pour, entres autres raisons,
          accéder aux différents profils. Il ne serait pas juste à l'égard de
          ceux qui payent le service d'en faire part à ceux qui ne sont pas
          inscrits.
        </p>

        <h3>CONTACT : </h3>
        <p>
          Dans votre espace personnel vous aurez la possibilité d'envoyer un
          mail. Pour y accéder cliquez sur le point d'interrogation qui ce
          trouve en haut à droite à côté du bouton de déconnection.
        </p>

        <h3>MOUQABALA VISIO :</h3>
        <p>
          Zawaj Sounnah a dans son effectif plusieurs imams et étudiants en
          sciences qui pourront officier des mouqabalas visio pour les sœurs qui
          n'ont pas la possibilité d'avoir des tuteurs. Ce service n'est pas
          compris dans votre formule, et sera à la demande des utilisateurs qui
          seront « en discussion ». Ce service est payant, car les imams
          prendront de leur temps pour faire cela.
        </p>

        <h3>LE TUTEUR : </h3>
        <p>
          « Pas de mariage (pour la femme) sans tuteur. » il est donc impératif
          pour chaque femme d'avoir un tuteur prêt à prendre le relais une fois
          sortie de la plate-forme. Zawaj Sounnah n'est pas responsable des
          actes commis en dehors de la plate-forme après mise en relation avec
          les tuteurs. Pour les sœurs n'ayant pas de tuteur, veuillez consulter
          cet article afin de savoir comment trouver un tuteur :
          <a
            href="https://zawaj-sounnah.com/comment-trouver-un-tuteur-pour-se-marier/"
            target="__blank"
          >
            <b>
              https://zawaj-sounnah.com/comment-trouver-un-tuteur-pour-se-marier/
            </b>
          </a>
        </p>

        <h3>TARIF & ABONNEMENT : </h3>
        <p>
          Reportez-vous à la page d'inscription pour prendre connaissance des
          tarifs en vigueur selon votre pays et les modes de paiements. Le
          service est cependant accessible uniquement pour les membres ayant un
          abonnement actif, ou pris une cotisation sur plusieurs mois.
        </p>

        <h3>REMBOURSEMENT DE LA COTISATION :</h3>
        <p>
          Pour toute demande de remboursement veuillez nous contacter par
          mail:&nbsp;
          <a href="mailto:zawaj.sounnah@gmail.com">
            <b>zawaj.sounnah@gmail.com</b>
          </a>
        </p>

        <h3>LA RÉSILIATION :</h3>
        <p>
          Vous pouvez mettre un terme à votre adhésion mensuelle à tout moment,
          sans motif spécifique. Un lien sera bientôt présent dans votre espace
          membre pour supprimer complètement votre profil ainsi que toutes vos
          données automatiquement. Cette action est irréversible. Le service
          peut également résilier votre adhésion s'il est observé que vous ne
          respectez pas les présentes conditions d'utilisation.
        </p>

        <h3>LES HORAIRES :</h3>
        <p>
          Le service d'intermédiaire est ouvert tous les jours de 10 heures à 20
          heures. Nous sommes fermés le dimanche. Toutefois ceci n'est qu'à
          titre informatif, nous pouvons ouvrir plus tôt ou fermer plus tard.
          Zawaj Sounnah prévoit de mettre en place une équipe de modération afin
          de fournir un service d'intermédiaire ouvert de 7 heures du matin à
          minuit. Vous serez informé de l'évolution de ce projet.
        </p>

        <h3>RESPONSABILITÉ DE Zawaj Sounnah EN CAS DE DIVORCE :</h3>
        <p>
          Zawaj Sounnah est une plate-forme d'intermédiaire mettant en avant des
          profils de personnes désirant le mariage, nous nous chargeons
          uniquement de faire respecter les règles islamiques sur notre
          plate-forme et nous ne sommes pas responsables de ce qui se passe en
          dehors de celle-ci, lorsque les numéros des familles sont échangés.
          Vous avez l'obligation religieuse d'enquêter sur votre pretendant(e),
          de questionner sa famille et son entourage, biensûr s'il vous parvient
          que cette personne est immorale ou non-sérieuse, Zawaj Sounnah fera un
          bannissement après avoir reçu vos preuves. Ne vous précipitez pas dans
          le mariage sans être sûr(e) de connaître la famille du prétendant(e)
          et d'être connu(e) de celle-ci. Vous êtes responsable de votre mariage
          et de votre choix. Néanmoins, nous sommes à votre disposition pour
          vous conseiller dans celui-ci à travers nos articles nos vidéos, nos
          interventions.
        </p>

        <h3>DISPOSITIONS DIVERSES :</h3>
        <p>
          Zawaj Sounnah ne pourra être tenu pour responsable de tout dommage
          direct ou indirect pouvant survenir de l'utilisation ou au contraire
          de l'impossibilité d'utiliser le service.
          <br />
          Zawaj Sounnah s'efforce de veiller au respect des principes
          précédemment énoncés.
          <br />A cause du volume des informations, le contrôle du contenu
          transmis ne peut être que limité. En conséquence, les relations que
          vous entretenez avec les membres du site et le contenu que vous
          publiez relèvent exclusivement de votre responsabilité. Vous acceptez
          que Zawaj Sounnah ne soit pas responsable en cas de conflits entre
          vous après intervention des familles. Si vous avez un doute sur
          l’honnêteté d’un membre ou si vous constatez qu'un membre ne respecte
          pas les conditions d'utilisation, vous avez la possibilité de le
          bloquer en l'ajoutant dans votre liste noire et de nous en informer,
          nous agirons dans le respect de votre anonymat. Nous supprimerons le
          profil si nous estimons votre requête justifiée.
        </p>

        <h3>
          RESPECT DES REGLES DU MARIAGE EN VIGEUR SELON VOTRE LIEU DE RESIDENCE
          :
        </h3>
        <div className="paragraphe">
          Les conditions de validité du mariage en Islam sont :
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              le consentement mutuel des deux futurs époux, la présence de deux
              témoins,
            </li>
            <li>la fixation d’une dote,</li>
            <li>la présence du tuteur de la future épouse.</li>
          </ul>
          Or toutes ces conditions sont normalement réunies lors de la
          célébration de l'acte civil. Le mariage est un contrat civil qui doit
          être reconnu devant Allah puis peut l'être devant les hommes. Les
          termes (Fâtiha, Halal, Zawaje ou Nikâh) tels qu'ils sont utilisés
          aujourd’hui pour décrire le mariage religieux ne sont pas valables
          pour la législation française ou d'autres pays européens dans la
          mesure ou rien ne peut légalement le garantir vis à vis de leurs lois,
          cependant dans la religion Islamique cela est amplement suffisant si
          les conditions sont réunies. Dans les lois européennes le mariage
          religieux ne garantit non plus les droits de la femme en cas de
          litige, ni la reconnaissance des éventuels enfants ou les droits de
          succession. Zawaj Sounnah n'effectue pas les mariages religieux, nous
          sommes simplement un service de mise en relation.
        </div>

        <h3>RÈGLES DE PRUDENCE :</h3>
        <div className="paragraphe">
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              Ne divulguez jamais d’informations personnelles aux autres membres
              qui pourraient leur permettre de vous identifier en dehors du
              site.
            </li>
            <li>Utilisez la messagerie interne du site pour communiquer.</li>
            <li>
              Zawaj Sounnah décline toute responsabilité concernant les
              rencontres physiques entre membres, suite à l’utilisation de son
              site et de ses services.
            </li>
            <li>
              Zawaj Sounnah conseille à ses membres de prendre toutes les
              précautions nécessaires lorsqu’ils rencontrent d’autres personnes
              physiquement pour la première fois.
            </li>
            <li>
              Il est fortement recommandé d’entrer en contact avec une personne
              pour la première fois avec sa famille et d'être toujours
              accompagné d’un proche ou de votre famille
            </li>
            <li>
              Pour rappel un Wali est recommandé pour communiquer en toute
              sérénité.
            </li>
          </ul>
        </div>
      </TextContainer>
    );
  };

  const onlineCGU = (content: any) => {
    const Editor = createReactEditorJS();
    return (
      <TextContainer>
        <Editor
          holder="editor"
          readOnly={true}
          defaultValue={content}
          tools={EDITOR_JS_TOOLS}
        >
          <div id="editor" className="editor"></div>
        </Editor>
      </TextContainer>
    );
  };

  return <>{generalTerms}</>;
};

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .main-title {
    font-size: var(--title-size);
    font-weight: normal;
    color: var(--ui-primary);
    margin-bottom: 30px;
  }

  h3,
  .ce-header {
    font-size: var(--subtitle-size);
    font-weight: normal;
    color: var(--ui-primary);
    margin-top: 20px;
    margin-bottom: 5px;
  }

  p,
  .ce-paragraph {
    text-align: left;
    padding: 0px 20px;
  }

  .paragraphe,
  .ce-paragraph {
    text-align: left;
    padding: 0px 20px;
  }
  #editor {
    .codex-editor__redactor {
      padding-bottom: 0px !important;
    }
    .ce-block__content {
      width: 100%;
      max-width: unset;
    }
  }
`;

export default TextCGU;
