import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";
import { TForgotPassword, TResetPassword, TSignIn, TSignUp } from "../types";

export const apiLoginMember = (
  data: TSignIn
): Promise<AxiosResponse<any, any>> => {
  return axios.post("/auth-s/api/v1/auth/p/sign-in", data);
};

export const apiSignupMember = (
  data: TSignUp
): Promise<AxiosResponse<any, any>> => {
  return axios.post("/auth-s/api/v1/auth/p/sign-up", data);
};

export const apiCheckSignup = (data: {
  email: string;
  username: string;
  phoneNumber: string;
}): Promise<AxiosResponse<any, any>> => {
  return axios.get(
    "/auth-s/api/v1/auth/p/verify-duplicate?email=" +
      data.email +
      "&username=" +
      data.username +
      "&phoneNumber=" +
      data.phoneNumber
  );
};

export const apiForgotPasswordMember = (
  data: TForgotPassword
): Promise<AxiosResponse<any, any>> => {
  return axios.post("/auth-s/api/v1/auth/p/forgot-password", data);
};

export const apiResetPasswordMember = (
  data: TResetPassword
): Promise<AxiosResponse<any, any>> => {
  return axios.post("/auth-s/api/v1/auth/p/reset-password", data);
};

export const apiVerifyEmailConformity = (
  data: string
): Promise<AxiosResponse<any, any>> => {
  return axios.get("/auth-s/api/v1/auth/p/verify-email?token=" + data);
};

export const apiConfirmEmail = (): Promise<AxiosResponse<any, any>> => {
  return axios.post("/auth-s/api/v1/auth/confirm-email");
};

export const apiGetUserConnected = (): Promise<AxiosResponse<any, any>> => {
  return axios.get("/profile-s/api/v1/profiles/me");
};

export const apiGetModerationSchedule = (): Promise<
  AxiosResponse<any, any>
> => {
  return axios.get("/time-slots/moderation-status");
};

/**
 *
 * OLD API CALL
 */

export const apiVerifyEmail = (
  email: string
): Promise<AxiosResponse<any, any>> => {
  return axios.get("/members/email/verify?email=" + email);
};

export const apiVerifyUsername = (
  username: string
): Promise<AxiosResponse<any, any>> => {
  return axios.get("/members/username/verify?username=" + username);
};

export const apiVerifyPhone = (
  phone_number: string
): Promise<AxiosResponse<any, any>> => {
  return axios.get("/members/phone/verify?phone_number=" + phone_number);
};
