import {
  TCurrentExchange,
  TFilteredQuery,
  TGeneralLinks,
  TModerationSocket,
} from "@/features/profile/types";
import general_param from "./general_param.types";

interface IUpdateEthnies {
  type: general_param.UPDATE_ETHNIES;
  payload: { id: string; name: string }[];
}

interface IUpdateSearchQuery {
  type: general_param.UPDATE_SEARCH_QUERY;
  payload: string;
}

interface IUpdateFormQuery {
  type: general_param.UPDATE_FORM_QUERY;
  payload: TFilteredQuery;
}

interface IUpdateOpenDiscussion {
  type: general_param.UPDATE_OPEN_DISCUSSION;
  payload: boolean;
}

interface IUpdateCurrentExchange {
  type: general_param.UPDATE_CURRENT_EXCHANGE;
  payload: TCurrentExchange;
}

interface IUpdateMaxMessage {
  type: general_param.UPDATE_MAX_MESSAGE;
  payload: number;
}

interface IUpdateNbreMessageSent {
  type: general_param.UPDATE_NBRE_MESSAGE_SENT;
  payload: number;
}

interface IUpdateNbreMessageReceive {
  type: general_param.UPDATE_NBRE_MESSAGE_RECEIVE;
  payload: number;
}

interface IUpdateModeration {
  type: general_param.UPDATE_MODERATION_SOCKET;
  payload: TModerationSocket;
}

interface IUpdateNbreNotification {
  type: general_param.UPDATE_NBRE_NOTIFICATION_SOCKET;
  payload: number;
}

interface IUpdateNbreLive {
  type: general_param.UPDATE_NBRE_LIVE_SOCKET;
  payload: number;
}

interface IUpdateMaxCaracter {
  type: general_param.UPDATE_MAX_CARACTERE;
  payload: number;
}

interface IUpdateMailMale {
  type: general_param.UPDATE_MAIL_MALE;
  payload: string;
}

interface IUpdateMailFemale {
  type: general_param.UPDATE_MAIL_FEMALE;
  payload: string;
}

interface IUpdateGeneralLinks {
  type: general_param.UPDATE_GENERAL_LINKS;
  payload: TGeneralLinks;
}

interface IUpdateIsModerationSuspend {
  type: general_param.UPDATE_IS_MODERATION_SUSPEND;
  payload: boolean;
}

interface IUpdateMessageClosedModeration {
  type: general_param.UPDATE_MESSAGE_CLOSED_MODERATION;
  payload: string;
}

interface IUpdateTourStepIsOpen {
  type: general_param.UPDATE_TOUR_STEP_IS_OPEN;
  payload: boolean;
}

interface IUpdateIsWebsiteMaintenance {
  type: general_param.UPDATE_IS_WEBSITE_MAINTENANCE;
  payload: boolean;
}

interface IUpdateIsPaymentPendingProcess {
  type: general_param.UPDATE_PAYMENT_PENDING_PROCESS;
  payload: boolean;
}

export type GeneralParamAction =
  | IUpdateEthnies
  | IUpdateSearchQuery
  | IUpdateFormQuery
  | IUpdateOpenDiscussion
  | IUpdateCurrentExchange
  | IUpdateMaxMessage
  | IUpdateNbreMessageSent
  | IUpdateNbreMessageReceive
  | IUpdateModeration
  | IUpdateNbreNotification
  | IUpdateNbreLive
  | IUpdateMaxCaracter
  | IUpdateMailMale
  | IUpdateMailFemale
  | IUpdateGeneralLinks
  | IUpdateIsModerationSuspend
  | IUpdateMessageClosedModeration
  | IUpdateTourStepIsOpen
  | IUpdateIsWebsiteMaintenance
  | IUpdateIsPaymentPendingProcess;

export const updateEthnies = (ethnies: { id: string; name: string }[]) => ({
  type: general_param.UPDATE_ETHNIES,
  payload: ethnies,
});

export const updateSearchQuery = (search_query: string) => ({
  type: general_param.UPDATE_SEARCH_QUERY,
  payload: search_query,
});

export const updateFormQuery = (form_query: TFilteredQuery) => ({
  type: general_param.UPDATE_FORM_QUERY,
  payload: form_query,
});

export const updateOpenDiscussion = (openDiscussion: boolean) => ({
  type: general_param.UPDATE_OPEN_DISCUSSION,
  payload: openDiscussion,
});

export const updateCurrentExchange = (current_exchange: TCurrentExchange) => ({
  type: general_param.UPDATE_CURRENT_EXCHANGE,
  payload: current_exchange,
});

export const updateMaxMessage = (max_message: number) => ({
  type: general_param.UPDATE_MAX_MESSAGE,
  payload: max_message,
});

export const updateNbreMessageSent = (nbre_message_sent: number) => ({
  type: general_param.UPDATE_NBRE_MESSAGE_SENT,
  payload: nbre_message_sent,
});

export const updateNbreMessageReceive = (nbre_message_receive: number) => ({
  type: general_param.UPDATE_NBRE_MESSAGE_RECEIVE,
  payload: nbre_message_receive,
});

export const updateModeration = (moderation_socket: TModerationSocket) => ({
  type: general_param.UPDATE_MODERATION_SOCKET,
  payload: moderation_socket,
});

export const updateNbreNotificationSocket = (
  nbre_notification_socket: number
) => ({
  type: general_param.UPDATE_NBRE_NOTIFICATION_SOCKET,
  payload: nbre_notification_socket,
});

export const updateNbreLiveSocket = (nbre_live_socket: number) => ({
  type: general_param.UPDATE_NBRE_LIVE_SOCKET,
  payload: nbre_live_socket,
});

export const updateMaxCaracter = (max_caracter: number) => ({
  type: general_param.UPDATE_MAX_CARACTERE,
  payload: max_caracter,
});

export const updateMailMale = (mail_male: string) => ({
  type: general_param.UPDATE_MAIL_MALE,
  payload: mail_male,
});

export const updateMailFemale = (mail_female: string) => ({
  type: general_param.UPDATE_MAIL_FEMALE,
  payload: mail_female,
});

export const updateGeneralLinks = (general_links: TGeneralLinks) => ({
  type: general_param.UPDATE_GENERAL_LINKS,
  payload: general_links,
});

export const updateIsModerationSuspend = (is_moderation_suspend: boolean) => ({
  type: general_param.UPDATE_IS_MODERATION_SUSPEND,
  payload: is_moderation_suspend,
});

export const updateMessageClosedModeration = (
  message_closed_moderation: string
) => ({
  type: general_param.UPDATE_MESSAGE_CLOSED_MODERATION,
  payload: message_closed_moderation,
});

export const updateTourStepIsOpen = (tour_step_is_open: boolean) => ({
  type: general_param.UPDATE_TOUR_STEP_IS_OPEN,
  payload: tour_step_is_open,
});

export const updateIsWebsiteMaintenance = (
  is_website_maintenance: boolean
) => ({
  type: general_param.UPDATE_IS_WEBSITE_MAINTENANCE,
  payload: is_website_maintenance,
});
export const updateIsPaymentPendingProcess = (
  is_payment_pending_process: boolean
) => ({
  type: general_param.UPDATE_PAYMENT_PENDING_PROCESS,
  payload: is_payment_pending_process,
});
