import React, { useEffect, useRef, useState } from "react";
import { InlineIcon } from "@iconify/react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
  Alert,
  AlertTitle,
  Collapse,
  IconButton,
} from "@mui/material";
import "./Fiche.styles.scss";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";
import * as yup from "yup";
import { useFormik } from "formik";
import { TCountry, TFiche, TMemberInfos } from "@/features/profile/types";
import { apiSaveFiche } from "@/features/profile/api/profile.api";
import { Toastify } from "@/utils/toast";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  selectMemberInfos,
  selectMemberProfile,
} from "@/store/reducers/member/member.selector";
import { useAppDispatch } from "@/store";
import { useSelector } from "react-redux";
import { TMemberProfile } from "@/features/auth/types";
import * as moment from "moment";
import "moment/locale/fr";
import { updateMemberProfile } from "@/store/reducers/member/member.actions";
import * as _ from "lodash";
import useUserRestrictions from "@/hooks/useUserRestriction";
import CModalRejectedModif from "@/components/Modal-Rejected-Modif/ModalRejectedModif";

const Fiche = () => {
  const memberInfosFromStore = useSelector(selectMemberInfos) as TMemberInfos;
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const {
    isUserProfileModificationApproved,
    isUserProfileModificationRejected,
    isUserProfileModificationPending,
  } = useUserRestrictions();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [country, setCountry] = useState<TCountry>({
    country: memberProfileFromStore?.infos?.identity?.country || "",
    abbreviation: memberProfileFromStore?.infos?.identity?.countryCode || "",
  });
  const [countries, setCountries] = React.useState<
    Array<{ country: string; abbreviation: string }>
  >([]);

  const [openAlertForUpdate, setOpenAlertForUpdate] = useState(true);

  const [openModerationReport, setOpenModerationReport] = useState(false);

  const dispatch = useAppDispatch();

  const handleCountryChange = (event: any, value: TCountry | null) => {
    console.log(value);
    if (value == null) {
      setCountry({ country: "", abbreviation: "" });
      formik.setFieldValue("country", "");
    } else {
      setCountry(value as TCountry);
    }
  };

  useEffect(() => {
    const countries_json_data = require("@/assets/json/countries.json");
    setCountries(countries_json_data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countryInputref = useRef(null);

  const validationSchema = yup.object({
    username: yup
      .string()
      .test({
        name: "not-email",
        message: "le pseudo n'est pas un email",
        test: function (value) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return !emailRegex.test(value as string);
        },
      })
      .required(" le pseudo est obligatoire "),

    birthday_date: yup
      .date()
      .required(" l'âge est obligatoire ")
      .min(
        new Date(new Date().getFullYear() - 90, 0, 1),
        "Vous devez avoir moins de 90 ans"
      )
      .max(
        new Date(new Date().getFullYear() - 18, 11, 31),
        "Vous devez avoir plus de 18 ans"
      ),
    city: yup.string().required(" la ville est obligatoire "),
    country: yup.string().when("origine", (val, schema) => {
      let country_val =
        (countryInputref as React.MutableRefObject<any>).current.value || "";
      if (country_val === "") {
        return yup.string().required(" le pays est obligatoire ");
      } else if (getAbbreviation(country_val) === "") {
        setCountry({
          country: country_val,
          abbreviation: getAbbreviation(country_val),
        });
        return yup.string().test({
          name: "incorrect_country",
          message: " Ce pays n'est pas valide ",
          test: function (value) {
            let result = true;
            if (getAbbreviation(country_val) === "") {
              result = false;
            }
            return result;
          },
        });
      } else {
        setCountry({
          country: country_val,
          abbreviation: getAbbreviation(country_val),
        });
        return yup.string().notRequired();
      }
    }),
  });

  const formik = useFormik({
    initialValues: {
      username:
        memberProfileFromStore?.infos?.identity?.username ||
        memberInfosFromStore?.username ||
        "",
      birthday_date:
        moment
          .default(memberProfileFromStore?.infos?.identity?.birthDate)
          .format("YYYY-MM-DD") || "",
      city: memberProfileFromStore?.infos?.identity?.city || "",
      country: memberProfileFromStore?.infos?.identity?.country || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const initialValues = {
        username: formik.initialValues.username.trim(),
        birthday_date: formik.initialValues.birthday_date,
        city: formik.initialValues.city.trim(),
        country: formik.initialValues.country.trim(),
      };
      const actualValues = {
        username: values.username.trim(),
        birthday_date: values.birthday_date,
        city: values.city.trim(),
        country: values.country.trim(),
      };
      if (_.isEqual(initialValues, actualValues)) {
        Toastify(`Aucun changement apporté à votre fiche.`, "warning", 3000);
      } else {
        const dataToSend: TFiche = {
          category: "identity",
          birthDate: values?.birthday_date + "T00:00:00.000Z" || "",
          city: values?.city.trim() || "",
          country: country.country.trim() || "",
          countryCode: country.abbreviation.trim() || "",
          username: values.username.trim() || "",
        };
        saveFiche(dataToSend);
      }
    },
  });

  const saveFiche = (data: TFiche) => {
    setLoading(true);
    apiSaveFiche(data)
      .then((response: any) => {
        setLoading(false);
        console.log(response);

        const profile = response.data as TMemberProfile;
        // profile.infos.identity = { ...memberProfileFromStore.infos.identity };
        dispatch(updateMemberProfile({ ...profile }));

        navigate(routePaths.profileChildren.myAccountChildren.formAbsolute, {
          replace: false,
        });
        Toastify(
          `Votre fiche a été enregistrée avec succès .`,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const goTo = (routes: string) => {
    navigate(routes, { replace: true });
  };

  const getAbbreviation = (selected_country: string): string => {
    let result = "";

    result =
      countries.find((x) => x.country === selected_country)?.abbreviation || "";

    return result;
  };

  const ps = useRef();

  const scrollTop = () => {
    const curr = (ps as React.MutableRefObject<any>).current;
    if (curr) {
      console.log("oohh");
      curr.scrollTop = 0;
    }
  };

  return (
    <>
      <div className="ct-fiche">
        <div className="ct-fiche__nav-part">
          <Button
            color="primary"
            className="ct-fiche__nav-part__btn-nav"
            onClick={() => {
              goTo(routePaths.profileChildren.myAccountChildren.formAbsolute);
            }}
            startIcon={
              <InlineIcon icon="material-symbols:lab-profile-outline" />
            }
          >
            Accédez à votre profil
          </Button>
          {!isUserProfileModificationApproved && (
            <>
              {!openAlertForUpdate && (
                <IconButton
                  size="small"
                  color={
                    isUserProfileModificationRejected
                      ? memberProfileFromStore?.status?.reason?.length > 0
                        ? "error"
                        : "warning"
                      : "info"
                  }
                  onClick={() => {
                    setOpenAlertForUpdate((prev) => !prev);
                    scrollTop();
                  }}
                  className="icon-blip"
                >
                  <InlineIcon
                    icon={
                      isUserProfileModificationRejected
                        ? memberProfileFromStore?.status?.reason?.length > 0
                          ? "material-symbols:error-outline-rounded"
                          : "material-symbols:warning-outline-rounded"
                        : "material-symbols:info-outline-rounded"
                    }
                  />
                </IconButton>
              )}
            </>
          )}
          {memberProfileFromStore?.status?.reason?.length > 0 && (
            <Button
              color="warning"
              className="ct-fiche__nav-part__btn-nav report"
              onClick={() => {
                setOpenModerationReport(true);
              }}
              startIcon={<InlineIcon icon="fluent-mdl2:report-warning" />}
            >
              Rapport de modération
            </Button>
          )}
        </div>
        <div
          className="ct-fiche__body-part"
          ref={(el) => ((ps as React.MutableRefObject<any>).current = el)}
        >
          {!isUserProfileModificationApproved && (
            <Collapse in={openAlertForUpdate}>
              <Alert
                severity={
                  isUserProfileModificationRejected
                    ? memberProfileFromStore?.status?.reason?.length > 0
                      ? "error"
                      : "warning"
                    : "info"
                }
                onClose={() => {
                  setOpenAlertForUpdate((prev) => !prev);
                }}
                className="ct-formulaire__body-part__alert"
              >
                {isUserProfileModificationRejected && (
                  <>
                    {memberProfileFromStore?.status?.reason?.length > 0 ? (
                      <>
                        <AlertTitle>Modifications rejetées</AlertTitle>
                        <>
                          Les modifications apportées à votre profil ont été{" "}
                          <b> rejetées</b> par notre équipe de modération pour
                          la(les) raison(s) suivante(s):
                          <ul
                            style={{
                              textAlign: "left",
                              paddingLeft: "20px",
                              paddingTop: "20px",
                              marginBottom: "20px",
                            }}
                          >
                            {memberProfileFromStore?.status?.reason?.map(
                              (x, index) => (
                                <li className="li-reason" key={index}>
                                  {x.category === "identity" && (
                                    <>
                                      Dans la{" "}
                                      <b
                                        onClick={() => {
                                          navigate(
                                            routePaths.profileChildren
                                              .myAccountChildren.ficheAbsolute,
                                            { replace: true }
                                          );
                                        }}
                                      >
                                        <InlineIcon
                                          icon="akar-icons:link-out"
                                          fontSize={12}
                                        />
                                        Fiche d'information
                                      </b>
                                      :
                                    </>
                                  )}
                                  {x.category === "profile" && (
                                    <>
                                      Dans la rubrique{" "}
                                      <b
                                        onClick={() => {
                                          navigate(
                                            routePaths.profileChildren
                                              .myAccountChildren.formAbsolute,
                                            {
                                              replace: true,
                                              state: { formStep: "1" },
                                            }
                                          );
                                        }}
                                      >
                                        <InlineIcon
                                          icon="akar-icons:link-out"
                                          fontSize={12}
                                        />
                                        Profile
                                      </b>
                                      :
                                    </>
                                  )}
                                  {x.category === "religiousAspects" && (
                                    <>
                                      Dans la rubrique{" "}
                                      <b
                                        onClick={() => {
                                          navigate(
                                            routePaths.profileChildren
                                              .myAccountChildren.formAbsolute,
                                            {
                                              replace: true,
                                              state: { formStep: "2" },
                                            }
                                          );
                                        }}
                                      >
                                        <InlineIcon
                                          icon="akar-icons:link-out"
                                          fontSize={12}
                                        />
                                        Aspect réligieux
                                      </b>
                                      :
                                    </>
                                  )}
                                  {x.category === "religiousPractices" && (
                                    <>
                                      Dans la rubrique{" "}
                                      <b
                                        onClick={() => {
                                          navigate(
                                            routePaths.profileChildren
                                              .myAccountChildren.formAbsolute,
                                            {
                                              replace: true,
                                              state: { formStep: "3" },
                                            }
                                          );
                                        }}
                                      >
                                        <InlineIcon
                                          icon="akar-icons:link-out"
                                          fontSize={12}
                                        />
                                        Pratiques réligieuses
                                      </b>
                                      :
                                    </>
                                  )}
                                  {x.category === "healthAndDisability" && (
                                    <>
                                      Dans la rubrique{" "}
                                      <b
                                        onClick={() => {
                                          navigate(
                                            routePaths.profileChildren
                                              .myAccountChildren.formAbsolute,
                                            {
                                              replace: true,
                                              state: { formStep: "4" },
                                            }
                                          );
                                        }}
                                      >
                                        <InlineIcon
                                          icon="akar-icons:link-out"
                                          fontSize={12}
                                        />
                                        Santé et handicap
                                      </b>
                                      :
                                    </>
                                  )}
                                  <b className="value">{x.value}</b>
                                </li>
                              )
                            )}
                          </ul>
                          <br />
                          <strong>
                            {" "}
                            Veuillez apporter les corrections adéquates à vos
                            modifications pour qu'elles puissent être prises en
                            compte.
                          </strong>
                          <br />
                          Avant l'approbation de vos modifications , votre
                          profil sera inaccessible aux autres membres
                          <br />
                        </>
                      </>
                    ) : (
                      <>
                        <AlertTitle>Profil en cours de modération</AlertTitle>
                        <>
                          Les modifications apportées à votre profil sont
                          toujours <b> en cours de vérification</b> par notre
                          équipe de modération.
                          <br />
                          Avant l'approbation de vos modifications , votre
                          profil sera inaccessible aux autres membres.
                          <br />
                        </>
                      </>
                    )}
                  </>
                )}
                {isUserProfileModificationPending && (
                  <>
                    Vous avez effectué des modifications dans votre profil.
                    <br />
                    <strong>
                      {" "}
                      vos modifications sont donc en cours de modération
                    </strong>
                    <br />
                    Durant la modération, votre profil est inaccessible aux
                    autres membres
                    <br />
                  </>
                )}
              </Alert>
            </Collapse>
          )}
          <div className="ct-fiche__body-part__card-part">
            <h4>
              {" "}
              <b>MA FICHE D'INFORMATION</b>{" "}
            </h4>
            <img
              src={require(
                "@/assets/images/png/avatar-" +
                  (memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ||
                    "male") +
                  ".png"
              )}
              alt="sidebar-logo"
              className="ct-fiche__card-part__avatar"
            />
            <form className="form-part" onSubmit={formik.handleSubmit}>
              <Box className="upper-box">
                <Grid container rowSpacing={4} columnSpacing={2}>
                  <Grid item xs={12} sm={12} lg={6} justifyContent="center">
                    <FormControl
                      fullWidth
                      color="primary"
                      className="gender-radio-row"
                    >
                      <FormLabel id="id-civilité">Civilité</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="id-civilité"
                        name="row-radio-buttons-group"
                        className="row-radio-buttons-group"
                        value={
                          memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ||
                          "male"
                        }
                      >
                        <FormControlLabel
                          value="male"
                          disabled
                          control={<Radio />}
                          label="Mr"
                        />
                        <FormControlLabel
                          value="female"
                          disabled
                          control={<Radio />}
                          label="Mme"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      placeholder="Pseudo"
                      variant="outlined"
                      label="Pseudo **"
                      name="username"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                      error={
                        formik.touched.username &&
                        Boolean(formik.errors.username)
                      }
                      helperText={
                        formik.touched.username && formik.errors.username
                      }
                      type="text"
                      // disabled={
                      //   (userConnected as TUserConnected).infos.username !== ""
                      // }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container rowSpacing={4} columnSpacing={2}>
                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      placeholder="Prénom"
                      variant="outlined"
                      label="Prénom"
                      value={
                        memberProfileFromStore?.infos?.identity?.firstname || ""
                      }
                      disabled
                      type="text"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      placeholder="Nom"
                      variant="outlined"
                      label="Nom"
                      value={
                        memberProfileFromStore?.infos?.identity?.lastname || ""
                      }
                      disabled
                      type="text"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <TextField
                      placeholder="Votre adresse email"
                      variant="outlined"
                      label="Email"
                      value={memberInfosFromStore?.email || ""}
                      disabled
                      type="text"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      placeholder="Votre date de naissance"
                      variant="outlined"
                      label="Date de naissance **"
                      name="birthday_date"
                      onChange={formik.handleChange}
                      value={formik.values.birthday_date}
                      error={
                        formik.touched.birthday_date &&
                        Boolean(formik.errors.birthday_date)
                      }
                      helperText={
                        formik.touched.birthday_date &&
                        formik.errors.birthday_date
                      }
                      type="date"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">  </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      placeholder="Votre numéro de téléphone"
                      variant="outlined"
                      label="Numéro de téléphone"
                      value={
                        memberProfileFromStore?.infos?.identity?.phoneNumber ||
                        ""
                      }
                      disabled
                      type="text"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      placeholder="Votre ville"
                      variant="outlined"
                      label="Ville **"
                      name="city"
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                      type="text"
                      // disabled={
                      //   (userConnected as TUserConnected).contacts?.city !== null
                      // }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <Autocomplete
                      id="country-select-demo"
                      options={countries}
                      value={country}
                      onChange={handleCountryChange}
                      autoHighlight
                      getOptionLabel={(option) => option.country}
                      // disabled={
                      //   (userConnected as TUserConnected).contacts?.country !==
                      //     null &&
                      //   (userConnected as TUserConnected).contacts
                      //     ?.country_code !== null
                      // }
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          <InlineIcon
                            style={{ borderRadius: "50%" }}
                            icon={`flag:${option.abbreviation.toLowerCase()}-1x1`}
                          />{" "}
                          &nbsp;
                          {option.country}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <>
                          <InlineIcon
                            className="flag"
                            icon={`flag:${country.abbreviation.toLowerCase()}-1x1`}
                          />
                          <TextField
                            {...params}
                            placeholder="Votre Pays"
                            variant="outlined"
                            label="Pays **"
                            name="country"
                            inputRef={countryInputref}
                            onChange={formik.handleChange}
                            value={formik.values.country}
                            error={
                              formik.touched.country &&
                              Boolean(formik.errors.country)
                            }
                            helperText={
                              formik.touched.country && formik.errors.country
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        </>
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12}
                    sx={{ textAlign: "center" }}
                  >
                    <Typography variant="caption" color="primary">
                      ** INFORMATION <b>UNIQUEMENT</b> VISIBLE PAR
                      L’ADMINISTRATION DE ZAWAJ SUNNAH
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              {/* {((userConnected as TUserConnected).contacts?.country === null ||
              (userConnected as TUserConnected).contacts?.country_code ===
                null ||
              (userConnected as TUserConnected).infos.username === "" ||
              (userConnected as TUserConnected).contacts?.city === null ||
              (userConnected as TUserConnected).infos.age.toString() ===
                "")  && ( */}
              <LoadingButton
                loading={loading}
                loadingPosition="center"
                variant="contained"
                color="tertiary"
                sx={{ marginTop: "30px" }}
                type="submit"
              >
                ENREGISTRER
              </LoadingButton>
              {/* )} */}
            </form>
          </div>
        </div>
      </div>
      {openModerationReport && (
        <CModalRejectedModif
          open={openModerationReport}
          setOpen={setOpenModerationReport}
        />
      )}
    </>
  );
};

export default Fiche;
