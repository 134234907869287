import React, { useCallback, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import "./ModalResearch.styles.scss";
import { InlineIcon } from "@iconify/react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";

import { apiGetSearchEthniesAndOrigins } from "@/features/profile/api/search.api";
import { TFilteredQuery } from "@/features/profile/types";
import { store } from "@/store";
import {
  updateFormQuery,
  updateSearchQuery,
} from "@/store/reducers/general_param/general_param.actions";
import { selectFormQuery } from "@/store/reducers/general_param/general_param.selector";
import { updateLocation } from "@/store/reducers/member/member.actions";
import { Toastify } from "@/utils/toast";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { MultipleSelectChip } from "../MultipleSelectChip/MultipleSelectChip";
import { TMemberProfile } from "@/features/auth/types";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

interface ModalResearchProps {
  open: boolean;
  loadingFromHome: boolean;
  onClose:
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined;
  closeClick: () => void;
  applyFilter: (query: string) => void;
}
const ModalResearch = ({
  open,
  loadingFromHome,
  onClose,
  closeClick,
  applyFilter,
}: ModalResearchProps) => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;
  const form_query = useSelector(selectFormQuery);

  const [loadingList, setLoadingList] = useState(false);

  const navigate = useNavigate();

  type TCheckBoxList = Array<{ name: string; checked: boolean }> | [];
  const [originList, setOriginList] = React.useState<TCheckBoxList>([]);
  const handleOriginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempList = originList;

    if (event.target.name === "Peu importe") {
      tempList.map((et) => (et.checked = event.target.checked));
    } else {
      const exist = tempList.findIndex((elt) => elt.name === event.target.name);
      if (exist !== -1) tempList[exist].checked = event.target.checked;

      const anyone = tempList.findIndex((elt) => elt.name === "Peu importe");
      if (anyone !== -1) tempList[anyone].checked = false;
    }

    setOriginList([...tempList]);
  };
  const [ethniesList, setEthiesList] = React.useState<TCheckBoxList>([]);
  const handleEthniesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempList = ethniesList;

    if (event.target.name === "Peu importe") {
      tempList.map((et) => (et.checked = event.target.checked));
    } else {
      const exist = tempList.findIndex((elt) => elt.name === event.target.name);
      if (exist !== -1) tempList[exist].checked = event.target.checked;

      const anyone = tempList.findIndex((elt) => elt.name === "Peu importe");
      if (anyone !== -1) tempList[anyone].checked = false;
    }

    setEthiesList([...tempList]);
  };
  const [countriesResidenceList, setCountriesResidenceList] =
    React.useState<TCheckBoxList>([]);
  const handleCountriesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const tempList = countriesResidenceList;

    if (event.target.name === "Peu importe") {
      tempList.map((et) => (et.checked = event.target.checked));
    } else {
      const exist = tempList.findIndex((elt) => elt.name === event.target.name);
      if (exist !== -1) tempList[exist].checked = event.target.checked;

      const anyone = tempList.findIndex((elt) => elt.name === "Peu importe");
      if (anyone !== -1) tempList[anyone].checked = false;
    }

    setCountriesResidenceList([...tempList]);
  };

  const tenues_vestimentaires_femmes_list = [
    "Ne porte pas encore le voile",
    "Porte le turban",
    "Porte le Hijab",
    "Porte le Jilbab",
    "Porte le Niqab",
    "L'Abaya",
  ];

  const situation_matrimoniale_femme_list = [
    "Jamais Mariée",
    "Divorcée",
    "Veuve",
  ];

  const situation_matrimoniale_homme_list = [
    "Jamais Marié",
    "Divorcé",
    "Veuf",
    "Marié",
  ];

  const validationSchema = yup.object({
    age_minimum: yup
      .number()
      .min(18, " l'âge minimum est de 18 ans")
      .max(70, " l'âge maximum est de 70 ans")
      .test({
        name: "min_over_max",
        message: " l'âge minimum doit être inférieure à l'âge maximum ",
        test: function (value, ctx) {
          let result = false;
          if (value) {
            if (ctx.parent.age_maximum) {
              result = value < ctx.parent.age_maximum;
            } else {
              result = value < 70;
            }
          } else {
            result = true;
          }
          return result;
        },
      }),
    age_maximum: yup
      .number()
      .min(18, " l'âge minimum est de 18 ans")
      .max(70, " l'âge maximum est de 70 ans")
      .test({
        name: "max_over_min",
        message: " l'âge maximum doit être supérieure à l'âge minimum ",
        test: function (value, ctx) {
          let result = false;
          if (value) {
            if (ctx.parent.age_minimum) {
              result = value > ctx.parent.age_minimum;
            } else {
              result = true;
            }
          } else {
            result = true;
          }
          return result;
        },
      }),
    distance_parcouru: yup
      .number()
      .min(0, "la distance parcouru doit être positive"),
  });

  const formik = useFormik({
    initialValues: {
      age_minimum: form_query.age_minimum,
      age_maximum: form_query.age_maximum,
      tenue_vestimentaire_femme: form_query.tenue_vestimentaire_femme,
      fast_filter: form_query.fast_filter,
      //tenue_vestimentaire_homme: '',
      //distance_parcouru: '',
      situation_matrimoniale: form_query.situation_matrimoniale,
      situation_parentale: form_query.situation_parentale,
      accepte_enfants: form_query.accepte_enfants,
      accepte_married_man: form_query.accepte_married_man,
      profils_salafi: form_query.profils_salafi,
      accepte_demenager: form_query.accepte_demenager,
      etant_hijra: form_query.etant_hijra,
      souhait_hijra: form_query.souhait_hijra,
      recherche_mon_ethnie: form_query.recherche_mon_ethnie,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const dataToSend = {
        ...values,
        tenue_vestimentaire_femme: setArrayToGoodValue(
          values.tenue_vestimentaire_femme
        ),
        situation_matrimoniale: setArrayToGoodValue(
          values.situation_matrimoniale
        ),
        originList: cleanList(originList),
        ethniesList: cleanList(ethniesList),
        countriesResidence: cleanList(countriesResidenceList),
      };
      console.log(dataToSend);

      store.dispatch(
        updateFormQuery({
          ...values,
          originList: dataToSend.originList,
          ethniesList: dataToSend.ethniesList,
          countriesResidence: dataToSend.countriesResidence,
        } as TFilteredQuery)
      );

      const queryToSend = buildQueryString(dataToSend);
      console.log(queryToSend);
      store.dispatch(updateSearchQuery(queryToSend));
      console.log("helloooooo:::::::", queryToSend);

      applyFilter(queryToSend);

      // launchSearchRequest(queryToSend);
    },
    onReset: (values) => {
      resetFilter();
      applyFilter("");
    },
  });

  const resetFilter = useCallback(() => {
    const initValues = {
      age_minimum: "",
      age_maximum: "",
      tenue_vestimentaire_femme: [],
      fast_filter: "",
      //tenue_vestimentaire_homme: '',
      //distance_parcouru: '',
      situation_matrimoniale: [],
      situation_parentale: "",
      accepte_enfants: "",
      accepte_married_man: "",
      profils_salafi: "",
      accepte_demenager: "",
      etant_hijra: "",
      souhait_hijra: "",
      recherche_mon_ethnie: "",
      originList: [],
      ethniesList: [],
      countriesResidence: [],
    };

    const tempOriginList = originList.map((et) => {
      return {
        ...et,
        checked: false,
      };
    });
    setOriginList([...tempOriginList]);

    const tempEthniesList = ethniesList.map((et) => {
      return {
        ...et,
        checked: false,
      };
    });
    setEthiesList([...tempEthniesList]);

    const tempCountriesResidenceList = countriesResidenceList.map((et) => {
      return {
        ...et,
        checked: false,
      };
    });
    setCountriesResidenceList([...tempCountriesResidenceList]);

    store.dispatch(updateFormQuery({ ...initValues } as TFilteredQuery));
    store.dispatch(updateSearchQuery(""));
    // formik.resetForm();

    Toastify(
      `Le filtre de recherche a bien été réinitialisé `,
      "success",
      5000
    );
    // window.location.reload();
  }, [countriesResidenceList, ethniesList, originList]);

  const buildQueryString = (values: any): string => {
    let result = "";

    if (values.fast_filter === "consulted") {
      result = result.concat("&isVisited=", "true");
    }
    if (values.fast_filter === "not_consulted") {
      result = result.concat("&isVisited=", "false");
    }
    if (values.fast_filter === "my_spectators") {
      result = result.concat("&hasVisited=", "true");
    }

    if (values.age_minimum !== "")
      result = result.concat("&minAge=", values.age_minimum);

    if (values.age_maximum !== "")
      result = result.concat("&maxAge=", values.age_maximum);

    if (values.tenue_vestimentaire_femme.length > 0)
      result = result.concat(
        "&femaleDressCode[]=",
        values.tenue_vestimentaire_femme.join("&femaleDressCode[]=")
      );

    if (values.situation_matrimoniale.length > 0)
      result = result.concat(
        "&maritalStatus[]=",
        values.situation_matrimoniale.join("&maritalStatus[]=")
      );

    if (values.situation_parentale !== "")
      result = result.concat("&haveChildren=", values.situation_parentale);

    if (values.accepte_enfants !== "")
      result = result.concat("&acceptChildren=", values.accepte_enfants);

    if (values.accepte_married_man !== "")
      result = result.concat("&acceptMarriedMan=", values.accepte_married_man);

    if (values.profils_salafi !== "")
      result = result.concat("&isSalafi=", values.profils_salafi);

    if (values.accepte_demenager !== "")
      result = result.concat("&agreeToMove=", values.accepte_demenager);

    if (values.etant_hijra !== "")
      result = result.concat("&isInHijra=", values.etant_hijra);

    if (values.souhait_hijra !== "")
      result = result.concat("&likeToMakeHijra=", values.souhait_hijra);

    if (values.recherche_mon_ethnie !== "")
      result = result.concat("&wantMyEthnicity=", values.recherche_mon_ethnie);

    if (values.ethniesList.length > 0)
      result = result.concat(
        "&wantedEthnicities[]=",
        customArrayToString(values.ethniesList, false, "&wantedEthnicities[]=")
      );

    if (values.originList.length > 0)
      result = result.concat(
        "&wantedCountries[]=",
        customArrayToString(values.originList, false, "&wantedCountries[]=")
      );

    if (values.countriesResidence.length > 0) {
      result = result.concat(
        "&wantedCountriesResidence[]=",
        customArrayToString(
          values.countriesResidence,
          false,
          "&wantedCountriesResidence[]="
        )
      );
    }

    return result.substring(0);
  };

  const cleanList = (data: TCheckBoxList): TCheckBoxList => {
    let result: TCheckBoxList = [];
    result = data.filter((item) => {
      return item.checked === true && item.name !== "Peu importe";
    });
    return result;
  };

  const setArrayToGoodValue = (arr: string[]): string[] => {
    let result: string[] = [];

    arr.forEach((elt) => {
      // Tenue vestimentaire
      if (elt === "Ne porte pas encore le voile")
        result.push("notWearHeadscarf");
      if (elt === "Porte le turban") result.push("wearTurban");
      if (elt === "Porte le Hijab") result.push("wearHijab");
      if (elt === "Porte le Jilbab") result.push("wearJilbab");
      if (elt === "Porte le Niqab") result.push("wearNiqab");
      if (elt === "L'Abaya") result.push("wearAbaya");

      // Situation maritale
      if (elt === "Jamais Mariée" || elt === "Jamais Marié")
        result.push("NEVER_MARRIED");
      if (elt === "Divorcée" || elt === "Divorcé") result.push("DIVORCED");
      if (elt === "Veuve" || elt === "Veuf") result.push("WIDOW");
      if (elt === "Marié") result.push("MARRIED");
    });

    return result;
  };

  const customArrayToString = (
    arr: any[],
    isId: boolean = false,
    joinString: string = ","
  ): string => {
    let result: string[] = [];

    if (isId) {
      arr.forEach((elt) => {
        result.push(elt.id);
      });
    } else {
      arr.forEach((elt) => {
        result.push(elt.name);
      });
    }

    return result.join(joinString);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      var location = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
      console.log(location);
      store.dispatch(updateLocation(location));
    });

    setLoadingList(true);

    open &&
      apiGetSearchEthniesAndOrigins()
        .then((response: any) => {
          setLoadingList(false);
          console.log(response);

          let tempList = response.data.origins.map((data: any) => {
            return {
              name: data,
              checked:
                form_query.originList.find((x) => x.name === data) === undefined
                  ? false
                  : true,
            };
          });
          setOriginList([{ name: "Peu importe", checked: false }, ...tempList]);

          let tempList2 = response.data.ethnicities.map((data: any) => {
            return {
              id: data._id,
              name: data.name,
              checked:
                form_query.ethniesList.find((x) => x.name === data.name) ===
                undefined
                  ? false
                  : true,
            };
          });

          setEthiesList([
            { name: "Peu importe", checked: false },
            ...tempList2,
          ]);

          if (!form_query.countriesResidence) {
            store.dispatch(
              updateFormQuery({ ...form_query, countriesResidence: [] })
            );
          }

          let tempList3 = response.data.countriesOfResidence.map(
            (data: any) => {
              return {
                name: data,
                checked:
                  form_query?.countriesResidence?.find(
                    (x: any) => x.name === data
                  ) === undefined
                    ? false
                    : true,
              };
            }
          );

          setCountriesResidenceList([
            { name: "Peu importe", checked: false },
            ...tempList3,
          ]);
        })
        .catch((error) => {
          setLoadingList(false);
          if (false) {
            console.log(error);
            error?.response?.data?.message &&
              Toastify(`${error?.response?.data?.message}`, "error", 5000);
          }
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <div className="c-modal-research">
          <div className="c-modal-research__title-part">
            <IconButton
              className="back-icon"
              size="medium"
              color="secondary"
              onClick={closeClick}
            >
              <InlineIcon icon="tabler:chevron-left" />
            </IconButton>
            <span className="title">Critères de recherches</span>
            <IconButton
              className="close-icon"
              size="medium"
              color="secondary"
              onClick={closeClick}
            >
              <InlineIcon icon="iconamoon:close-bold" />
            </IconButton>
          </div>
          <div className="c-modal-research__actions-part">
            <Chip
              className="action-chip erase"
              color="primary"
              variant="outlined"
              size="medium"
              label="Réinitialiser"
              icon={<InlineIcon icon={"carbon:reset"} />}
              onClick={formik.handleReset}
            />

            <Chip
              className="action-chip"
              color="primary"
              variant="filled"
              size="medium"
              label="Effectuer le filtre"
              icon={
                <InlineIcon
                  icon={
                    loadingFromHome
                      ? "eos-icons:three-dots-loadingFromHome"
                      : "mingcute:check-fill"
                  }
                />
              }
              onClick={() => {
                !loadingFromHome && formik.handleSubmit();
              }}
            />
          </div>
          {loadingFromHome ? (
            <>
              <img
                src={require("@/assets/images/gif/loadmore.gif")}
                height="70"
                width="70"
                className="load-more"
                alt="load-more"
              />
            </>
          ) : (
            <form
              className="c-modal-research__body-part"
              onSubmit={formik.handleSubmit}
              onReset={formik.handleReset}
            >
              <Box>
                <Grid container rowSpacing={3} columnSpacing={2}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <p className="label">Filtre rapide</p>
                    <FormControl
                      fullWidth
                      color="primary"
                      className="input-radio"
                    >
                      <RadioGroup
                        row
                        aria-labelledby="my-label-radio"
                        name="fast_filter"
                        value={formik.values.fast_filter}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="consulted"
                          control={<Radio />}
                          label="Profiles consultés"
                        />
                        <FormControlLabel
                          value="not_consulted"
                          control={<Radio />}
                          label="Profiles non consultés"
                        />
                        <FormControlLabel
                          value="my_spectators"
                          control={<Radio />}
                          label="Mes spectateurs"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <p className="label">Âge minimum (18) </p>
                    <TextField
                      placeholder=""
                      variant="outlined"
                      name="age_minimum"
                      onChange={formik.handleChange}
                      value={formik.values.age_minimum}
                      error={
                        formik.touched.age_minimum &&
                        Boolean(formik.errors.age_minimum)
                      }
                      helperText={
                        formik.touched.age_minimum && formik.errors.age_minimum
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <span className="suffix"> ans </span>
                          </InputAdornment>
                        ),
                      }}
                      type="number"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <p className="label">Âge maximum (70)</p>
                    <TextField
                      placeholder=""
                      variant="outlined"
                      name="age_maximum"
                      onChange={formik.handleChange}
                      value={formik.values.age_maximum}
                      error={
                        formik.touched.age_maximum &&
                        Boolean(formik.errors.age_maximum)
                      }
                      helperText={
                        formik.touched.age_maximum && formik.errors.age_maximum
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <span className="suffix"> ans </span>
                          </InputAdornment>
                        ),
                      }}
                      type="number"
                    />
                  </Grid>

                  {memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ===
                    "male" && (
                    <>
                      <Grid item xs={12} sm={12} lg={12}>
                        <p className="label">Tenue vestimentaire</p>
                        <MultipleSelectChip
                          name="tenue_vestimentaire_femme"
                          selectOptions={tenues_vestimentaires_femmes_list}
                          value={formik.values.tenue_vestimentaire_femme}
                          onChange={formik.handleChange}
                          labelId="id-select-tenue-femme"
                          label=""
                          errorText={formik.errors.tenue_vestimentaire_femme}
                          error={
                            formik.touched.tenue_vestimentaire_femme &&
                            Boolean(formik.errors.tenue_vestimentaire_femme)
                          }
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={12} lg={12}>
                    {memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ===
                      "female" && (
                      <>
                        <p className="label">Situation matrimoniale</p>
                        <MultipleSelectChip
                          name="situation_matrimoniale"
                          selectOptions={situation_matrimoniale_homme_list}
                          value={formik.values.situation_matrimoniale}
                          onChange={formik.handleChange}
                          labelId="id-situation_matrimoniale"
                          label=""
                          errorText={formik.errors.situation_matrimoniale}
                          error={
                            formik.touched.situation_matrimoniale &&
                            Boolean(formik.errors.situation_matrimoniale)
                          }
                        />
                      </>
                    )}
                    {memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ===
                      "male" && (
                      <>
                        <p className="label">Situation matrimoniale</p>
                        <MultipleSelectChip
                          name="situation_matrimoniale"
                          selectOptions={situation_matrimoniale_femme_list}
                          value={formik.values.situation_matrimoniale}
                          onChange={formik.handleChange}
                          labelId="id-situation_matrimoniale"
                          label=""
                          errorText={formik.errors.situation_matrimoniale}
                          error={
                            formik.touched.situation_matrimoniale &&
                            Boolean(formik.errors.situation_matrimoniale)
                          }
                        />
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p className="label">Situation parentale</p>
                    <FormControl
                      fullWidth
                      color="primary"
                      className="input-radio"
                    >
                      <RadioGroup
                        row
                        aria-labelledby="my-label-radio"
                        name="situation_parentale"
                        value={formik.values.situation_parentale}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Avec enfants"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Sans enfants"
                        />
                        <FormControlLabel
                          value=""
                          control={<Radio />}
                          label="Peu importe"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ===
                    "male" && (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p className="label">Qui accepte les hommes mariés ?</p>
                        <FormControl
                          fullWidth
                          color="primary"
                          className="input-radio"
                        >
                          <RadioGroup
                            row
                            aria-labelledby="my-label-radio"
                            name="accepte_married_man"
                            value={formik.values.accepte_married_man}
                            onChange={formik.handleChange}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="Non"
                            />
                            <FormControlLabel
                              value=""
                              control={<Radio />}
                              label="Peu importe"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p className="label">Qui accepte les enfants ?</p>
                    <FormControl
                      fullWidth
                      color="primary"
                      className="input-radio"
                    >
                      <RadioGroup
                        row
                        aria-labelledby="my-label-radio"
                        name="accepte_enfants"
                        value={formik.values.accepte_enfants}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Non"
                        />
                        <FormControlLabel
                          value=""
                          control={<Radio />}
                          label="Peu importe"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p className="label">
                      N'afficher que les profils salafis ?
                    </p>
                    <FormControl
                      fullWidth
                      color="primary"
                      className="input-radio"
                    >
                      <RadioGroup
                        row
                        aria-labelledby="my-label-radio"
                        name="profils_salafi"
                        value={formik.values.profils_salafi}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Non"
                        />
                        <FormControlLabel
                          value=""
                          control={<Radio />}
                          label="Peu importe"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p className="label">Qui accepte de déménager ?</p>
                    <FormControl
                      fullWidth
                      color="primary"
                      className="input-radio"
                    >
                      <RadioGroup
                        row
                        aria-labelledby="my-label-radio"
                        name="accepte_demenager"
                        value={formik.values.accepte_demenager}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Non"
                        />
                        <FormControlLabel
                          value=""
                          control={<Radio />}
                          label="Peu importe"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p className="label">Étant en hijra ?</p>
                    <FormControl
                      fullWidth
                      color="primary"
                      className="input-radio"
                    >
                      <RadioGroup
                        row
                        aria-labelledby="my-label-radio"
                        name="etant_hijra"
                        value={formik.values.etant_hijra}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Non"
                        />
                        <FormControlLabel
                          value=""
                          control={<Radio />}
                          label="Peu importe"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p className="label">Souhaitant faire la hijra ?</p>
                    <FormControl
                      fullWidth
                      color="primary"
                      className="input-radio"
                    >
                      <RadioGroup
                        row
                        aria-labelledby="my-label-radio"
                        name="souhait_hijra"
                        value={formik.values.souhait_hijra}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Non"
                        />
                        <FormControlLabel
                          value=""
                          control={<Radio />}
                          label="Peu importe"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p className="label">
                      Afficher les profils qui recherchent mon ethnie
                    </p>
                    <FormControl
                      fullWidth
                      color="primary"
                      className="input-radio"
                    >
                      <RadioGroup
                        row
                        aria-labelledby="my-label-radio"
                        name="recherche_mon_ethnie"
                        value={formik.values.recherche_mon_ethnie}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Non"
                        />
                        <FormControlLabel
                          value=""
                          control={<Radio />}
                          label="Peu importe"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <p className="label">Origines</p>
                    <FormControl className="yel-checkbox-list">
                      <FormGroup className="yel-checkbox-option-container">
                        {loadingList ? (
                          <img
                            src={require("@/assets/images/gif/loadmore.gif")}
                            height="70"
                            width="70"
                            alt="load-more"
                          />
                        ) : (
                          originList.map((origin, index) => {
                            return (
                              <FormControlLabel
                                key={index}
                                color="primary"
                                control={
                                  <Checkbox
                                    key={index}
                                    color="primary"
                                    checked={origin.checked}
                                    onChange={handleOriginChange}
                                    name={origin.name}
                                  />
                                }
                                label={origin.name}
                              />
                            );
                          })
                        )}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <p className="label">Ethnies</p>
                    <FormControl className="yel-checkbox-list">
                      <FormGroup className="yel-checkbox-option-container">
                        {loadingList ? (
                          <img
                            src={require("@/assets/images/gif/loadmore.gif")}
                            height="70"
                            width="70"
                            alt="load-more"
                          />
                        ) : (
                          ethniesList.map((ethny, index) => {
                            return (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    key={index}
                                    color="primary"
                                    checked={ethny.checked}
                                    onChange={handleEthniesChange}
                                    name={ethny.name}
                                  />
                                }
                                label={ethny.name}
                              />
                            );
                          })
                        )}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <p className="label">Pays de résidence</p>
                    <FormControl className="yel-checkbox-list">
                      <FormGroup className="yel-checkbox-option-container">
                        {loadingList ? (
                          <img
                            src={require("@/assets/images/gif/loadmore.gif")}
                            height="70"
                            width="70"
                            alt="load-more"
                          />
                        ) : (
                          countriesResidenceList.map((country, index) => {
                            return (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    key={index}
                                    color="primary"
                                    checked={country.checked}
                                    onChange={handleCountriesChange}
                                    name={country.name}
                                  />
                                }
                                label={country.name}
                              />
                            );
                          })
                        )}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </div>
      </Slide>
    </Modal>
  );
};

export default ModalResearch;
