import { InlineIcon } from "@iconify/react";
import {
  Button,
  Chip,
  Grow,
  InputAdornment,
  SpeedDial,
  SpeedDialAction,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Live.styles.scss";
import LiveCard, { TLiveCard } from "../../Elements/LiveCard/LiveCard";
import NoData from "@/components/No-data/NoData";
import { apiGetLive } from "../../api/live.api";
import { Toastify } from "@/utils/toast";
import * as moment from "moment";
import "moment/locale/fr";
import { updateNbreLiveSocket } from "@/store/reducers/general_param/general_param.actions";
import { store } from "@/store";
import { socketChannels } from "@/config/socketChannels";
import { AppSocketContext } from "@/routes";
import useUserRestrictions from "@/hooks/useUserRestriction";
import UserRestrictionDisplayer from "@/components/UserRestrictionDisplayer/UserRestrictionDisplayer";
import ModalUserRestrictionWhenPaid from "@/components/Modal-user-restriction-when-paid/ModalUserRestrictionWhenPaid";
import * as _ from "lodash";

type TFilteredValue = "à venir" | "passés" | "En direct" | "";

const CtLive = () => {
  const { isUserFullAccessAutorized } = useUserRestrictions();
  const { socketIO } = useContext(AppSocketContext);

  const [activeButton, setActiveButton] = useState<TFilteredValue>("");

  const theme = useTheme();
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchedText, setSearchedText] = useState("");
  const handleSearchChange = (e: any) => {
    setSearchedText(e?.target?.value);
  };

  const actions = [
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "Tout" },
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "Passés" },
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "En_direct" },
    { icon: <InlineIcon icon="clarity:filter-2-line" />, name: "À_venir" },
  ];

  const [loading, setLoading] = useState(false);
  const [fakeItems, setFakeItems] = useState<TLiveCard[]>([]);
  const [page, setPage] = useState(1);
  const [hasmore, setHasmore] = useState(true);

  const fetchMoreData = () => {
    setPage(page + 1);
    console.log(page);
    //setLoading(true);

    isUserFullAccessAutorized &&
      apiGetLive(page, 6)
        .then((response: any) => {
          //setLoading(false);
          console.log(response);

          if (page === response.data.paginationResult.totalPages) {
            setHasmore(false);
          }

          let tempList: TLiveCard[] = response.data.result.map((data: any) => {
            return {
              _id: data._id,
              title: data.subject,
              host: data.speaker.name,
              type:
                moment
                  .default(data.liveDate)
                  .diff(moment.default(new Date()), "hours") > 0
                  ? "à venir"
                  : moment
                        .default(data.liveDate)
                        .diff(moment.default(new Date()), "hours") === 0
                    ? "En direct"
                    : "passés",
              link: data.link,
              note: data.note,
              live_date: data.liveDate,
            };
          });

          console.log([...tempList]);
          setFakeItems([...tempList]);
        })
        .catch((error) => {
          //setLoading(false);
          //Toastify(`${error?.response?.data?.message}`, 'error', 5000);
        });
  };

  useEffect(() => {
    store.dispatch(updateNbreLiveSocket(0));

    isUserFullAccessAutorized && setLoading(true);

    isUserFullAccessAutorized &&
      apiGetLive(1, 6)
        .then((response: any) => {
          setLoading(false);
          console.log(response);

          let tempList: TLiveCard[] = response.data.result.map((data: any) => {
            return {
              _id: data._id,
              title: data.subject,
              host: data.speaker.name,
              type:
                moment
                  .default(data.liveDate)
                  .diff(moment.default(new Date()), "hours") > 0
                  ? "à venir"
                  : moment
                        .default(data.liveDate)
                        .diff(moment.default(new Date()), "hours") === 0
                    ? "En direct"
                    : "passés",
              link: data.link,
              note: data.note,
              live_date: data.liveDate,
            };
          });

          console.log([...tempList]);
          setFakeItems([...tempList]);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          error?.response?.data?.message &&
            Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });

    return () => {
      store.dispatch(updateNbreLiveSocket(0));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserFullAccessAutorized]);

  useEffect(() => {
    if (socketIO) {
      socketIO.on(socketChannels.get_live_channel, (data) => {
        console.log("Socket Lives ✌️", data);

        const newLive: any = {
          _id: data._id,
          title: data.subject,
          host: data.speaker.name,
          type:
            moment
              .default(data.liveDate)
              .diff(moment.default(new Date()), "hours") > 0
              ? "à venir"
              : moment
                    .default(data.liveDate)
                    .diff(moment.default(new Date()), "hours") === 0
                ? "En direct"
                : "passés",
          link: data.link,
          note: data.note,
          live_date: data.liveDate,
        };

        const findIndex = fakeItems.findIndex((elt) => elt._id === newLive._id);
        if (findIndex !== -1) {
          setFakeItems(
            fakeItems.map((elt) => (elt._id === newLive._id ? newLive : elt))
          );
        } else {
          setFakeItems([{ ...newLive }, ...fakeItems]);
        }
      });

      socketIO.on(socketChannels.delete_live_channel, (data) => {
        console.log("🌕🌕🌕", data);
        const tempArr = [...fakeItems];
        const removedItem = _.remove(tempArr, (x) => {
          return x._id === data;
        });
        console.log(removedItem);
        setFakeItems([...tempArr]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketIO, fakeItems]);

  return (
    <>
      {isUserFullAccessAutorized ? (
        <div className="ct-live">
          <div className="ct-live__filter-part">
            <TextField
              placeholder="Titre du live / Nom de l'hôte"
              variant="outlined"
              label="Rechercher"
              name="recherche"
              className="searchInput"
              type="text"
              value={searchedText}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InlineIcon icon="bi:search" />
                  </InputAdornment>
                ),
              }}
            />

            {matches_sm && (
              <>
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  className="ct-live__filter-part__speed-dial"
                  direction="down"
                  icon={
                    <InlineIcon icon="ep:filter" style={{ fontSize: "20px" }} />
                  }
                >
                  {actions.map((action, index) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      tooltipOpen
                      onClick={() => {
                        if (index === 0) {
                          // Tout
                          setActiveButton("");
                        }
                        if (index === 1) {
                          // Imam
                          setActiveButton("passés");
                        }
                        if (index === 2) {
                          // Prédicateur
                          setActiveButton("En direct");
                        }
                        if (index === 3) {
                          // Prédicateur
                          setActiveButton("à venir");
                        }
                      }}
                    />
                  ))}
                </SpeedDial>
                {activeButton !== "" && (
                  <Chip
                    label={activeButton.toUpperCase()}
                    onDelete={() => {
                      setActiveButton("");
                    }}
                    sx={{ width: "100%" }}
                  />
                )}
              </>
            )}

            {!matches_sm && (
              <>
                <Button
                  variant={activeButton === "passés" ? "outlined" : "contained"}
                  onClick={() => {
                    setActiveButton("passés");
                  }}
                  color="primary"
                  className="ct-live__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  Passés
                </Button>
                <Button
                  variant={
                    activeButton === "En direct" ? "outlined" : "contained"
                  }
                  onClick={() => {
                    setActiveButton("En direct");
                  }}
                  color="primary"
                  className="ct-live__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  En direct
                </Button>
                <Button
                  variant={
                    activeButton === "à venir" ? "outlined" : "contained"
                  }
                  onClick={() => {
                    setActiveButton("à venir");
                  }}
                  color="primary"
                  className="ct-live__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  À venir
                </Button>
                <Button
                  variant={activeButton === "" ? "outlined" : "contained"}
                  onClick={() => {
                    setActiveButton("");
                  }}
                  color="primary"
                  className="ct-live__filter-part__btn-nav"
                  startIcon={<InlineIcon icon="clarity:filter-2-line" />}
                >
                  Tout
                </Button>
              </>
            )}
          </div>

          {loading ? (
            <img
              src={require("@/assets/images/gif/loadmore.gif")}
              height="70"
              width="70"
              className="load-more"
              alt="load-more"
            />
          ) : (
            <PerfectScrollbar id="scrollableDiv">
              <InfiniteScroll
                className="ct-live__body-part"
                scrollableTarget="scrollableDiv"
                dataLength={fakeItems.length}
                next={fetchMoreData}
                hasMore={hasmore}
                loader={
                  fakeItems
                    .filter((value) => {
                      const fullname = value.title + value.host;
                      return (
                        fullname
                          .toLocaleLowerCase()
                          .indexOf(searchedText.toLocaleLowerCase()) !== -1
                      );
                    })
                    .filter((value) => {
                      return value.type.indexOf(activeButton) !== -1;
                    }).length > 0 ? (
                    <img
                      src={require("@/assets/images/gif/loadmore.gif")}
                      className="load-more"
                      alt="load-more"
                    />
                  ) : (
                    <NoData />
                  )
                }
              >
                {fakeItems
                  .filter((value) => {
                    const fullname = value.title + value.host;
                    return (
                      fullname
                        .toLocaleLowerCase()
                        .indexOf(searchedText.toLocaleLowerCase()) !== -1
                    );
                  })
                  .filter((value) => {
                    return value.type.indexOf(activeButton) !== -1;
                  })
                  .map((value, index) => {
                    return (
                      <LiveCard
                        key={index}
                        _id={value._id}
                        live_date={value.live_date}
                        link={value.link}
                        note={value.note}
                        title={value.title}
                        host={value.host}
                        type={value.type}
                      />
                    );
                  })}
              </InfiniteScroll>
            </PerfectScrollbar>
          )}
        </div>
      ) : (
        <>
          <Grow in={!isUserFullAccessAutorized} mountOnEnter unmountOnExit>
            <div className="ct-live">
              <UserRestrictionDisplayer />
            </div>
          </Grow>
        </>
      )}
      {false && <ModalUserRestrictionWhenPaid />}
    </>
  );
};

export default CtLive;
