import ListManager from "@/components/ListManager/ListManager";
import {
  selectMemberCurrentExchange,
  selectMemberProfile,
} from "@/store/reducers/member/member.selector";
import { Toastify } from "@/utils/toast";
import { Grow, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";

import {
  apiCancelDemand,
  apiEndUpExchange,
  apiGetAllPendingExchange,
  apiGetAllClosedExchange,
  apiRejectDemand,
  apiAcceptDemand,
} from "../../api/exchange.api";
import { TProfileCard } from "../../Elements/ProfileCard/ProfileCard";
import {
  initCurrentExchange,
  TCurrentExchange,
  TMemberCurrentExchange,
} from "../../types";
import EchangeInfos from "./components/Echange-Infos/EchangeInfos";
import DetailEchange from "./Detail-echange/DetailEchange";
import "./Exchange.styles.scss";
import { store } from "@/store";
import { selectOpenDiscussion } from "@/store/reducers/general_param/general_param.selector";
import {
  updateCurrentExchange,
  updateOpenDiscussion,
} from "@/store/reducers/general_param/general_param.actions";
import * as _ from "lodash";
import {
  updateAcceptExchange,
  updateExchangeNotif,
  updateMemberCurrentExchange,
  updatePendingCancelConversation,
} from "@/store/reducers/member/member.actions";
import { AppSocketContext } from "@/routes";
import { socketChannels } from "@/config/socketChannels";
import useUserRestrictions from "@/hooks/useUserRestriction";
import UserRestrictionDisplayer from "@/components/UserRestrictionDisplayer/UserRestrictionDisplayer";
import ModalUserRestrictionWhenPaid from "@/components/Modal-user-restriction-when-paid/ModalUserRestrictionWhenPaid";
import { TProfileList } from "../Home-content/Home.content";
import { calculerAge } from "@/utils/calculerAge";
import { TMemberProfile } from "@/features/auth/types";

export const ExchangeContext = createContext<{
  acceptExchangeFct: (conversationId: string, username: string) => void;
  loadingAccept: boolean;
  rejectDemandFct: (
    conversationId: string,
    username: string,
    motif: string
  ) => void;
  loadingReject: boolean;
  cancelDemandFct: (conversationId: string, username: string) => void;
  loadingCancel: boolean;
  endUpExchangeFct: (
    conversationId: string,
    motif: string,
    username: string
  ) => void;
  loadingEndup: boolean;
}>({
  acceptExchangeFct: () => {},
  loadingAccept: false,
  rejectDemandFct: () => {},
  loadingReject: false,
  cancelDemandFct: () => {},
  loadingCancel: false,
  endUpExchangeFct: () => {},
  loadingEndup: false,
});

const CtExchange = () => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;
  const openDiscussion = useSelector(selectOpenDiscussion);
  const memberCurrentExchange = useSelector(
    selectMemberCurrentExchange
  ) as TMemberCurrentExchange;

  console.log(memberCurrentExchange);

  const { isUserFullAccessAutorized } = useUserRestrictions();

  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingPending, setLoadingPending] = useState(false);
  const [loadingClosed, setLoadingClosed] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingEndup, setLoadingEndup] = useState(false);
  const [demandList, setDemandList] = useState<TProfileCard[]>([]);
  const [closedDemandList, setClosedDemandList] = useState<TProfileCard[]>([]);
  const [navState, setNavState] = React.useState<"pending" | "done">("pending");

  const { socketIO } = useContext(AppSocketContext);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newNavState: "pending" | "done"
  ) => {
    console.log(newNavState);

    setNavState(newNavState);
    if (newNavState === "pending") {
      handleLoadPendingDemand();
    } else if (newNavState === "done") {
      handleLoadClosedDemand();
    }
  };

  const isEmitter = useCallback(
    (gender: "MALE" | "FEMALE"): boolean => {
      return (
        gender.toLocaleLowerCase() ===
        memberProfileFromStore?.infos?.identity?.gender.toLocaleLowerCase()
      );
    },
    [memberProfileFromStore?.infos?.identity?.gender]
  );

  const handleLoadPendingDemand = useCallback(() => {
    if (isUserFullAccessAutorized) {
      setLoadingPending(true);
      apiGetAllPendingExchange()
        .then((response: any) => {
          setLoadingPending(false);
          console.log(response);

          let tempList: TProfileList;
          tempList = response.data.result.map((data: any) => {
            return {
              _id: data._id,
              conversationId: data.conversationId,
              isVerified: data.status === "VALIDATED",
              username: data.username,
              type: "",
              progressValue: Math.round(data.rateOfCompletion),
              profil_id: data.memberId,
              isOnline: data?.isOnline,
              age: calculerAge(new Date(data.birthDate)),
              town: data.city,
              country: data.country,
              origine: data.homeCountry,
              nationalite: data.citizenship,
              hasMore: false,
              sendStatut: isEmitter(data.opener) ? "sent" : "received",
              description: data.description,
              profile_wanted: data.profilewanted,
              date_of_creation: new Date(),
              last_connection: new Date(),
              closure: data.closure,
              currentConversation: data.currentConversation,
              is_banished: false,
            };
          });
          setDemandList([...tempList]);
          console.log(tempList);
        })
        .catch((error) => {
          setLoadingPending(false);
          console.log(error);
          error?.response?.data?.message &&
            Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });
    }
  }, [isEmitter, isUserFullAccessAutorized]);

  const handleLoadClosedDemand = useCallback(() => {
    if (isUserFullAccessAutorized) {
      setLoadingClosed(true);
      apiGetAllClosedExchange()
        .then((response: any) => {
          setLoadingClosed(false);
          console.log(response);

          let tempList: TProfileList;
          tempList = response.data.result.map((data: any) => {
            return {
              _id: data._id,
              conversationId: data.conversationId,
              isVerified: data.status === "VALIDATED",
              username: data.username,
              type: "",
              progressValue: Math.round(data.rateOfCompletion),
              profil_id: data.memberId,
              isOnline: data?.isOnline,
              age: calculerAge(new Date(data.birthDate)),
              town: data.city,
              country: data.country,
              origine: data.homeCountry,
              nationalite: data.citizenship,
              hasMore: false,
              sendStatut: isEmitter(data.opener) ? "sent" : "received",
              description: data.description,
              profile_wanted: data.profilewanted,
              date_of_creation: new Date(),
              last_connection: new Date(),
              closure: data.closure,
              currentConversation: data.currentConversation,
              is_banished: false,
            };
          });
          setClosedDemandList([...tempList]);
          console.log(tempList);
        })
        .catch((error) => {
          setLoadingClosed(false);
          console.log(error);
          error?.response?.data?.message &&
            Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });
    }
  }, [isEmitter, isUserFullAccessAutorized]);

  useEffect(() => {
    store.dispatch(updateExchangeNotif(0)); // <---
    handleLoadPendingDemand();
    handleLoadClosedDemand();

    return () => {
      store.dispatch(updateExchangeNotif(0));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserFullAccessAutorized]);

  useEffect(() => {
    if (memberCurrentExchange === null) {
      store.dispatch(updateOpenDiscussion(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCurrentExchange]);

  useEffect(() => {
    //console.log('||| ⭐️',socketIO);

    if (socketIO) {
      socketIO.on(socketChannels.exchange_demand, (data) => {
        console.log("⭐️ Exchange demand ", data);

        const newExchangeDemand: any = {
          _id: data._id,
          conversationId: data.conversationId,
          isVerified: data.status === "VALIDATED",
          username: data.username,
          type: "",
          progressValue: Math.round(data.rateOfCompletion),
          profil_id: data.memberId,
          isOnline: data?.isOnline,
          age: calculerAge(new Date(data.birthDate)),
          town: data.city,
          country: data.country,
          origine: data.homeCountry,
          nationalite: data.citizenship,
          hasMore: false,
          sendStatut: isEmitter(data.opener) ? "sent" : "received",
          description: data.description,
          profile_wanted: data.profilewanted,
          date_of_creation: new Date(),
          last_connection: new Date(),
          closure: data.closure,
          currentConversation: data.currentConversation,
          is_banished: false,
        };
        setDemandList([{ ...newExchangeDemand }, ...demandList]);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketIO, demandList]);

  const acceptExchangeFct = (conversationId: string, username: string) => {
    setLoadingAccept(true);

    apiAcceptDemand(conversationId || "")
      .then((response: any) => {
        setLoadingAccept(false);
        console.log(response);
        Toastify(
          ` Vous pouvez désormais échanger avec ${username} `,
          "success",
          5000
        );

        const tempCurrentExchange: TMemberCurrentExchange = response.data;
        store.dispatch(updateMemberCurrentExchange(tempCurrentExchange));

        let tempArr = demandList;
        let removedItem = _.remove(tempArr, (x) => {
          return x.conversationId === conversationId;
        });
        console.log(removedItem);
        setDemandList([...tempArr]);

        store.dispatch(updateAcceptExchange(true));
        store.dispatch(updateOpenDiscussion(true));
      })
      .catch((error) => {
        setLoadingAccept(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        console.log("🟦", error);
      });
  };
  const rejectDemandFct = (
    conversationId: string,
    username: string,
    motif: string
  ) => {
    setLoadingReject(true);

    apiRejectDemand(conversationId, motif)
      .then((response: any) => {
        setLoadingReject(false);
        console.log(response);

        let tempArr = demandList;
        let removedItem = _.remove(tempArr, (x) => {
          return x.conversationId === conversationId;
        });
        console.log(removedItem);
        setDemandList([...tempArr]);

        Toastify(
          ` La demande d'échange de ${username} a bien été refusée `,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoadingReject(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        console.log("🟦", error);
      });
  };

  const cancelDemandFct = (conversationId: string, username: string) => {
    setLoadingCancel(true);

    apiCancelDemand(conversationId)
      .then((response: any) => {
        setLoadingCancel(false);
        console.log(response);

        let tempArr = demandList;
        let removedItem = _.remove(tempArr, (x) => {
          return x.conversationId === conversationId;
        });
        console.log(removedItem);
        setDemandList([...tempArr]);

        Toastify(
          ` La demande d'échange de ${username} a bien été annulée `,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoadingCancel(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        console.log("🟦", error);
      });
  };

  const endUpExchangeFct = (id: string, motif: string, username: string) => {
    setLoadingEndup(true);

    apiEndUpExchange(id, motif)
      .then((response: any) => {
        setLoadingEndup(false);
        console.log(response);

        Toastify(
          ` La clôture d'échange avec ${username} a bien été envoyé à nos équipes de modération `,
          "success",
          5000
        );

        store.dispatch(updateMemberCurrentExchange(null));
        store.dispatch(updateOpenDiscussion(false));
        store.dispatch(updatePendingCancelConversation(true));
      })
      .catch((error) => {
        setLoadingEndup(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        console.log("🟦", error);
      });
  };

  return (
    <ExchangeContext.Provider
      value={{
        acceptExchangeFct: acceptExchangeFct,
        loadingAccept: loadingAccept,
        rejectDemandFct: rejectDemandFct,
        loadingReject: loadingReject,
        cancelDemandFct: cancelDemandFct,
        loadingCancel: loadingCancel,
        endUpExchangeFct: endUpExchangeFct,
        loadingEndup: loadingEndup,
      }}
    >
      <>
        {isUserFullAccessAutorized ? (
          <div className="ct-exchange">
            {openDiscussion ? (
              <DetailEchange memberCurrentExchange={memberCurrentExchange} />
            ) : (
              <div className="ct-exchange__recap-container">
                <div className="exchange-info-container">
                  <EchangeInfos memberCurrentExchange={memberCurrentExchange} />
                </div>
                <div className="recap-list-container">
                  <div className="nav-part">
                    <ToggleButtonGroup
                      color="primary"
                      className="yel-toggle-group"
                      value={navState}
                      exclusive
                      // onChange={handleChange}
                      aria-label="Platform"
                    >
                      <ToggleButton
                        value="pending"
                        onClick={(e) => {
                          handleChange(e, "pending");
                        }}
                      >
                        {" "}
                        Demande(s) en cours ({demandList.length}){" "}
                      </ToggleButton>
                      <ToggleButton
                        value="done"
                        onClick={(e) => {
                          handleChange(e, "done");
                        }}
                      >
                        Échange(s) clôturé(s) ({closedDemandList.length}){" "}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <div className="list-manager-exchange-container">
                    {navState === "pending" && (
                      <ListManager
                        profileList={demandList}
                        loading={loadingPending}
                        isForExchange={true}
                        isInProgress={true}
                        isForCloture={false}
                        alt={false}
                      />
                    )}
                    {navState === "done" && (
                      <ListManager
                        profileList={closedDemandList}
                        loading={loadingClosed}
                        isForExchange={true}
                        isForCloture={true}
                        alt={false}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <Grow in={!isUserFullAccessAutorized} mountOnEnter unmountOnExit>
              <div className="ct-exchange">
                <UserRestrictionDisplayer />
              </div>
            </Grow>
          </>
        )}
        {false && <ModalUserRestrictionWhenPaid />}
      </>
    </ExchangeContext.Provider>
  );
};

export default CtExchange;
