import React, { useContext, useEffect } from "react";
import {
  YouTube,
  Send,
  FacebookRounded,
  Twitter,
  Instagram,
} from "@mui/icons-material";
import logo from "@/assets/images/png/logo.png";
import logoMini from "@/assets/images/png/logo-mini.png";
import under from "@/assets/images/png/UnderContruction.png";
import "./landing.styles.scss";
import { AppSocketContext } from "@/routes";
import { socketChannels } from "@/config/socketChannels";
import { TInterfaceSettings } from "@/features/profile/types";
import { store } from "@/store";
import {
  updateMaxMessage,
  updateMaxCaracter,
  updateMailMale,
  updateMailFemale,
  updateGeneralLinks,
  updateIsModerationSuspend,
  updateMessageClosedModeration,
  updateIsWebsiteMaintenance,
} from "@/store/reducers/general_param/general_param.actions";
import { initializeParam } from "@/features/profile/initialize_param/initialize_param";

const Landing = () => {
  const { socketIO } = useContext(AppSocketContext);

  useEffect(() => {
    if (socketIO) {
      socketIO.on(socketChannels.interface_setting_channel, (data) => {
        console.log("||| 🌕 interface", data);
        const interfaceSetting: TInterfaceSettings = data as TInterfaceSettings;

        store.dispatch(
          updateMaxMessage(
            interfaceSetting.moderation.numberMessagePerDiscussion
          )
        );

        store.dispatch(
          updateMaxCaracter(
            interfaceSetting.moderation.numberCaractersPerMessage
          )
        );

        store.dispatch(updateMailMale(interfaceSetting.menGroupHeader.email));

        store.dispatch(
          updateMailFemale(interfaceSetting.womenGroupHeader.email)
        );

        store.dispatch(
          updateGeneralLinks({
            youtube: interfaceSetting.socialMedia.youtube,
            telegram: interfaceSetting.socialMedia.telegram,
            facebook: interfaceSetting.socialMedia.facebook,
            twitter: interfaceSetting.socialMedia.twitter,
            instagram: interfaceSetting.socialMedia.instagram,
          })
        );

        store.dispatch(
          updateIsModerationSuspend(
            interfaceSetting.maintenance.suspendModeration
          )
        );

        store.dispatch(
          updateIsWebsiteMaintenance(interfaceSetting.maintenance.website)
        );

        store.dispatch(
          updateMessageClosedModeration(
            interfaceSetting?.moderation?.schedule || ""
          )
        );
      });
    }
  }, [socketIO]);

  useEffect(() => {
    initializeParam();
  }, []);

  return (
    <div className="langing-container">
      <div className="top">
        <img src={logo} alt="" className="logo" />
      </div>
      <div className="big-title">
        <p>
          {" "}
          Nous effectuons actuellement 
          <span className="color-text">
            des mises à jour sur l'application{" "}
          </span>
          .{" "}
        </p>
      </div>
      <div className="middle">
        <img src={logoMini} alt="" className="logo-mini" />
        <p> ... Un peu de patience ...</p>
        <img src={under} alt="" className="under" />
        <p>
          Pour toute(s) autre(s) question(s) contactez-nous par e-mail :{" "}
          <b>
            <a href="mailto:zawaj.sounnah@gmail.com">zawaj.sounnah@gmail.com</a>
          </b>
        </p>
      </div>
      <div className="bottom">
        <div className="copyright">
          <p>
            © 2024 
            <a href="https://zawaj-sounnah.com/">Zawaj Sounnah</a>.<br />
            <small>Une cause pour le mariage</small>
          </p>
        </div>
        <div className="social">
          <a
            className="rounded-square"
            href="https://www.youtube.com/@zawajsounnah2059"
            target="_blank"
            rel="noreferrer"
          >
            <YouTube color="primary" />
          </a>
          <a
            className="rounded-square"
            href="https://t.me/zawajsounnah"
            target="_blank"
            rel="noreferrer"
          >
            <Send color="primary" />
          </a>
          <a
            className="rounded-square"
            href="https://www.facebook.com/zawajsounnah/"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookRounded color="primary" />
          </a>
          <a
            className="rounded-square"
            href="https://twitter.com/ZawajSounnah?t=E5cbH2yqGe6ws_X_ZIOVxQ&s=09"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter color="primary" />
          </a>
          <a
            className="rounded-square"
            href="https://www.instagram.com/zawaj.sounnah/?hl=fr"
            target="_blank"
            rel="noreferrer"
          >
            <Instagram color="primary" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Landing;
