import { routePaths } from "@/config";
import { Navigate, Route, Routes } from "react-router-dom";
import LProfile from "../layouts/Profile.layout";
import Blacklist from "../pages/Blacklist.page";
import Engagement from "../pages/Engagement.page";
import Exchange from "../pages/Exchange.page";
import Favorite from "../pages/Favorite.page";
import Fonctionnement from "../pages/Fonctionnement.page";
import Home from "../pages/Home.page";
import ImamSpeakers from "../pages/ImamSpeakers.page";
import Live from "../pages/Live.page";
import { MyAccountRoutes } from "../pages/MyAccount-content/routes/myAccount.routes";
import Notification from "../pages/Notification.page";
import Questions from "../pages/Questions.page";
import Search from "../pages/Search.page";
import Staff from "../pages/Staff.page";
import { SubscriptionRoutes } from "../pages/Subscription-content /routes/subscription.routes";

export const ProfileRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<LProfile />}>
        <Route
          index
          element={
            <Navigate to={`${routePaths.profileChildren.homeAbsolute}`} />
          }
        />
        <Route path={routePaths.profileChildren.home} element={<Home />} />
        <Route
          path={routePaths.profileChildren.favorite}
          element={<Favorite />}
        />
        <Route
          path={routePaths.profileChildren.exchange}
          element={<Exchange />}
        />
        <Route path={routePaths.profileChildren.search} element={<Search />} />
        <Route
          path={routePaths.profileChildren.myAccount + "/*"}
          element={<MyAccountRoutes />}
        />
        <Route
          path={routePaths.profileChildren.notification}
          element={<Notification />}
        />
        <Route
          path={routePaths.profileChildren.fonctionnement}
          element={<Fonctionnement />}
        />
        <Route
          path={routePaths.profileChildren.engagement}
          element={<Engagement />}
        />
        <Route
          path={routePaths.profileChildren.questions}
          element={<Questions />}
        />
        <Route path={routePaths.profileChildren.staff} element={<Staff />} />
        <Route
          path={routePaths.profileChildren.imamSpeakers}
          element={<ImamSpeakers />}
        />
        <Route
          path={routePaths.profileChildren.blacklist}
          element={<Blacklist />}
        />
        <Route path={routePaths.profileChildren.live} element={<Live />} />
        <Route
          path={routePaths.profileChildren.subscription + "/*"}
          element={<SubscriptionRoutes />}
        />
        <Route
          path="/*"
          element={
            <Navigate to={`${routePaths.profileChildren.homeAbsolute}`} />
          }
        />
      </Route>
    </Routes>
  );
};
