import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import { IconButton } from "@mui/material";
import { HighlightOffRounded } from "@mui/icons-material";
import "./ModalBanned.styles.scss";

interface CModalBannedProps {
  open: boolean;
  close: () => void;
  reason: string;
}
const CModalBanned = ({ open, close, reason }: CModalBannedProps) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={close}
    >
      <Grow in={open}>
        <div className="c-modal-banned ">
          <IconButton
            className="close-icon"
            size="large"
            color="inherit"
            onClick={close}
          >
            <HighlightOffRounded />
          </IconButton>
          <img
            src={require("@/assets/images/png/logo-mini.png")}
            alt="sidebar-logo"
            className="modal-avatar"
          />

          {
            <>
              <h3>
                <b> ACCÈS REFUSÉES </b>
              </h3>
              <br />

              <p>
                Vous avez été <b> banni(e)</b> de la plateforme par notre équipe
                de modération
                {reason && (
                  <>
                    {" "}
                    pour la raison suivante :<br /> <b>{reason}</b>
                  </>
                )}
                .
              </p>
            </>
          }
        </div>
      </Grow>
    </Modal>
  );
};

export default CModalBanned;
