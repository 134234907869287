import React, { useContext, useEffect } from "react";

import { useSelector } from "react-redux";

import "./EchangeHeader.styles.scss";
import { TMemberCurrentExchange } from "@/features/profile/types";
import { InlineIcon } from "@iconify/react";
import classnames from "classnames";
import {
  selectMaxMessage,
  selectNbreMessageReceive,
  selectNbreMessageSent,
} from "@/store/reducers/general_param/general_param.selector";
import useExchangeRole from "@/hooks/useExchangeRole";
import { calculerAge } from "@/utils/calculerAge";
import * as moment from "moment";
import "moment/locale/fr";
import { useMediaQuery, useTheme } from "@mui/material";
import { AppSocketContext } from "@/routes";
import { socketChannels } from "@/config/socketChannels";
import { store } from "@/store";
import {
  updateMaxMessage,
  updateNbreMessageReceive,
  updateNbreMessageSent,
} from "@/store/reducers/general_param/general_param.actions";

interface EchangeHeaderProps {
  memberCurrentExchange: TMemberCurrentExchange;
}
const EchangeHeader = ({ memberCurrentExchange }: EchangeHeaderProps) => {
  const { sender, receiver } = useExchangeRole();
  const max_message = useSelector(selectMaxMessage);
  const nbre_message_sent = useSelector(selectNbreMessageSent);
  const nbre_message_receive = useSelector(selectNbreMessageReceive);
  const theme = useTheme();
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { socketIO } = useContext(AppSocketContext);

  useEffect(() => {
    //console.log('||| ⭐️',socketIO);

    if (socketIO) {
      socketIO.on(socketChannels.nbre_message_available_count, (data) => {
        console.log(data);
        store.dispatch(updateNbreMessageSent(data.sender));
        store.dispatch(updateNbreMessageReceive(data.receiver));
      });
    } else {
      console.log("⭐️ CLOSED : ", socketIO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketIO]);

  return (
    <>
      <div className={classnames("c-echange-header")}>
        <div className="user-part">
          <img
            src={require(
              `@/assets/images/png/avatar-${
                sender?.gender?.toLowerCase() || "male"
              }.png`
            )}
            alt="logo"
          />
          <div className="username-age-head">
            <p className="username" title={sender?.username}>
              {sender?.username}
            </p>
            <p className="age">
              {calculerAge(new Date(sender?.birthDate || ""))}
              ans
            </p>
          </div>

          <div className="tag">
            <InlineIcon icon="akar-icons:location" fontSize={20} />
            <div className="city-country">
              <p className="city" title={sender?.city}>
                {sender?.city}
              </p>
              <p className="country" title={sender?.country}>
                {sender?.country}
              </p>
            </div>
          </div>

          <small>
            {!matches_sm ? "Message restants " : " "}
            <b>
              {max_message - nbre_message_sent < 0
                ? 0
                : max_message - nbre_message_sent}
              /{max_message}
            </b>{" "}
          </small>
        </div>

        <img
          className="double-arrow"
          src={require("@/assets/images/png/double-arrow.png")}
          alt="logo"
        />

        <div className="staff-part">
          <img src={require("@/assets/images/png/logo-mini.png")} alt="logo" />
          {matches_sm ? <></> : <p> Le staff Zawaj Sunnah </p>}

          <p style={{ fontWeight: "bold", fontSize: "small" }}>
            {matches_sm ? (
              <></>
            ) : (
              <>
                En échange depuis <br />
              </>
            )}

            <b style={{ color: "var(--ui-primary)" }}>
              {Math.abs(
                moment
                  .default(memberCurrentExchange?.date)
                  .diff(moment.default(new Date()), "days")
              ) + " jrs"}
            </b>
          </p>
        </div>

        <img
          className="double-arrow"
          src={require("@/assets/images/png/double-arrow.png")}
          alt="logo"
        />

        <div className="user-part">
          <img
            src={require(
              `@/assets/images/png/avatar-${
                receiver?.gender?.toLowerCase() || "male"
              }.png`
            )}
            alt="logo"
          />
          <div className="username-age-head">
            <p className="username" title={receiver?.username}>
              {receiver?.username}
            </p>
            <p className="age">
              {calculerAge(new Date(receiver?.birthDate || ""))}
              ans
            </p>
          </div>

          <div className="tag">
            <InlineIcon icon="akar-icons:location" fontSize={20} />
            <div className="city-country">
              <p className="city" title={receiver?.city}>
                {receiver?.city}
              </p>
              <p className="country" title={receiver?.country}>
                {receiver?.country}
              </p>
            </div>
          </div>

          <small>
            {!matches_sm ? "Message restants " : " "}
            <b>
              {max_message - nbre_message_receive < 0
                ? 0
                : max_message - nbre_message_receive}
              /{max_message}
            </b>{" "}
          </small>
        </div>
      </div>
    </>
  );
};

export default EchangeHeader;
