import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";

export const apiGetFavorite = (
  data?: any
): Promise<AxiosResponse<any, any>> => {
  return axios.get("/profile-s/api/v1/profiles/favorites-fans", data);
};

export const apiGetInterestedInMe = (
  access_token: string
): Promise<AxiosResponse<any, any>> => {
  return axios.get("/test");
};

export const apiAddToFavorite = (
  member_id: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/profile-s/api/v1/profiles/add-favorite", {
    profileID: member_id,
  });
};

export const apiRemoveFromFavorite = (
  member_id: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/profile-s/api/v1/profiles/remove-favorite", {
    profileID: member_id,
  });
};
