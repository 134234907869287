import React from "react";
import Header from "../containers/Header/Header.container";
import CtLive from "./Live-content/Live.content";

const Live: React.FC = () => {
  return (
    <>
      <Header title="GESTION DES LIVES" subtitle="" />
      <CtLive />
    </>
  );
};

export default Live;
