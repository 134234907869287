import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";

export const apiGetAllPendingExchange = (): Promise<
  AxiosResponse<any, any>
> => {
  return axios.get("/chat-s/api/v1/conversations", {
    params: { status: "PENDING" },
  });
};
export const apiGetAllClosedExchange = (): Promise<AxiosResponse<any, any>> => {
  return axios.get("/chat-s/api/v1/conversations", {
    params: { status: "CLOSED" },
  });
};

export const apiAcceptDemand = (
  conversationId: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch(
    "/chat-s/api/v1/conversations/" + conversationId + "/validate",
    {
      validation: "ACCEPTED",
      // rejectedReason: ""
    }
  );
};

export const apiRejectDemand = (
  conversationId: string,
  motif: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch(
    "/chat-s/api/v1/conversations/" + conversationId + "/validate",
    {
      validation: "REJECTED",
      rejectedReason: motif,
    }
  );
};

export const apiCancelDemand = (
  conversationId: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch(
    "/chat-s/api/v1/conversations/" + conversationId + "/cancel"
  );
};

export const apiEndUpExchange = (
  conversationId: string,
  note: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch(
    "/chat-s/api/v1/conversations/" + conversationId + "/close",
    {
      closeReason: note,
    }
  );
};

export const apiRemindDemand = (
  receiverProfileId: string
): Promise<AxiosResponse<any, any>> => {
  return axios.post(
    "/chat-s/api/v1/conversations/" + receiverProfileId + "/relaunch"
  );
};

export const apiSendMessage = (
  message: string,
  conversationId: string
): Promise<AxiosResponse<any, any>> => {
  return axios.post(
    "/chat-s/api/v1/conversations/" + conversationId + "/messages",
    { message }
  );
};

export const apiGetConversation = (
  page: number = 1,
  limit: number = 100,
  conversationId: string
): Promise<AxiosResponse<any, any>> => {
  return axios.get(
    "/chat-s/api/v1/conversations/" +
      conversationId +
      "/messages?page=" +
      page +
      "&limit=" +
      limit
  );
};

export const markMessageAsRead = (
  conversationId: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch(
    "/chat-s/api/v1/conversations/" + conversationId + "/messages/read"
  );
};

export const relaunch = (): Promise<AxiosResponse<any, any>> => {
  return axios.get("/conversations/current/relaunch/");
};
