import * as React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { store } from "../store";
import { theme } from "../theme";
import { SuspenseFallback } from "@/components/SuspenseFallback/SuspenseFallback";
import { muiCustomTheme } from "@/theme/muiCustomTheme";
import { TourProvider } from "@reactour/tour";
import { appTourSteps } from "@/config";
import { updateTourStepIsOpen } from "@/store/reducers/general_param/general_param.actions";

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  return (
    <React.Suspense fallback={<SuspenseFallback />}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={muiCustomTheme}>
            <Provider store={store}>
              <TourProvider
                steps={appTourSteps}
                onClickClose={() => {
                  store.dispatch(updateTourStepIsOpen(false));
                }}
                onClickMask={() => {
                  store.dispatch(updateTourStepIsOpen(false));
                }}
                showBadge={false}
                nextButton={({ Button, currentStep, setCurrentStep }) => (
                  <Button
                    hideArrow
                    children={
                      currentStep === appTourSteps.length - 1
                        ? "C'est compris!"
                        : "Suivant"
                    }
                    onClick={() => {
                      currentStep === appTourSteps.length - 1 &&
                        store.dispatch(updateTourStepIsOpen(false));
                      currentStep < appTourSteps.length - 1 &&
                        setCurrentStep(currentStep + 1);
                    }}
                  />
                )}
                prevButton={({ Button, currentStep, setCurrentStep }) => (
                  <Button
                    hideArrow
                    children={currentStep === 0 ? "" : "Précédent"}
                    onClick={() => {
                      currentStep > 0 && setCurrentStep(currentStep - 1);
                    }}
                  />
                )}
              >
                <Router>{children}</Router>
              </TourProvider>
            </Provider>
          </MuiThemeProvider>
        </ThemeProvider>
      </HelmetProvider>
    </React.Suspense>
  );
};
