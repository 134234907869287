import { routePaths } from "@/config";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./MyAccount.styles.scss";
import useUserRestrictions from "@/hooks/useUserRestriction";
import ModalVerifiedEmail from "@/components/Modal-verified-email/ModalVerifiedEmail";
import CModalRemindPay from "@/components/Modal-remind-pay/ModalRemindPay";

const CtMyAccount = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { isUserEmailVerified, didUserHasToPay, isUserNew } =
    useUserRestrictions();

  useEffect(() => {
    if (isUserNew) {
      navigate(routePaths.profileChildren.myAccountChildren.ficheAbsolute, {
        replace: true,
      }); // à la demande de momo
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserNew]);

  return (
    <>
      <div className="ct-my-account">{isUserEmailVerified && <Outlet />}</div>
      <>
        <ModalVerifiedEmail open={!isUserEmailVerified} />
        <CModalRemindPay
          open={location.state?.from !== "inside" && didUserHasToPay}
        />
      </>
    </>
  );
};

export default CtMyAccount;
