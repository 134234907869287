import { Routes, Route } from "react-router-dom";
import Landing from "../page/landing.page";

export const LandingRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<Landing />} />
    </Routes>
  );
};
