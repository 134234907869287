import { createGlobalStyle, DefaultTheme } from "styled-components";
import convertThemeColorsToRootColors, {
  TThemeColors,
} from "../utils/convertThemeColorsToRootColors";

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

    *, ::after, ::before {
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        padding: 0;
        margin: 0;
    }

    body {
        overflow-x: hidden;
    }

    h1{
        font-family: 'Playfair Display';
        font-weight: normal ;
    }
    h2{
        font-family: 'Playfair Display SC';
        font-weight: "normal";
    }
    h3{
        font-family: 'Lato', sans-serif;
        font-weight: "normal";
    }
    h4{
        font-family: 'Lato', sans-serif;
        font-weight: "normal";
    }
    h5{
        font-family: 'Lato', sans-serif;
        font-weight: "normal";
    }
    div,p,a,span{
        font-family: 'Lato', sans-serif;
        font-weight: normal;
    }
    small{
        font-family: 'Lato', sans-serif;
        font-weight: "normal";
    }
    textarea {
        font-family: 'Lato', sans-serif;
        font-weight: "normal";
        font-size: 1.1rem;
    }



    :root {
        --ui-primary-rgb: 115,16,62;
        --ui-secondary-rgb: 254,248,236;
        --ui-tertiary-rgb: 222,184,107;
        --ui-tertiary-shade-rgb: 191, 156, 87;
        --ui-dark-rgb: 37,37,37;
        --ui-medium-rgb: 54,56,83;
        --ui-light-rgb: 249,249,249;
        --ui-black-rgb: 0,0,0;
        --ui-white-rgb: 255,255,255;
        --ui-success-rgb: 117,216,115;
        --ui-error-rgb: 255, 48, 0;
        --ui-gray-rgb: 135, 144, 161;
        --ui-warning-rgb: 245, 181, 68;
        --ui-disabled-rgb: 206, 206, 206;
        --ui-pink-for-male-shade-rgb: 246, 145, 225;
        --ui-blue-for-female-shade-rgb: 110, 202, 255;

        --title-size: 1.4rem;
        --subtitle-size: 1.1rem;
        --normal-size: 0.9rem;
        --small-size: 0.8rem;
        
        ${({ theme }: { theme: DefaultTheme & { colors: TThemeColors } }) => {
          return convertThemeColorsToRootColors(theme.colors);
        }}
    }

    a {
        text-decoration: none;
    }
`;

export default GlobalStyle;
