import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";
import { TFiche } from "../types";

export const apiSaveFiche = (
  data: TFiche
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/profile-s/api/v1/profiles", data);
};

export const apiSaveProfile = (data: any): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/profile-s/api/v1/profiles", data);
};

export const apiSaveAspectFemale = (
  data: any
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/profile-s/api/v1/profiles", data);
};

export const apiSaveAspectMale = (
  data: any
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/profile-s/api/v1/profiles", data);
};

export const apiSavePratique = (
  data: any
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/profile-s/api/v1/profiles", data);
};

export const apiSaveSante = (data: any): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/profile-s/api/v1/profiles", data);
};

/*
 * OLD API CALL
 */

export const apiSearchProfile = (
  page: number = 1,
  limit: number = 6,
  data: string
): Promise<AxiosResponse<any, any>> => {
  return axios.get(
    "/members/filtered/members?" + data + "&page=" + page + "&limit=" + limit
  );
};

export const apiGetMember = (
  page: number = 1,
  limit: number = 6,
  data: string
): Promise<AxiosResponse<any, any>> => {
  if (data !== "") {
    return axios.get(
      "/profile-s/api/v1/profiles?" +
        "page=" +
        page +
        "&limit=" +
        limit +
        "" +
        data
    );
  } else {
    return axios.get(
      "/profile-s/api/v1/profiles?page=" + page + "&limit=" + limit
    );
  }
};

export const apiGetFullProfil = (
  profile_id: string
): Promise<AxiosResponse<any, any>> => {
  return axios.get("profile-s/api/v1/profiles/" + profile_id);
};

export const apiAskForExchange = (
  member_id: string
): Promise<AxiosResponse<any, any>> => {
  return axios.post("chat-s/api/v1/conversations/" + member_id);
};

export const apiSignalMember = (data: {
  member_id: string;
  note: string;
}): Promise<AxiosResponse<any, any>> => {
  return axios.post("/profile-s/api/v1/reports/create", {
    reported: data.member_id,
    reason: data.note,
  });
};

// Faux commentaire
