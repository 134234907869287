import React, { useCallback, useContext, useEffect, useState } from "react";
import "./Header.styles.scss";
import {
  PowerSettingsNewRounded,
  HelpOutlineRounded,
} from "@mui/icons-material";
import {
  TooltipProps,
  Tooltip,
  tooltipClasses,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toggleSidebar } from "@/store/reducers/sidebar/sidebar.actions";
import { persistor, store, useAppDispatch } from "@/store";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";
import CModal from "@/components/Modal/Modal";
import { InlineIcon } from "@iconify/react";
import { getToken } from "@/store/reducers/member/member.actions";
import {
  selectIsModerationSuspend,
  selectModerationSocket,
  selectNbreLiveSocket,
  selectNbreNotificationSocket,
} from "@/store/reducers/general_param/general_param.selector";
import { ConnectedProps, connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { TModerationSocket } from "../../types";
import CModalInfosZawaj from "@/components/Modal-Infos-Zawaj/ModalInfosZawaj";
import { AppSocketContext } from "@/routes";
import { socketChannels } from "@/config/socketChannels";
import { updateIsModerationSuspend } from "@/store/reducers/general_param/general_param.actions";
import { axios } from "@/lib/axios";
import {
  selectMessageNotif,
  selectFavoriteNotif,
  selectExchangeNotif,
} from "@/store/reducers/member/member.selector";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.secondary}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

type HeaderProps = {
  title: string;
  subtitle: string;
};
const Header = ({ title, subtitle }: HeaderProps) => {
  const nbre_message_notif = useSelector(selectMessageNotif);
  const nbre_favorite_notif = useSelector(selectFavoriteNotif);
  const nbre_exchange_notif = useSelector(selectExchangeNotif);
  const nbre_live_notif = useSelector(selectNbreLiveSocket);
  const nbre_notification = useSelector(selectNbreNotificationSocket);
  const moderation_socket = useSelector(selectModerationSocket);

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const [openInfos, setOpenInfos] = useState(false);
  const handleOpenInfos = () => setOpenInfos(true);
  const handleCloseInfos = () => setOpenInfos(false);

  const [openHelp, setOpenHelp] = useState(false);
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  const [openModeration, setOpenModeration] = useState(false);
  const handleOpenModeration = () => setOpenModeration(true);
  const handleCloseModeration = () => setOpenModeration(false);

  const [openLogout, setOpenLogout] = useState(false);
  const handleClickOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { socketIO, socketRef } = useContext(AppSocketContext);

  const [isModerationOpen, setIsModerationOpen] = useState(false);

  const logout = () => {
    axios
      .post("/auth-s/api/v1/auth/logout")
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        store.dispatch({ type: "CLEAR_STORE" });
        localStorage.clear();
        navigate(routePaths.auth, { replace: true });
        dispatch(getToken(""));
        persistor.purge();

        console.log("✅ socketRef.current avant", socketRef.current, socketIO);
        if (socketRef.current) {
          socketRef.current.disconnect();
          socketRef.current = null;
          console.log(
            "✅ socketRef.current après",
            socketRef.current,
            socketIO
          );
        }

        socketIO?.close();
      });
  };

  const goToHome = () => {
    navigate(routePaths.profileChildren.homeAbsolute, { replace: true });
  };

  const getIsModerationOpen = useCallback(() => {
    axios
      .get("/param-s/api/v1/parameters/app-plannings/is-open")
      .then((response: any) => {
        // store.dispatch(updateIsModerationSuspend(response.data.isOpen));
        setIsModerationOpen(response.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    getIsModerationOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socketIO) {
      socketIO.on(socketChannels.is_moderation_open, (data) => {
        console.log("🌍", data);
        // store.dispatch(updateIsModerationSuspend(data.isOpen));
        setIsModerationOpen(data.isOpen);
      });
    }
  }, [socketIO]);

  const is_moderation_suspend = useSelector(selectIsModerationSuspend);

  const isModerationReallyAvailable = useCallback((): boolean => {
    console.log(isModerationOpen);
    console.log(is_moderation_suspend);

    if (is_moderation_suspend === true) {
      return false;
    } else {
      if (isModerationOpen === true) {
        return true;
      } else {
        return false;
      }
    }
  }, [isModerationOpen, is_moderation_suspend]);

  return (
    <>
      <div className="c-header">
        <div className="mobile-header">
          <StyledBadge
            overlap="circular"
            variant="dot"
            color="primary"
            badgeContent={
              nbre_favorite_notif +
              nbre_live_notif +
              nbre_notification +
              nbre_exchange_notif +
              nbre_message_notif
            }
          >
            <IconButton
              size="large"
              color="primary"
              onClick={() => {
                dispatch(toggleSidebar());
              }}
            >
              <InlineIcon icon="heroicons-outline:menu-alt-2" />
            </IconButton>
          </StyledBadge>
          <img
            style={{ display: "none" }}
            src={require("@/assets/images/png/logo-mini.png")}
            alt="logo-mini"
            className="logo-mini"
            onClick={goToHome}
          />
          <div className="title-part">
            <h2 className="title">{title}</h2>
            <p className="subtitle">{subtitle}</p>
          </div>
          <IconButton color="info" size="large" onClick={handleOpenInfos}>
            <InlineIcon icon="entypo:info-with-circle" />
          </IconButton>
          <Button
            className="btn-moderation"
            color={isModerationOpen ? "success" : "inherit"}
            onClick={handleOpenModeration}
            variant="contained"
          >
            <InlineIcon
              icon={
                isModerationOpen ? "fa-solid:door-open" : "fa-solid:door-closed"
              }
            />
          </Button>
        </div>

        <StyledBadge
          overlap="circular"
          variant="dot"
          color="primary"
          className="rounded-square-btn "
          badgeContent={
            nbre_favorite_notif +
            nbre_live_notif +
            nbre_notification +
            nbre_exchange_notif +
            nbre_message_notif
          }
        >
          <IconButton
            size="large"
            color="primary"
            onClick={() => {
              dispatch(toggleSidebar());
            }}
          >
            <InlineIcon icon="heroicons-outline:menu-alt-2" />
          </IconButton>
        </StyledBadge>
        <img
          style={{ display: "none" }}
          src={require("@/assets/images/png/logo-mini.png")}
          alt="logo-mini"
          className="logo-mini-md"
          onClick={goToHome}
        />
        <div className="title-part">
          <h2 className="title">{title}</h2>
          <p className="subtitle">{subtitle}</p>
        </div>

        <div className="btn-part">
          <Button
            className="btn-moderation"
            color={isModerationReallyAvailable() ? "success" : "inherit"}
            onClick={handleOpenModeration}
            variant="contained"
          >
            Modération {isModerationReallyAvailable() ? "ouverte" : "fermée"}
          </Button>
        </div>

        <CustomTooltip title="À lire absolument">
          <IconButton
            color="info"
            size="medium"
            className="icon-header"
            onClick={handleOpenInfos}
          >
            <InlineIcon icon="entypo:info-with-circle" />
          </IconButton>
        </CustomTooltip>

        <CustomTooltip title="Besoin d'aide">
          <IconButton className="icon-header" onClick={handleOpenHelp}>
            <HelpOutlineRounded color="primary" />
          </IconButton>
        </CustomTooltip>

        <CustomTooltip title="Se déconnecter">
          <IconButton className="icon-header" onClick={handleClickOpenLogout}>
            <PowerSettingsNewRounded color="primary" />
          </IconButton>
        </CustomTooltip>
      </div>

      <CModalInfosZawaj open={openInfos} closeModal={handleCloseInfos} />
      <CModal
        open={openHelp}
        type="aide"
        onClose={handleCloseHelp}
        closeClick={handleCloseHelp}
      />
      <CModal
        open={openModeration}
        type="moderation"
        onClose={handleCloseModeration}
        closeClick={handleCloseModeration}
        moderation_socket={moderation_socket}
        is_moderation_suspend={!isModerationReallyAvailable()}
      />
      <Dialog
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Déconnexion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de vous déconnecter. Êtes-vous sûr(e) de
            vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogout}>Annuler</Button>
          <Button onClick={logout} autoFocus>
            Déconnecter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Header;
