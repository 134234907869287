import React, { useState } from "react";
import { routePaths } from "@/config";
import { Toastify } from "@/utils/toast";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { apiLoginMember } from "../../api/auth.api";
import { useFormik } from "formik";
import { TMemberProfile, TSignIn } from "../../types";
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from "yup";
import "./SigninForm.styles.scss";
import { useAppDispatch } from "@/store";
import {
  getToken,
  getMemberInfos,
  updateLocation,
  updateMemberProfile,
} from "@/store/reducers/member/member.actions";
import { TMemberInfos } from "@/features/profile/types";
import { selectEmail } from "@/store/reducers/temp_data/temp_data.selector";
import { useSelector } from "react-redux";
import CModalBanned from "@/components/Modal-Banned/ModalBanned";
import { jwtDecode } from "jwt-decode";
import { AxiosError } from "axios";

const SigninForm = () => {
  const email_from_redux = useSelector(selectEmail);
  const [isBannished, setIsBannished] = useState(false);
  const [reason, setReason] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const dispatch = useAppDispatch();

  const validationSchema = yup.object({
    identifier: yup
      .string()
      .required(" l'email ou l'indentifiant est obligatoire "),
    password: yup.string().required("le mot de passe est obligatoire"),
    // .min(5, 'Votre mot de passe doit contenir au moins 5 caratères')
  });

  const formik = useFormik({
    initialValues: {
      identifier: email_from_redux || "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      loginSubmit(values);
    },
  });

  const loginSubmit = (data: TSignIn) => {
    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
    setLoading(true);

    data.identifier = data.identifier.trim();

    apiLoginMember(data)
      .then((response: any) => {
        setLoading(false);
        console.log(response);

        const token = response.data.accessToken;
        const profile = response.data.profile as TMemberProfile;
        console.log({ profile });
        const decoded = jwtDecode(token);
        console.log(decoded);

        const infosUser: TMemberInfos = {
          _id: (decoded.sub as any)?._id || "",
          email: (decoded.sub as any)?.email,
          role: (decoded.sub as any)?.role,
          username: (decoded.sub as any)?.username,
          memberId: (decoded.sub as any)?.memberId,
          isVerified: (decoded.sub as any)?.isVerified,
          isInEmailVerificationProcess: (decoded.sub as any)
            ?.isInEmailVerificationProcess,
        };
        console.log({ infosUser });
        dispatch(getToken(token));
        dispatch(getMemberInfos({ ...infosUser }));
        dispatch(updateMemberProfile({ ...profile }));
        navigate(routePaths.profile, { replace: false });

        if (true) {
          navigator.geolocation.getCurrentPosition((position) => {
            var location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            console.log(location);
            dispatch(updateLocation(location));
          });
          navigate(routePaths.profileChildren.myAccountAbsolute, {
            replace: false,
          });
          Toastify(
            `As Salam Aleykoum ${infosUser.username}, tu es maintenant connecté(e).`,
            "success",
            5000
          );
        }
      })
      .catch((error: any) => {
        setLoading(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        console.log(error);
        if (error.response.status === 403) {
          setIsBannished(true);
          setReason(error.response.data.message);
        }
      });
  };

  return (
    <>
      <div className="signin-container">
        <div className="header-part">
          <h1 className="title">Se Connecter</h1>
          <p className="subtitle"> ACCÉDER À L'ESPACE MEMBRE </p>
        </div>
        <form className="form-part" onSubmit={formik.handleSubmit}>
          <TextField
            placeholder="Votre adresse email "
            variant="outlined"
            label="Email "
            name="identifier"
            onChange={formik.handleChange}
            value={formik.values.identifier}
            error={
              formik.touched.identifier && Boolean(formik.errors.identifier)
            }
            helperText={formik.touched.identifier && formik.errors.identifier}
            type="text"
          />
          <TextField
            placeholder="Votre mot de passe"
            variant="outlined"
            label="Mot de passe"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ marginTop: "15px" }}
          />
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            variant="contained"
            color="tertiary"
            sx={{ marginTop: "30px" }}
            type="submit"
          >
            SE CONNECTER
          </LoadingButton>
        </form>
        <div className="footer-part">
          <Link
            className="link-primary"
            to={routePaths.authChildren.forgottenPasswordAbsolute}
          >
            {" "}
            Mot de passe oublié
          </Link>
          <p>
            {" "}
            Vous n'avez pas de compte ?
            <Link
              className="link-primary"
              to={routePaths.authChildren.signUpAbsolute}
            >
              {" "}
              S'inscrire{" "}
            </Link>
          </p>
          <a
            href="https://zawaj-sounnah.com/"
            target="_blank"
            rel="noreferrer"
            className="link-gray"
          >
            Aller sur zawaj-sounnah.com
          </a>
        </div>
      </div>
      {isBannished && (
        <CModalBanned
          open={isBannished}
          close={() => setIsBannished(false)}
          reason={reason}
        />
      )}
    </>
  );
};

export default SigninForm;
