import React from "react";
import VerifEmailForm from "../forms/VerifEmailForm/VerifEmailForm";

const VerifEmail: React.FC = () => {
  return (
    <>
      <VerifEmailForm />
    </>
  );
};

export default VerifEmail;
