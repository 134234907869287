import { Navigate, RouteObject } from "react-router-dom";
import { LandingRoutes } from "@/features/landing";

export const maintenanceRoutes: RouteObject[] = [
  {
    path: "",
    element: <LandingRoutes />,
  },
  { path: "*", element: <Navigate to="" /> },
];
