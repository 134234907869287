import { routePaths } from "@/config";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { TSignUp } from "../../types";
import { useFormik } from "formik";
import * as yup from "yup";
import "./SignupForm.styles.scss";
import { useAppDispatch } from "@/store";
import { updateSignUp } from "@/store/reducers/temp_data/temp_data.actions";
import { apiCheckSignup } from "../../api/auth.api";
import { Toastify } from "@/utils/toast";
import { MuiTelInput } from "mui-tel-input";
import { LoadingButton } from "@mui/lab";
import { selectSignUp } from "@/store/reducers/temp_data/temp_data.selector";
import { useSelector } from "react-redux";

const SignupForm: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const sign_up = useSelector(selectSignUp);

  const [errorsList, setErrorsList] = useState<{ [key: string]: string }[]>([]);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const dispatch = useAppDispatch();

  const [phoneValue, setPhoneValue] = useState(sign_up?.phoneNumber || "+33");

  const handleChangePhoneValue = (newValue: string) => {
    setPhoneValue(newValue);
    formik.setFieldValue("phone_number", newValue);
  };

  const validationSchema = yup.object({
    firstname: yup.string().required(" le prénom est obligatoire "),
    lastname: yup.string().required(" le nom est obligatoire "),
    username: yup
      .string()
      .test({
        name: "not-email",
        message: "le nom d'utilisateur n'est pas un email",
        test: function (value) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return !emailRegex.test(value as string);
        },
      })
      .required(" le nom d'utilisateur est obligatoire "),
    email: yup
      .string()
      .email("Entrez une adresse mail valide")
      .required(" l'email est obligatoire "),
    phone_number: yup
      .string()
      .min(7, "le numéro de téléphone est obligatoire")
      .required(" le numéro de téléphone est obligatoire "),
    password: yup
      .string()
      .min(5, "Votre mot de passe doit contenir au moins 5 caratères")
      .required("le mot de passe est obligatoire"),
    confirmed_password: yup
      .string()
      .required("la confirmation du mot de passe est obligatoire")
      .test({
        name: "passwords_match",
        message: "Les mots de passes ne correspondent pas",
        test: function (value, ctx) {
          const { password } = ctx.parent;
          if (value !== password) {
            return false;
          }
          return true;
        },
      }),
    gender: yup.string().required(" le genre est obligatoire "),
    // age: yup
    //   .number()
    //   .required(" l'âge est obligatoire ")
    //   .min(18, " l'âge minimum est de 18 ans")
    //   .max(90, " l'âge maximum est de 90 ans"),
    birthday_date: yup
      .date()
      .required(" l'âge est obligatoire ")
      .min(
        new Date(new Date().getFullYear() - 90, 0, 1),
        "Vous devez avoir moins de 90 ans"
      )
      .max(
        new Date(new Date().getFullYear() - 18, 11, 31),
        "Vous devez avoir plus de 18 ans"
      ),
  });

  const formik = useFormik({
    initialValues: {
      firstname: sign_up?.firstname || "",
      lastname: sign_up?.lastname || "",
      username: sign_up?.username || "",
      gender: sign_up?.gender || "",
      // age: "",
      birthday_date: sign_up?.birthDate || "",
      email: sign_up?.email || "",
      password: sign_up?.password || "",
      confirmed_password: sign_up?.password || "",
      phone_number: sign_up?.phoneNumber || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //navigate( routePaths.authChildren.termsAndConditionAbsolute ,{replace: false});
      let dataToSend = {
        firstname: values.firstname,
        lastname: values.lastname,
        username: values.username,
        gender: values.gender,
        // age: values.age,
        birthDate: values.birthday_date,
        email: values.email,
        password: values.password,
        phoneNumber: values.phone_number.trim().replace(/\s/g, ""),
      };
      checkSignup(dataToSend as TSignUp);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkSignup = (data: TSignUp) => {
    setLoading(true);
    const dataToVerify = {
      email: data.email,
      username: data.username,
      phoneNumber: encodeURIComponent(data.phoneNumber),
    };
    apiCheckSignup(dataToVerify)
      .then((response: any) => {
        console.log(response);
        dispatch(updateSignUp(data));
        navigate(routePaths.authChildren.termsAndConditionAbsolute, {
          replace: false,
        });
      })
      .catch((error: any) => {
        console.log({ error });
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        const errorsList = error?.response?.data?.errors;
        setErrorsList([...errorsList]);
      })
      .finally(() => {
        setLoading(false);

        // à enlever après 👇🏾👇🏾👇🏾👇🏾👇🏾👇🏾
        dispatch(updateSignUp(data));
        // navigate(routePaths.authChildren.termsAndConditionAbsolute, {
        //   replace: false,
        // });
        // à enlever après 👆🏾👆🏾👆🏾👆🏾👆🏾👆🏾
      });
  };

  const handleErrorsList = useCallback(
    (propertyName: string): string => {
      const property = errorsList.find(
        (error) => Object.keys(error)[0] === propertyName
      );
      if (!!property) return property[propertyName];
      else return "";
    },
    [errorsList]
  );

  return (
    <div className="signup-container">
      <div className="header-part">
        <h1 className="title">Page d'inscription</h1>
      </div>
      <form className="form-part" onSubmit={formik.handleSubmit}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Votre prénom"
                variant="outlined"
                label="Prénom"
                name="firstname"
                onChange={formik.handleChange}
                value={formik.values.firstname}
                error={
                  formik.touched.firstname && Boolean(formik.errors.firstname)
                }
                helperText={formik.touched.firstname && formik.errors.firstname}
                type="text"
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Votre nom"
                variant="outlined"
                label="Nom"
                name="lastname"
                onChange={formik.handleChange}
                value={formik.values.lastname}
                error={
                  formik.touched.lastname && Boolean(formik.errors.lastname)
                }
                helperText={formik.touched.lastname && formik.errors.lastname}
                type="text"
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Votre nom d'utilisateur"
                variant="outlined"
                label="Nom d'utilisateur"
                name="username"
                onChange={formik.handleChange}
                value={formik.values.username}
                error={
                  (formik.touched.username &&
                    Boolean(formik.errors.username)) ||
                  handleErrorsList("username") !== ""
                }
                helperText={
                  (formik.touched.username && formik.errors.username) ||
                  handleErrorsList("username")
                }
                type="text"
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Votre adresse email"
                variant="outlined"
                label="Email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={
                  (formik.touched.email && Boolean(formik.errors.email)) ||
                  handleErrorsList("email") !== ""
                }
                helperText={
                  (formik.touched.email && formik.errors.email) ||
                  handleErrorsList("email")
                }
                type="email"
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <MuiTelInput
                placeholder="Votre numéro de téléphone (Whatsapp)"
                variant="outlined"
                label="Numéro de téléphone (Whatsapp)"
                name="phone_number"
                onChange={handleChangePhoneValue}
                value={phoneValue}
                error={
                  (formik.touched.phone_number &&
                    Boolean(formik.errors.phone_number)) ||
                  handleErrorsList("phoneNumber") !== ""
                }
                helperText={
                  (formik.touched.phone_number && formik.errors.phone_number) ||
                  handleErrorsList("phoneNumber")
                }
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            {false && (
              <Grid item xs={12} sm={12} lg={6}>
                <TextField
                  placeholder="Votre numéro de téléphone (Whatsapp)"
                  variant="outlined"
                  label="Numéro de téléphone (Whatsapp)"
                  name="phone_number"
                  onChange={formik.handleChange}
                  value={formik.values.phone_number}
                  error={
                    formik.touched.phone_number &&
                    Boolean(formik.errors.phone_number)
                  }
                  helperText={
                    formik.touched.phone_number && formik.errors.phone_number
                  }
                  type="text"
                  sx={{ marginTop: "15px" }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Votre mot de passe"
                variant="outlined"
                label="Mot de passe"
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Confirmer votre mot de passe"
                variant="outlined"
                label="Confirmation de Mot de passe"
                type={showPassword2 ? "text" : "password"}
                name="confirmed_password"
                onChange={formik.handleChange}
                value={formik.values.confirmed_password}
                error={
                  formik.touched.confirmed_password &&
                  Boolean(formik.errors.confirmed_password)
                }
                helperText={
                  formik.touched.confirmed_password &&
                  formik.errors.confirmed_password
                }
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}
                      >
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <FormControl
                fullWidth
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                sx={{ marginTop: "15px" }}
              >
                <InputLabel id="id-sexe">Sexe</InputLabel>
                <Select
                  labelId="id-sexe"
                  label="Sexe"
                  name="gender"
                  onChange={formik.handleChange}
                  value={formik.values.gender}
                >
                  <MenuItem value={"MALE"}>Homme</MenuItem>
                  <MenuItem value={"FEMALE"}>Femme</MenuItem>
                </Select>
                <FormHelperText
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                >
                  {formik.errors.gender}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                placeholder="Votre date de naissance"
                variant="outlined"
                label="Date de naissance"
                name="birthday_date"
                onChange={formik.handleChange}
                value={formik.values.birthday_date}
                error={
                  formik.touched.birthday_date &&
                  Boolean(formik.errors.birthday_date)
                }
                helperText={
                  formik.touched.birthday_date && formik.errors.birthday_date
                }
                type="date"
                sx={{ marginTop: "15px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">  </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              sx={{ display: "none" }}
              item
              xs={12}
              sm={12}
              lg={12}
              justifyContent="center"
            >
              <FormControl
                fullWidth
                color="primary"
                className="paiement-radio-row"
              >
                <FormLabel id="id-formule">Formules</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="id-formule"
                  name="row-radio-buttons-group"
                  className="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="1m"
                    control={<Radio />}
                    label="Abonnement mensuel"
                  />
                  <FormControlLabel
                    value="6m"
                    control={<Radio />}
                    label="Forfait 6 mois"
                  />
                  <FormControlLabel
                    value="1a"
                    control={<Radio />}
                    label="Forfait 1 an"
                  />
                </RadioGroup>
                <FormHelperText className="paiement-radio-helper-text">
                  Vous devez payer &nbsp;<b>10.00 €</b>&nbsp; pour &nbsp;
                  <b>Abonnement Mensuel</b>&nbsp; comme abonnement.
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <LoadingButton
          loading={loading}
          loadingPosition="center"
          variant="contained"
          color="tertiary"
          sx={{ marginTop: "30px" }}
          type="submit"
        >
          {" "}
          S'INSCRIRE
        </LoadingButton>
      </form>
      <div className="footer-part">
        <Link
          className="link-primary"
          to={routePaths.authChildren.signInAbsolute}
        >
          Retourner sur la page de connexion
        </Link>
      </div>
    </div>
  );
};

export default SignupForm;
