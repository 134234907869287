import React from "react";
import Header from "../containers/Header/Header.container";
import CtImamSpeakers from "./ImamSpeakers-content/ImamSpeakers.content";

const ImamSpeakers: React.FC = () => {
  return (
    <>
      <Header title="IMAMS ET INTERVENANTS" subtitle=" " />
      <CtImamSpeakers />
    </>
  );
};

export default ImamSpeakers;
