import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";

export const apiGetLive = (
  page: number = 1,
  limit: number = 6
): Promise<AxiosResponse<any, any>> => {
  return axios.get(
    "/param-s/api/v1/parameters/live-sessions?page=" + page + "&limit=" + limit
  );
};
