import Axios from "axios";
import { API_URL } from "../config";
import { persistor, store } from "@/store";
import { getToken } from "@/store/reducers/member/member.actions";
import { ToastExpired } from "@/utils/toast";

export const axios = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

// Variable to store the token refresh promise
let isRefreshing = false;

// Function to refresh the token
const refreshToken = () => {
  return axios.post("/auth-s/api/v1/auth/p/refresh-token");
};

const onExpired = () => {
  ToastExpired.fire();
  store.dispatch({ type: "CLEAR_STORE" });
  localStorage.clear();
  store.dispatch(getToken(""));
  persistor.purge();
};

let failedQueue: Array<{
  resolve: (token: string) => void;
  reject: (err: any) => void;
}> = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token as string);
    }
  });
  failedQueue = [];
};

axios.interceptors.response.use(
  (response: any) => {
    return response.data;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401 && !originalRequest._retry) {
      const currentURL = window.location.href;

      if (currentURL.indexOf("/auth/sign-in") === -1) {
        const responseURL = error?.request?.responseURL || "";

        if (responseURL.indexOf("/p/refresh-token") === -1) {
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                axios.defaults.headers.Authorization = `Bearer ${token}`;
                return axios(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }
          originalRequest._retry = true;
          isRefreshing = true;
          return new Promise(function (resolve, reject) {
            refreshToken()
              .then((res) => {
                const newToken = res.data;
                store.dispatch(getToken(newToken));
                axios.defaults.headers.Authorization = `Bearer ${newToken}`;
                processQueue(null, newToken);
                resolve(axios(originalRequest));
              })
              .catch((err) => {
                console.log("🌍 Je suis dans le catch du refresh");
                processQueue(err, null);
                onExpired();
                reject(err);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        } else {
          onExpired();
        }
      }
    }

    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config: any) => {
  const token = store.getState().member.token;
  config.headers = {
    Authorization: token ? `Bearer ${token}` : "",
    Source: "WEB",
  };

  if (["patch", "post", "put"].includes(config.method)) {
    config.headers["Content-Type"] = "application/json";
    if (config.data && !(config.data instanceof FormData)) {
      console.log("Payload is FormData:", config.data);
      config.headers["Content-Type"] = "application/json";
    }
  }

  return config;
});
