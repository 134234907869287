import ChatMessage, {
  TChatMessageProps,
} from "@/components/ChatMessage/ChatMessage";
import { InlineIcon } from "@iconify/react";
import { HighlightOffRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./HistoriqueConversation.style.scss";
import { apiGetConversation } from "@/features/profile/api/exchange.api";
import { Toastify } from "@/utils/toast";
import * as moment from "moment";
import "moment/locale/fr";
import {
  TChatConversation,
  TMemberInfos,
  TPaginationResult,
} from "@/features/profile/types";
import useExchangeRole from "@/hooks/useExchangeRole";
import NoData from "@/components/No-data/NoData";
import { TMemberProfile } from "@/features/auth/types";
import {
  selectMemberInfos,
  selectMemberProfile,
} from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";

interface HistoriqueConversationProps {
  closeModal: () => void;
  conversation_id: string;
}
const HistoriqueConversation = ({
  closeModal,
  conversation_id,
}: HistoriqueConversationProps) => {
  const memberInfosFromStore = useSelector(selectMemberInfos) as TMemberInfos;
  const [discussion, setDiscussion] = useState<TChatMessageProps[]>([]);

  const LIMIT_MESSAGE = 50;

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [paginationResult, setPaginationResult] =
    useState<TPaginationResult | null>(null);
  const scrollRef = useRef<any>();

  useEffect(() => {
    loadConversation(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadConversation = useCallback(
    (page: number) => {
      if (paginationResult?.nextPage === null) {
      } else {
        setLoading(true);
        apiGetConversation(page, LIMIT_MESSAGE, conversation_id || "")
          .then((response: any) => {
            const data: TChatConversation[] = response.data.result;
            const conversation: TChatMessageProps[] = [...data].map(
              (elt: TChatConversation) => {
                false &&
                  console.log(
                    memberInfosFromStore.memberId + " " + elt.sender.memberId
                  );
                return {
                  _id: elt._id,
                  type: (memberInfosFromStore.memberId === elt.sender.memberId
                    ? "sent"
                    : "received") as "sent" | "received",
                  gender:
                    elt.sendBy === "STAFF"
                      ? "male"
                      : (elt.sender.gender.toLowerCase() as "male" | "female"),
                  fromStaff: elt.sendBy === "STAFF",
                  pending: elt.status === "PENDING",
                  message: elt.body,
                  isValid: elt.isModerated,
                  reason: "",
                  note: "",
                  isRead: elt.isRead,
                  issueDate: elt.issueDate,
                  time:
                    moment.default(new Date(elt.issueDate)).format("LL") +
                    " " +
                    moment.default(new Date(elt.issueDate)).format("LT"),
                };
              }
            );
            setPaginationResult(response.data.paginationResult);
            setLoading(false);
            setDiscussion((prevData) => [...prevData, ...conversation]);
          })
          .catch((error) => {
            setLoading(false);
            console.log("ERROR MESSAGE", error);
            console.log(error);
            error?.response?.data?.message &&
              Toastify(`${error?.response?.data?.message}`, "error", 5000);
          });
      }
    },
    [conversation_id, memberInfosFromStore.memberId, paginationResult?.nextPage]
  );

  const handleScroll = useCallback(() => {
    if (scrollRef.current.scrollTop === 0 && !loading) {
      if (paginationResult?.nextPage === null) {
      } else {
        const nextPage = page + 1;
        setPage(nextPage);
        loadConversation(nextPage);
      }
    }
  }, [loadConversation, loading, page, paginationResult?.nextPage]);

  useEffect(() => {
    const container = scrollRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, loading, page]);

  return (
    <div className="historique-conversation-scroll-container">
      <div className="historique-conversation-card">
        <img src={require("@/assets/images/png/logo-mini.png")} alt="logo" />

        <div className="discussion-container" ref={scrollRef}>
          {loading && (
            <img
              src={require("@/assets/images/gif/loadmore.gif")}
              height="70"
              width="70"
              className="load-more"
              alt="load-more"
            />
          )}
          {!loading && discussion.length === 0 && <NoData />}
          {!loading && discussion.length > 0 && (
            <>
              {discussion
                .sort(
                  (a, b) =>
                    new Date(a?.issueDate || "").getTime() -
                    new Date(b?.issueDate || "").getTime()
                )
                .map((msg, index) => {
                  return (
                    <ChatMessage
                      key={index}
                      _id={msg._id}
                      message={msg.message}
                      reason={msg.reason}
                      fromStaff={msg.fromStaff}
                      pending={msg.pending}
                      isValid={msg.isValid}
                      type={msg.type}
                      gender={msg.gender}
                      time={msg.time}
                      isRead={msg.isRead}
                    />
                  );
                })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HistoriqueConversation;
