import { createTheme, PaletteColorOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: string;
  }
  interface PaletteOptions {
    tertiary: PaletteColorOptions | undefined;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    // short: true;
    // long: true;
  }
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

const { palette } = createTheme();

export const muiCustomTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },
  palette: {
    primary: {
      main: "#73103E",
      // dark:,
      // light:,
      //contrastText:,
    },
    secondary: {
      main: "#FEF8EC",
      // dark:,
      // light:,
      //contrastText:,
    },
    tertiary: palette.augmentColor({
      color: {
        main: "#DEB86B",
        // dark:,
        // light:,
        contrastText: "#ffffff",
      },
    }),
    error: {
      main: "#FF3000",
      // dark:,
      // light:,
      //contrastText:,
    },
    warning: {
      main: "#F5B544",
      // dark:,
      // light:,
      //contrastText:,
    },
    // info: {
    //   main: '#73103E',
    //   dark:,
    //   light:,
    //   contrastText:,
    // },
    success: {
      main: "#75D873",
      // dark:,
      // light:,
      //contrastText:,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "40px",
          marginBottom: 20,
          "& fieldset": {
            borderRadius: 10,
            borderColor: "#e6e8ec",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: ["Lato", "sans-serif"],
          // fontWeight: 'normal',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: ["Lato", "sans-serif"],
          fontWeight: "normal",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: ["Lato", "sans-serif"],
          fontWeight: "normal",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontFamily: ["Lato", "sans-serif"],
          fontWeight: "normal",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: ["Lato", "sans-serif"],
          fontWeight: "normal",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: ["Lato", "sans-serif"],
          fontWeight: "normal",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          borderColor: "#cdcdcd",
          "& fieldset": {
            borderRadius: 10,
            borderColor: "#cdcdcd",
            borderWidth: "1px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "50px",
          fontFamily: ["Lato", "sans-serif"],
          fontWeight: "normal",
          textTransform: "inherit",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            borderRadius: 5,
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            borderRadius: 5,
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: ["Lato", "sans-serif"],
          fontWeight: "normal",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: ["Lato", "sans-serif"],
          fontWeight: "normal",
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          padding: "10px",
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: "#FEF8EC",
          fontSize: "1.5rem",
          "&.Mui-selected": {
            color: "#73103E",
            backgroundColor: "#FEF8EC",
            "&span": {
              fontFamily: ["Lato", "sans-serif"],
              fontWeight: "normal",
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: ["Lato", "sans-serif"],
          fontWeight: "normal",
          "&.Mui-error": {
            marginTop: "-2px",
            color: "#FF3000",
          },
        },
      },
    },
  },
});
