import { InlineIcon } from "@iconify/react";
import { Badge, styled, useMediaQuery, useTheme } from "@mui/material";
import { useCallback } from "react";
import { TMenu } from "../../types";
import { SideMenuLinkContainer } from "./SideMenu.styles";
import {
  selectNbreNotificationSocket,
  selectNbreLiveSocket,
} from "@/store/reducers/general_param/general_param.selector";
import {
  selectMessageNotif,
  selectFavoriteNotif,
  selectExchangeNotif,
} from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import useUserRestrictions from "@/hooks/useUserRestriction";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.secondary}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

interface SideMenuLinkProps {
  menu: TMenu;
  isActive: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}
const SideMenuLink = ({ menu, isActive, onClick }: SideMenuLinkProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const { isUserFullAccessAutorized } = useUserRestrictions();
  const nbre_notification_socket = useSelector(selectNbreNotificationSocket);
  const nbre_live_socket = useSelector(selectNbreLiveSocket);
  const nbre_message_notif = useSelector(selectMessageNotif);
  const nbre_favorite_notif = useSelector(selectFavoriteNotif);
  const nbre_exchange_notif = useSelector(selectExchangeNotif);

  const isBlocked = useCallback(() => {
    return (
      !isUserFullAccessAutorized &&
      (menu.label === "Accueil" ||
        menu.label === "Favoris" ||
        menu.label === "Échanges" ||
        menu.label === "Rechercher" ||
        menu.label === "Ma liste noire" ||
        menu.label === "Gestion des lives" ||
        menu.label === "Imam & Intervenants" ||
        menu.label === "Mon compte")
    );
  }, [menu.label, isUserFullAccessAutorized]);

  return (
    menu && (
      <SideMenuLinkContainer
        down_lg={matches.toString()}
        active={isActive.toString()}
        to={menu.route}
        onClick={(e) => {
          onClick?.(e);
        }}
        className={isBlocked() ? "not_available" : ""}
      >
        <>
          <InlineIcon icon={menu.icon} />

          {menu.label}

          {menu.label === "Favoris" && (
            <>
              <StyledBadge
                overlap="circular"
                variant="dot"
                sx={{ marginLeft: "20px" }}
                color={isActive ? "primary" : "warning"}
                badgeContent={nbre_favorite_notif}
              />
            </>
          )}
          {menu.label === "Notifications" && (
            <>
              <StyledBadge
                overlap="circular"
                variant="standard"
                sx={{ marginLeft: "20px" }}
                color={isActive ? "primary" : "warning"}
                badgeContent={nbre_notification_socket}
              />
            </>
          )}
          {menu.label === "Échanges" && (
            <>
              <StyledBadge
                overlap="circular"
                variant="standard"
                sx={{ marginLeft: "20px" }}
                color={isActive ? "primary" : "warning"}
                badgeContent={
                  nbre_message_notif // <---
                }
              />
              <StyledBadge
                overlap="circular"
                variant="standard"
                sx={{ marginLeft: "25px" }}
                color={isActive ? "info" : "info"}
                badgeContent={
                  nbre_exchange_notif // <---
                }
              />
            </>
          )}
          {menu.label === "Gestion des lives" && (
            <>
              <StyledBadge
                overlap="circular"
                variant="dot"
                sx={{ marginLeft: "20px" }}
                color={isActive ? "primary" : "warning"}
                badgeContent={nbre_live_socket}
              />
            </>
          )}
        </>
      </SideMenuLinkContainer>
    )
  );
};

export default SideMenuLink;
