import {
  selectMemberInfos,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { RouteObject, useNavigate, useRoutes } from "react-router-dom";
import { publicRoutes } from "./public.routes";
import { protectedRoutes } from "./protected.routes";
import { routePaths, SOCKET_URL } from "@/config";
import { createContext, useState, useEffect, useCallback, useRef } from "react";
import socketIOClient, { Socket } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { TMemberInfos } from "@/features/profile/types";
import queryString from "query-string";
import { store, persistor } from "@/store";
import {
  getMemberInfos,
  getToken,
  updateIsOnline,
  updateMemberCurrentExchange,
  updateMemberProfile,
} from "@/store/reducers/member/member.actions";
import { axios } from "@/lib/axios";
import { apiGetUserConnected } from "@/features/auth/api/auth.api";
import { TMemberProfile } from "@/features/auth/types";
import { Snackbar } from "@mui/material";
import { Toastify } from "@/utils/toast";
import { selectIsWebsiteMaintenance } from "@/store/reducers/general_param/general_param.selector";
import { maintenanceRoutes } from "./maintenance.routes";
import { updateIsPaymentPendingProcess } from "@/store/reducers/general_param/general_param.actions";

export const AppSocketContext = createContext<{
  socketIO: Socket | null;
  socketRef: any;
  logout: () => void;
}>({
  socketIO: null,
  socketRef: null,
  logout: () => {},
});

const AppRoutes = () => {
  const socketRef = useRef<Socket | null>(null);
  const [socketIO, setSocketIO] = useState<Socket | null>(null);
  const [attempt, setAttempt] = useState(0);
  const [loading, setLoading] = useState(false);

  const token = useSelector(selectToken);
  const isWebsiteInMaintainance = useSelector(selectIsWebsiteMaintenance);
  const memberInfosFromStore = useSelector(selectMemberInfos) as TMemberInfos;
  console.log({ memberInfosFromStore });

  const parsed = queryString.parse(window.location.search);
  console.log({ parsed });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    axios
      .post("/auth-s/api/v1/auth/logout")
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        store.dispatch({ type: "CLEAR_STORE" });
        localStorage.clear();
        navigate(routePaths.auth, { replace: true });
        dispatch(getToken(""));
        persistor.purge();

        if (socketRef.current) {
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      });
  }, [dispatch, navigate]);

  const handleTokenExpired = useCallback(() => {
    setAttempt((prev) => prev + 1);

    axios
      .post("/auth-s/api/v1/auth/p/refresh-token")
      .then((res) => {
        const newToken = res.data;
        store.dispatch(getToken(newToken));

        initializeSocket(newToken);
      })
      .catch((err) => {
        console.log({ err });
        if (attempt >= 3) {
          logout();
          setAttempt(0);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempt, logout]);

  const initializeSocket = useCallback(
    (token: string) => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }

      const io = socketIOClient(SOCKET_URL, {
        path: "/realtime/ws/handshake",
        query: { token: `Bearer ${token}` },
        transports: ["websocket"],
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        timeout: 20000,
      });

      io.on("token-expired", (data) => {
        console.log("🍄 Token expired", data);
        handleTokenExpired();
      });

      socketRef.current = io;
      setSocketIO(io);
    },
    [handleTokenExpired]
  );

  useEffect(() => {
    if (token) {
      initializeSocket(token);
    }
  }, [token, initializeSocket]);

  useEffect(() => {
    if (
      memberInfosFromStore?.isVerified === false &&
      parsed?.token &&
      token.length > 0
    ) {
      store.dispatch({ type: "CLEAR_STORE" });
      localStorage.clear();
      dispatch(getToken(""));
      persistor.purge();

      if (parsed?.email) {
        navigate(
          routePaths.authChildren.resetPasswordAbsolute +
            "?token=" +
            parsed?.token +
            "&email=" +
            parsed?.email,
          { replace: true }
        );
      } else {
        navigate(
          routePaths.authChildren.verifEmailAbsolute +
            "?token=" +
            parsed?.token,
          { replace: true }
        );
      }
    }

    if (parsed?.payment === "pending") {
      let maxTries = 10; // Nombre maximum de tentatives
      let tries = 0;
      setLoading(true);
      store.dispatch(updateIsPaymentPendingProcess(true));
      const manageDelayId = setInterval(() => {
        tries++;
        console.log(`Tentative ${tries}`);

        apiGetUserConnected()
          .then((response: any) => {
            const profile = response.data as TMemberProfile;
            console.log({ profile });
            const infosUser: TMemberInfos = {
              _id: profile.member._id,
              email: profile.member.email,
              role: profile.member.role,
              username: profile.member.username,
              memberId: profile.member.memberId,
              isVerified: store.getState().member.member_infos!?.isVerified,
              isInEmailVerificationProcess:
                store.getState().member.member_infos!
                  ?.isInEmailVerificationProcess,
            };
            console.log({ infosUser });
            store.dispatch(getMemberInfos({ ...infosUser }));
            store.dispatch(updateMemberProfile({ ...profile }));
            store.dispatch(
              updateMemberCurrentExchange(profile?.currentConversation || null)
            );
            store.dispatch(updateIsOnline(profile.availability.isAvailable));

            if (profile.account.subscription.lastPayment.hasExpired === false) {
              clearInterval(manageDelayId); // Arrête l'intervalle une fois que le paiement est valide
              setLoading(false);
              store.dispatch(updateIsPaymentPendingProcess(false));
              Toastify("Paiement effectué avec succès", "success", 2000);
            }
          })
          .catch((error) => {
            console.log("😜", error);
          })
          .finally(() => {});

        // Ajoutez ici votre logique principale à exécuter à chaque intervalle

        if (tries >= maxTries) {
          clearInterval(manageDelayId);
          setLoading(false); // Arrête l'intervalle après avoir atteint le nombre max de tentatives
          console.log(
            "Nombre maximum de tentatives atteint. Intervalle arrêté."
          );
        }
      }, 1000);
    }
  }, [dispatch, memberInfosFromStore, navigate, parsed, token]);

  const routes = useCallback((): RouteObject[] => {
    if (isWebsiteInMaintainance) {
      return maintenanceRoutes;
    } else {
      return token.length === 0 ? publicRoutes : protectedRoutes;
    }
  }, [isWebsiteInMaintainance, token.length]);

  const element = useRoutes([...routes()]);

  return (
    <AppSocketContext.Provider value={{ socketIO, socketRef, logout }}>
      <>
        {element}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={loading}
          message="Paiement en cours de traitement ..."
        />
      </>
    </AppSocketContext.Provider>
  );
};

export default AppRoutes;
