import React, { useState } from "react";
import { routePaths } from "@/config";
import { Link, useNavigate } from "react-router-dom";
import "./VerifEmailForm.styles.scss";
import queryString from "query-string";
import { TMemberInfos } from "@/features/profile/types";
import { useAppDispatch } from "@/store";
import {
  getToken,
  getMemberInfos,
  updateProgress,
  updateLocation,
  updateMemberProfile,
} from "@/store/reducers/member/member.actions";
import { Toastify } from "@/utils/toast";
import { jwtDecode } from "jwt-decode";
import { apiVerifyEmailConformity } from "../../api/auth.api";
import { LoadingButton } from "@mui/lab";
import { TMemberProfile } from "../../types";

const VerifEmailForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isEmailVerify, setIsEmailVerify] = useState(false);
  const [isButtonEmailCheckPressed, setIsButtonEmailCheckPressed] =
    useState(false);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleVerifyEmail = () => {
    const parsed = queryString.parse(window.location.search);
    console.log({ parsed });
    const tokenFromUrl = (parsed?.token || "") as string;

    setIsButtonEmailCheckPressed(true);
    setLoading(true);
    apiVerifyEmailConformity(tokenFromUrl)
      .then((response: any) => {
        setLoading(false);
        setIsEmailVerify(true);
        console.log(response);

        const token = response.data.accessToken;
        const profile = response.data.profile as TMemberProfile;
        console.log({ profile });
        const decoded = jwtDecode(token);
        console.log(decoded);

        const infosUser: TMemberInfos = {
          _id: (decoded.sub as any)?._id || "",
          email: (decoded.sub as any)?.email,
          role: (decoded.sub as any)?.role,
          username: (decoded.sub as any)?.username,
          memberId: (decoded.sub as any)?.memberId,
          isVerified: (decoded.sub as any)?.isVerified,
          isInEmailVerificationProcess: (decoded.sub as any)
            ?.isInEmailVerificationProcess,
        };
        console.log({ infosUser });
        dispatch(getToken(token));
        dispatch(getMemberInfos({ ...infosUser }));
        dispatch(updateMemberProfile({ ...profile }));

        navigate(routePaths.profile, { replace: false });
        dispatch(updateProgress(0));

        if (false) {
          navigator.geolocation.getCurrentPosition((position) => {
            var location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            console.log(location);
            dispatch(updateLocation(location));
          });
          navigate(routePaths.profileChildren.myAccountAbsolute, {
            replace: false,
          });
          Toastify(
            `As Salam Aleykoum ${infosUser.username}, tu es maintenant connecté(e).`,
            "success",
            5000
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        setIsEmailVerify(false);
        false && console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  return (
    <div className="paiement-container">
      <div className="header-part">
        <h1 className="title">Vérification de l'E-mail</h1>
      </div>

      <div className="loader-part-verif">
        <img
          src={require("@/assets/images/png/logo-mini.png")}
          className="logo-mini"
          alt="load-profil"
        />

        {!isButtonEmailCheckPressed ? (
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            variant="contained"
            color="primary"
            sx={{ marginTop: "30px", width: "fit-content", paddingX: 5 }}
            onClick={handleVerifyEmail}
          >
            Vérifier l'email
          </LoadingButton>
        ) : loading ? (
          <>
            <img
              src={require("@/assets/images/gif/loadmore.gif")}
              className="load-profil"
              alt="load-profil"
            />
            <small> E-mail en cours de vérification </small>
          </>
        ) : isEmailVerify ? (
          <>
            <p className="success"> E-mail vérifié! </p>
          </>
        ) : (
          <>
            <p>
              {" "}
              Désolé, Le lien de vérification a expiré.
              <br />
              Veuillez vous{" "}
              <Link
                className="link-connect"
                to={routePaths.authChildren.signInAbsolute}
              >
                {" "}
                connecter
              </Link>
              , pour recevoir un nouveau lien.{" "}
            </p>
          </>
        )}
      </div>

      <div className="footer-part">
        <Link
          className="link-primary"
          to={routePaths.authChildren.signInAbsolute}
        >
          {" "}
          Retourner sur la page de connexion
        </Link>
        <a
          href="https://zawaj-sounnah.com/"
          target="_blank"
          rel="noreferrer"
          className="link-gray"
        >
          Aller sur zawaj-sounnah.com
        </a>
      </div>
    </div>
  );
};

export default VerifEmailForm;
