import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import { IconButton } from "@mui/material";
import { HighlightOffRounded } from "@mui/icons-material";
import {
  selectMemberInfos,
  selectMemberProfile,
} from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import "./ModalVerifiedEmail.styles.scss";
import useUserRestrictions from "@/hooks/useUserRestriction";
import { apiConfirmEmail } from "@/features/auth/api/auth.api";
import { Toastify } from "@/utils/toast";
import { LoadingButton } from "@mui/lab";
import { InlineIcon } from "@iconify/react";
import { TMemberProfile } from "@/features/auth/types";
import { TMemberInfos } from "@/features/profile/types";

interface ModalVerifiedEmailProps {
  open: boolean;
}

const ModalVerifiedEmail = ({ open }: ModalVerifiedEmailProps) => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;
  const memberInfosFromStore = useSelector(selectMemberInfos) as TMemberInfos;

  const { isUserEmailVerificationExpired } = useUserRestrictions();
  const [loading, setLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleConfirmEmail = () => {
    setLoading(true);
    apiConfirmEmail()
      .then((response: any) => {
        console.log(response);
        setLoading(false);
        setIsEmailSent(true);
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
        setIsEmailSent(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const [openM, setOpenM] = useState(open);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openM}
      onClose={() => {
        setOpenM(false);
      }}
    >
      <Grow in={openM}>
        <div className="c-modal-verified-email ">
          <IconButton
            className="close-icon"
            size="large"
            color="inherit"
            onClick={() => {
              setOpenM(false);
            }}
          >
            <HighlightOffRounded />
          </IconButton>
          <img
            src={require(
              "@/assets/images/png/avatar-" +
                (memberProfileFromStore?.infos?.identity?.gender.toLowerCase() ||
                  "male") +
                ".png"
            )}
            alt="sidebar-logo"
            className="modal-avatar"
          />

          <h3>
            <b> E-MAIL NON VÉRIFIÉ </b>
          </h3>
          <br />

          {isUserEmailVerificationExpired ? (
            <p>
              {isEmailSent ? (
                <>
                  <span>
                    {" "}
                    Un mail vous a été envoyé à l'adresse suivante:{" "}
                    <b>{memberInfosFromStore.email}</b>{" "}
                  </span>
                  <br />
                  <br />
                  <b>Veuillez consulter votre boîte e-mail.</b>
                  <br />
                  <LoadingButton
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                    className="btn-modal"
                    color="primary"
                    onClick={handleConfirmEmail}
                    startIcon={<InlineIcon icon="mingcute:send-fill" />}
                  >
                    Renvoyer le lien
                  </LoadingButton>
                </>
              ) : (
                <>
                  Votre lien de vérification d'e-mail a expiré.
                  <br />
                  <br />
                  <LoadingButton
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                    className="btn-modal"
                    color="primary"
                    onClick={handleConfirmEmail}
                    startIcon={<InlineIcon icon="mingcute:send-fill" />}
                  >
                    Renvoyer le lien
                  </LoadingButton>
                </>
              )}
            </p>
          ) : (
            <p>
              Nous remarquons que
              <span className="bold">
                {" "}
                vous n'avez pas encore vérifié votre adresse e-mail
              </span>{" "}
              pour accéder à notre plateforme.
              <br />
              <br />
              La vérification de votre adresse e-mail est une étape importante
              pour assurer{" "}
              <span className="bold">
                la sécurité de votre compte et garantir que vous recevez des
                notifications importantes
              </span>{" "}
              de notre part.
              <br />
              <br />
              <b>
                Veuillez consulter votre boîte e-mail à l'adresse suivante:{" "}
                <b style={{ color: "var(--ui-primary)" }}>
                  {memberInfosFromStore.email}{" "}
                </b>
              </b>
            </p>
          )}
        </div>
      </Grow>
    </Modal>
  );
};

export default ModalVerifiedEmail;
