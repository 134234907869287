import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectMember = (state: TRootState) => state.member;

export const selectUserConnected = createSelector(
  [selectMember],
  (member) => member.user_connected
);

export const selectMemberInfos = createSelector(
  [selectMember],
  (member) => member.member_infos
);

export const selectMemberProfile = createSelector(
  [selectMember],
  (member) => member.member_profile
);

export const selectMemberCurrentExchange = createSelector(
  [selectMember],
  (member) => member.member_current_exchange
);

export const selectToken = createSelector(
  [selectMember],
  (member) => member.token
);

export const selectProgress = createSelector(
  [selectMember],
  (member) => member.progress
);

export const selectLocation = createSelector(
  [selectMember],
  (member) => member.location
);

export const selectMessageNotif = createSelector(
  [selectMember],
  (member) => member.nbre_message_notif
);

export const selectFavoriteNotif = createSelector(
  [selectMember],
  (member) => member.nbre_favorite_notif
);

export const selectExchangeNotif = createSelector(
  [selectMember],
  (member) => member.nbre_exchange_notif
);

export const selectNewModificationSubmit = createSelector(
  [selectMember],
  (member) => member.new_modification_submit
);
