import { TMemberProfile } from "@/features/auth/types";
import { TMemberCurrentExchange, TMiniProfil } from "@/features/profile/types";
import {
  selectMemberCurrentExchange,
  selectMemberProfile,
} from "@/store/reducers/member/member.selector";
import { useCallback } from "react";
import { useSelector } from "react-redux";

const useExchangeRole = () => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const memberCurrentExchange = useSelector(
    selectMemberCurrentExchange
  ) as TMemberCurrentExchange | null;

  const sender = useCallback((): TMiniProfil | null => {
    if (memberCurrentExchange) {
      if (memberProfileFromStore.infos.identity.gender === "MALE")
        return { ...memberCurrentExchange.male, gender: "MALE" };
      else return { ...memberCurrentExchange.female, gender: "FEMALE" };
    } else {
      return null;
    }
  }, [memberCurrentExchange, memberProfileFromStore.infos.identity.gender]);

  const receiver = useCallback((): TMiniProfil | null => {
    if (memberCurrentExchange) {
      if (memberProfileFromStore.infos.identity.gender === "MALE")
        return { ...memberCurrentExchange.female, gender: "FEMALE" };
      else return { ...memberCurrentExchange.male, gender: "MALE" };
    } else {
      return null;
    }
  }, [memberCurrentExchange, memberProfileFromStore.infos.identity.gender]);

  return {
    sender: sender(),
    receiver: receiver(),
  };
};
export default useExchangeRole;
