export const calculerAge = (dateNaissance: Date): number => {
  var dateActuelle = new Date();
  var anneeActuelle = dateActuelle.getFullYear();
  var moisActuel = dateActuelle.getMonth() + 1;
  var jourActuel = dateActuelle.getDate();

  var anneeNaissance = dateNaissance.getFullYear();
  var moisNaissance = dateNaissance.getMonth() + 1;
  var jourNaissance = dateNaissance.getDate();

  var age = anneeActuelle - anneeNaissance;

  // Vérifie si l'anniversaire de cette année est déjà passé
  if (
    moisActuel < moisNaissance ||
    (moisActuel === moisNaissance && jourActuel < jourNaissance)
  ) {
    age--;
  }

  return age;
};
