import { routePaths } from "@/config";
import { Navigate, Route, Routes } from "react-router-dom";
import LAuthentication from "../layouts/Authentication.layout";
import ForgottenPassword from "../pages/ForgottenPassword.page";
import ResetPassword from "../pages/ResetPassword.page";
import Signin from "../pages/Signin.page";
import Signup from "../pages/Signup.page";
import TermsAndConditions from "../pages/TermsAndConditions.page";
import VerifEmail from "../pages/VerifEmail.page";

export const AuthRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<LAuthentication />}>
        <Route
          index
          element={
            <Navigate to={`${routePaths.authChildren.signInAbsolute}`} />
          }
        />
        <Route path={routePaths.authChildren.signIn} element={<Signin />} />
        <Route path={routePaths.authChildren.signUp} element={<Signup />} />
        <Route
          path={routePaths.authChildren.forgottenPassword}
          element={<ForgottenPassword />}
        />
        <Route
          path={routePaths.authChildren.resetPassword}
          element={<ResetPassword />}
        />
        <Route
          path={routePaths.authChildren.termsAndCondition}
          element={<TermsAndConditions />}
        />
        <Route
          path={routePaths.authChildren.verifEmail}
          element={<VerifEmail />}
        />
        <Route
          path="/*"
          element={
            <Navigate to={`${routePaths.authChildren.signInAbsolute}`} />
          }
        />
      </Route>
    </Routes>
  );
};
