import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";

export const apiGetNotification = (
  page: number = 1,
  limit: number = 6
): Promise<AxiosResponse<any, any>> => {
  return axios.get(
    "/notif-s/api/v1/notifications?page=" + page + "&limit=" + limit
  );
};

export const apiRemoveNotification = (
  notification_id: string
): Promise<AxiosResponse<any, any>> => {
  return axios.delete("/notif-s/api/v1/notifications/" + notification_id);
};
