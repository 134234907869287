import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";

export const apiGetInterfaceSettings = (): Promise<AxiosResponse<any, any>> => {
  return axios.get("/param-s/api/v1/parameters/interface-settings");
};

export const apiToggleDisponibilite = (
  isAvailable: boolean
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/profile-s/api/v1/profiles/availability", {
    isAvailable,
  });
};
