import React, { useState } from "react";
import "./DeleteAccount.styles.scss";
import {
  selectMemberInfos,
  selectMemberProfile,
} from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { TMemberInfos } from "@/features/profile/types";
import { Toastify } from "@/utils/toast";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";
import * as moment from "moment";
import "moment/locale/fr";
import * as yup from "yup";
import { apiDeleteAccount } from "@/features/profile/api/subscription.api";
import { HighlightOffRounded } from "@mui/icons-material";
import { routePaths } from "@/config";
import { persistor, store } from "@/store";
import { getToken } from "@/store/reducers/member/member.actions";
import { useNavigate } from "react-router-dom";
import { TMemberProfile } from "@/features/auth/types";
import { axios } from "@/lib/axios";

const DeleteAccount = () => {
  const memberInfosFromStore = useSelector(selectMemberInfos) as TMemberInfos;
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const [loading, setLoading] = useState(false);

  const [openLogout, setOpenLogout] = useState(false);
  const handleClickOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const [openSignal, setOpenSignal] = useState(false);
  const handleOpenSignal = () => setOpenSignal(true);
  const handleCloseSignal = () => {
    formik2.resetForm();
    setOpenSignal(false);
  };

  const [openModalBeforeDelete, setOpenModalBeforeDelete] = useState(false);
  const handleClickOpenModalBeforeDelete = () => setOpenModalBeforeDelete(true);
  const handleCloseModalBeforeDelete = () => setOpenModalBeforeDelete(false);

  const navigate = useNavigate();

  const logout = () => {
    axios.post("/auth-s/api/v1/auth/logout").finally(() => {
      navigate(routePaths.auth, { replace: true });
      store.dispatch({ type: "CLEAR_STORE" });
      localStorage.clear();
      store.dispatch(getToken(""));
      persistor.purge();
    });
  };

  const validationSchema = yup.object({
    reason_radio: yup
      .string()
      .required(" La raison est obligatoire, svp faites un choix "),
  });

  const formik = useFormik({
    initialValues: {
      reason_radio: "",
      reason_other: "",
      amelioration: "",
      recommend: "",
      come_back: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleOpenSignal();
    },
  });

  const validationSchema2 = yup.object({
    password: yup.string().required(" le mot de passe est obligatoire "),
  });

  const formik2 = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema2,
    onSubmit: (values: any) => {
      handleClickOpenLogout();
    },
  });

  const deleteAccountSubmit = (data: any) => {
    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);

    console.log(data);
    const dataTosend = {
      password: data.password,
      predefinedReason: data.reason_radio,
      otherReason: data.reason_other,
      suggestion: data.amelioration,
      recommended: data.recommend === "oui" ? true : false,
      willingToReturn: data.come_back === "oui" ? true : false,
    };

    setLoading(true);

    apiDeleteAccount(dataTosend)
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        logout();
      })
      .catch((error: any) => {
        setLoading(false);

        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const deleteAccount = () => {
    deleteAccountSubmit({ ...formik.values, ...formik2.values });
    handleCloseLogout();
    handleCloseSignal();
  };

  return (
    <>
      <PerfectScrollbar className="ct-delete-account">
        <form className="delete-box" onSubmit={formik.handleSubmit}>
          <Box>
            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                <div className="recap-part">
                  <div className="text-part">
                    <h3> ID: {memberInfosFromStore?.memberId} </h3>
                    <p>
                      {" "}
                      Actif depuis le &nbsp;
                      <b>
                        {moment
                          .default(memberProfileFromStore?.activation?.date)
                          .format("L")}
                      </b>
                    </p>
                  </div>
                  <div className="infos-part">
                    <p>
                      Compte &nbsp;
                      {memberProfileFromStore?.activation?.isActivate
                        ? "Actif"
                        : "Inactif"}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Alert severity="warning">
                  <p className="text-blip" style={{ fontSize: "large" }}>
                    Pour 
                    <b>
                      les comptes antérieurs à la création de la nouvelle
                      plateforme
                    </b>
                    soit le <b>03 Mars 2023</b>, ne supprimez pas votre compte
                    vous même. <br />
                    <br />
                    Merci de bien vouloir nous contacter par <b>WhatsApp</b> ou
                    par 
                    <a href="mailto:zawaj.sounnah@gmail.com">
                      <b>E-mail</b> (zawaj.sounnah@gmail.com)
                    </a>
                      afin de ne ne pas être re prélever merci de suivre le
                    protocole !
                  </p>
                </Alert>
              </Grid>

              {memberProfileFromStore?.activation?.isActivate ? (
                <>
                  <Grid item xs={12} sm={12} lg={12} mt={2}>
                    <p>
                      <b>
                        {" "}
                        Pour quelle raison souhaitez-vous supprimer votre compte
                        ?{" "}
                      </b>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <FormControl fullWidth color="primary">
                      <RadioGroup
                        row
                        aria-labelledby="id-civilité"
                        className="radio-input"
                        name="reason_radio"
                        value={formik.values.reason_radio}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="not-found-what-looking-for"
                          control={<Radio />}
                          label="Vous n'avez pas trouvé ce que vous recherchez"
                        />
                        <FormControlLabel
                          value="plateform-not-suitable"
                          control={<Radio />}
                          label="Le site ne vous convient pas"
                        />
                        <FormControlLabel
                          value="subscription-too-expensive"
                          control={<Radio />}
                          label="L'abonnement est trop cher"
                        />
                        <FormControlLabel
                          value="other-reason"
                          control={<Radio />}
                          label="Autre (préciser)"
                        />
                      </RadioGroup>
                      <FormHelperText
                        error={
                          formik.touched.reason_radio &&
                          Boolean(formik.errors.reason_radio)
                        }
                      >
                        {formik.errors.reason_radio}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {formik.values.reason_radio === "other-reason" && (
                    <Grid item xs={12} sm={12} lg={12}>
                      <TextField
                        placeholder="Autre raison"
                        variant="outlined"
                        label="Autre raison"
                        name="reason_other"
                        onChange={formik.handleChange}
                        value={formik.values.reason_other}
                        type="text"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} lg={12} mt={2}>
                    <p>
                      <b>
                        {" "}
                        Avez-vous une critique constructive à faire afin
                        d'améliorer la plate-forme ?{" "}
                      </b>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <div className="text-area">
                      <textarea
                        className="text-area-input"
                        rows={4}
                        name="amelioration"
                        onChange={formik.handleChange}
                        value={formik.values.amelioration}
                      ></textarea>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} mt={2}>
                    <p>
                      <b>
                        {" "}
                        Auriez-vous recommandé la plate-forme a votre entourage
                        pour faire les causes pour le mariage ?{" "}
                      </b>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <FormControl fullWidth color="primary">
                      <RadioGroup
                        row
                        aria-labelledby="id-civilité"
                        className="radio-input"
                        name="recommend"
                        value={formik.values.recommend}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="oui"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="non"
                          control={<Radio />}
                          label="Non"
                        />
                      </RadioGroup>
                      <FormHelperText
                        error={
                          formik.touched.recommend &&
                          Boolean(formik.errors.recommend)
                        }
                      >
                        {formik.errors.recommend}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} mt={2}>
                    <p>
                      <b> Pensez-vous revenir un jour parmi nous ? </b>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <FormControl fullWidth color="primary">
                      <RadioGroup
                        row
                        aria-labelledby="id-civilité"
                        className="radio-input"
                        name="come_back"
                        value={formik.values.come_back}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="oui"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="non"
                          control={<Radio />}
                          label="Non"
                        />
                      </RadioGroup>
                      <FormHelperText
                        error={
                          formik.touched.come_back &&
                          Boolean(formik.errors.come_back)
                        }
                      >
                        {formik.errors.come_back}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <LoadingButton
                      disabled={!formik.isValid}
                      loading={loading}
                      loadingPosition="center"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Supprimer mon compte
                    </LoadingButton>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={12} lg={12}>
                  <Alert severity="info">
                    <p style={{ fontSize: "large" }}>
                      Vous disposez d'un <b> abonnement encore actif </b>.{" "}
                      <br />
                      Pour <b>supprimer</b> votre compte vous devrez au
                      préalable <br />
                      <b
                        style={{
                          color: "var(--ui-primary)",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(
                            routePaths.profileChildren.subscriptionChildren
                              .desactivateSubscriptionAbsolute,
                            { replace: true }
                          );
                        }}
                      >
                        désactiver votre abonnement
                      </b>
                    </p>
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Box>
        </form>
      </PerfectScrollbar>

      {/* SIGNAL */}
      {openSignal && (
        <Modal
          open={openSignal}
          onClose={handleCloseSignal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
            <form className="form-raison-part" onSubmit={formik2.handleSubmit}>
              <IconButton
                className="close-icon"
                size="large"
                color="inherit"
                onClick={handleCloseSignal}
              >
                <HighlightOffRounded />
              </IconButton>
              <p>
                {" "}
                Veuillez renseigner votre mot de passe pour supprimer le compte{" "}
              </p>
              <br />
              <TextField
                placeholder="mot de passe"
                variant="outlined"
                label="Votre mot de passe"
                name="password"
                onChange={formik2.handleChange}
                value={formik2.values.password}
                error={
                  formik2.touched.password && Boolean(formik2.errors.password)
                }
                helperText={
                  formik2.touched.password &&
                  (formik2.errors.password as string)
                }
                type="password"
              />

              <Box sx={{ width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={6}>
                    <Button
                      variant="contained"
                      color="tertiary"
                      onClick={handleCloseSignal}
                      sx={{ marginTop: "30px", width: "100%" }}
                      type="submit"
                    >
                      {" "}
                      ANNULER{" "}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <LoadingButton
                      loading={loading}
                      loadingPosition="center"
                      variant="contained"
                      color="primary"
                      sx={{ marginTop: "30px", width: "100%" }}
                      type="submit"
                    >
                      {" "}
                      SUPPRIMER{" "}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </>
        </Modal>
      )}
      {/* End of SIGNAL */}

      {/* DESACTIVATE  DIALOG PART */}
      {openLogout && (
        <Dialog
          open={openLogout}
          onClose={handleCloseLogout}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Suppression"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <b>Attention!!!</b> cette action est irréversible et entraîne
              également la suppression de votre abonnement.
              <br />
              Vous êtes sur le point de <b>supprimer</b> votre compte. Êtes-vous
              sûr(e) de vouloir le faire ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseLogout}>Annuler</Button>
            <Button onClick={deleteAccount} autoFocus>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* Ennd of DESACTIVATE  DIALOG PART */}

      {/* SIGNAL */}
      {false && openModalBeforeDelete && (
        <Dialog
          open={openModalBeforeDelete}
          onClose={handleCloseModalBeforeDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Abonnement actif"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ color: "var(--ui-dark)" }}>
                Vous disposez d'un abonnement encore actif.
                <br />
                Veuillez au préalable{" "}
                <b
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(
                      routePaths.profileChildren.subscriptionChildren
                        .desactivateSubscriptionAbsolute,
                      { replace: true }
                    );
                  }}
                >
                  désactiver votre abonnement
                </b>{" "}
                avant de supprimer votre compte
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={handleCloseModalBeforeDelete}>
              Annuler
            </Button>
            <Button
              style={{ fontWeight: "bold" }}
              onClick={() => {
                navigate(
                  routePaths.profileChildren.subscriptionChildren
                    .desactivateSubscriptionAbsolute,
                  { replace: true }
                );
              }}
              autoFocus
            >
              Désactiver mon abonnement
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* End of SIGNAL */}
    </>
  );
};

export default DeleteAccount;
