import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";

export const apiGetBlackList = (
  data?: any
): Promise<AxiosResponse<any, any>> => {
  return axios.post("/profile-s/api/v1/profiles/blocked-members", data);
};

export const apiAddToBlacklist = (
  member_id: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/profile-s/api/v1/profiles/blocked", {
    profileID: member_id,
  });
};

export const apiRemoveFromBlacklist = (
  member_id: string
): Promise<AxiosResponse<any, any>> => {
  return axios.patch("/profile-s/api/v1/profiles/unblocked", {
    profileID: member_id,
  });
};
