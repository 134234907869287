import { routePaths } from "@/config";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";
import { InlineIcon } from "@iconify/react";
import { Close } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  IconButton,
  LinearProgress,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import AspectStep from "./Aspect-step/Aspect.step";
import "./Formulaire.styles.scss";
import PratiquesStep from "./Pratiques-step/Pratiques.step";
import ProfilStep from "./Profil-step/Profil.step";
import SanteStep from "./Sante-step/Sante.step";
import { useSelector } from "react-redux";
import useUserRestrictions from "@/hooks/useUserRestriction";
import { TMemberProfile } from "@/features/auth/types";
import * as _ from "lodash";
import CModalRejectedModif from "@/components/Modal-Rejected-Modif/ModalRejectedModif";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Formulaire = () => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const {
    isUserProfileModificationApproved,
    isUserProfileModificationRejected,
    isUserProfileModificationPending,
  } = useUserRestrictions();

  const ps = useRef();

  const theme = useTheme();
  const matches_down_md = useMediaQuery(theme.breakpoints.down("md"));

  const [openAlert, setOpenAlert] = useState(false);
  const handleOpenAlert = () => setOpenAlert(true);
  const handleCloseAlert = () => setOpenAlert(false);

  const [openAlertForUpdate, setOpenAlertForUpdate] = useState(true);

  const [valueTabs, setValueTabs] = useState(0);
  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabs(newValue);
  };

  const scrollTop = () => {
    const curr = (ps as React.MutableRefObject<any>).current;
    if (curr) {
      console.log("oohh");
      curr.scrollTop = 0;
    }
  };

  const [openModerationReport, setOpenModerationReport] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const goTo = (routes: string) => {
    navigate(routes, { replace: true });
  };

  useEffect(() => {
    const formStep = location.state?.formStep;
    if (formStep !== "") {
      formStep === "1" && setValueTabs(0);
      formStep === "2" && setValueTabs(1);
      formStep === "3" && setValueTabs(2);
      formStep === "4" && setValueTabs(3);
    }
  }, [location.state?.formStep]);

  return (
    <>
      <div className="ct-formulaire">
        <div className="ct-formulaire__nav-part">
          <Button
            color="primary"
            className="ct-formulaire__nav-part__btn-nav"
            onClick={() => {
              goTo(routePaths.profileChildren.myAccountChildren.ficheAbsolute);
            }}
            startIcon={<InlineIcon icon="ant-design:file-search-outlined" />}
          >
            Accédez à la fiche
          </Button>

          <Button
            color="info"
            className="ct-formulaire__nav-part__btn-nav"
            onClick={handleOpenAlert}
            startIcon={<InlineIcon icon="clarity:warning-line" />}
          >
            Bon à savoir
          </Button>

          {!isUserProfileModificationApproved && (
            <>
              {!openAlertForUpdate && (
                <IconButton
                  size="medium"
                  color={
                    isUserProfileModificationRejected
                      ? memberProfileFromStore?.status?.reason?.length > 0
                        ? "error"
                        : "warning"
                      : "info"
                  }
                  onClick={() => {
                    setOpenAlertForUpdate((prev) => !prev);
                    scrollTop();
                  }}
                  className="icon-blip"
                >
                  <InlineIcon
                    icon={
                      isUserProfileModificationRejected
                        ? memberProfileFromStore?.status?.reason?.length > 0
                          ? "material-symbols:error-outline-rounded"
                          : "material-symbols:warning-outline-rounded"
                        : "material-symbols:info-outline-rounded"
                    }
                  />
                </IconButton>
              )}
            </>
          )}

          {memberProfileFromStore?.status?.reason?.length > 0 && (
            <Button
              color="warning"
              className="ct-fiche__nav-part__btn-nav report"
              onClick={() => {
                setOpenModerationReport(true);
              }}
              startIcon={<InlineIcon icon="fluent-mdl2:report-warning" />}
            >
              Rapport de modération
            </Button>
          )}
        </div>

        <div
          className="ct-formulaire__body-part"
          ref={(el) => ((ps as React.MutableRefObject<any>).current = el)}
        >
          <Collapse in={openAlert}>
            <Alert
              severity="warning"
              className="ct-formulaire__body-part__alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleCloseAlert}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>Informations utiles</AlertTitle>
              <strong>
                ** Infos réservées à zawaj sunnah et non-visibles par les
                membres
              </strong>
              <br />
              Toute modification du profil entraînera une modération de
              celui-ci.
              <br />
              Durant la modération, ton profil est inaccessible aux autres
              membres
              <br />
            </Alert>
          </Collapse>
          <div className="ct-formulaire__body-part__card-part">
            <div className="pastille-container">
              <div className="option">
                {isUserProfileModificationApproved ? (
                  <>
                    <div className="option-ok">
                      <InlineIcon icon="humbleicons:verified" />
                    </div>
                    <small className="ok">Profil approuvé</small>
                  </>
                ) : (
                  <>
                    <div className="option-ko">
                      <InlineIcon icon="material-symbols:cancel-outline-rounded" />
                    </div>
                    <small className="ko">
                      {" "}
                      Profil en attente de vérification
                    </small>
                  </>
                )}
              </div>
              <div className="option">
                <div className="option-ok">
                  <InlineIcon icon="humbleicons:verified" />
                </div>
                <small className="ok">Engagement</small>
              </div>
            </div>

            <Box sx={{ width: "100%" }}>
              <p className="profil-progress-text">
                {" "}
                Profil rempli à{" "}
                <b>
                  {Math.round(memberProfileFromStore.rateOfCompletion)}%
                </b>{" "}
              </p>
              <LinearProgress
                variant="determinate"
                color="success"
                value={Math.round(memberProfileFromStore.rateOfCompletion)}
              />
            </Box>

            {!isUserProfileModificationApproved && (
              <Collapse in={openAlertForUpdate} style={{ width: "100%" }}>
                <Alert
                  style={{ width: "100%" }}
                  severity={
                    isUserProfileModificationRejected
                      ? memberProfileFromStore?.status?.reason?.length > 0
                        ? "error"
                        : "warning"
                      : "info"
                  }
                  onClose={() => {
                    setOpenAlertForUpdate((prev) => !prev);
                  }}
                  className="ct-formulaire__body-part__alert"
                >
                  {isUserProfileModificationRejected && (
                    <>
                      {memberProfileFromStore?.status?.reason?.length > 0 ? (
                        <>
                          <AlertTitle>Modifications rejetées</AlertTitle>
                          <>
                            Les modifications apportées à votre profil ont été{" "}
                            <b> rejetées</b> par notre équipe de modération pour
                            la(les) raison(s) suivante(s):
                            <ul
                              style={{
                                textAlign: "left",
                                paddingLeft: "20px",
                                paddingTop: "20px",
                                marginBottom: "20px",
                              }}
                            >
                              {memberProfileFromStore?.status?.reason?.map(
                                (x, index) => (
                                  <li className="li-reason" key={index}>
                                    {x.category === "identity" && (
                                      <>
                                        Dans la{" "}
                                        <b
                                          onClick={() => {
                                            navigate(
                                              routePaths.profileChildren
                                                .myAccountChildren
                                                .ficheAbsolute,
                                              { replace: true }
                                            );
                                          }}
                                        >
                                          <InlineIcon
                                            icon="akar-icons:link-out"
                                            fontSize={12}
                                          />
                                          Fiche d'information
                                        </b>
                                        :
                                      </>
                                    )}
                                    {x.category === "profile" && (
                                      <>
                                        Dans la rubrique{" "}
                                        <b
                                          onClick={() => {
                                            navigate(
                                              routePaths.profileChildren
                                                .myAccountChildren.formAbsolute,
                                              {
                                                replace: true,
                                                state: { formStep: "1" },
                                              }
                                            );
                                          }}
                                        >
                                          <InlineIcon
                                            icon="akar-icons:link-out"
                                            fontSize={12}
                                          />
                                          Profile
                                        </b>
                                        :
                                      </>
                                    )}
                                    {x.category === "religiousAspects" && (
                                      <>
                                        Dans la rubrique{" "}
                                        <b
                                          onClick={() => {
                                            navigate(
                                              routePaths.profileChildren
                                                .myAccountChildren.formAbsolute,
                                              {
                                                replace: true,
                                                state: { formStep: "2" },
                                              }
                                            );
                                          }}
                                        >
                                          <InlineIcon
                                            icon="akar-icons:link-out"
                                            fontSize={12}
                                          />
                                          Aspect réligieux
                                        </b>
                                        :
                                      </>
                                    )}
                                    {x.category === "religiousPractices" && (
                                      <>
                                        Dans la rubrique{" "}
                                        <b
                                          onClick={() => {
                                            navigate(
                                              routePaths.profileChildren
                                                .myAccountChildren.formAbsolute,
                                              {
                                                replace: true,
                                                state: { formStep: "3" },
                                              }
                                            );
                                          }}
                                        >
                                          <InlineIcon
                                            icon="akar-icons:link-out"
                                            fontSize={12}
                                          />
                                          Pratiques réligieuses
                                        </b>
                                        :
                                      </>
                                    )}
                                    {x.category === "healthAndDisability" && (
                                      <>
                                        Dans la rubrique{" "}
                                        <b
                                          onClick={() => {
                                            navigate(
                                              routePaths.profileChildren
                                                .myAccountChildren.formAbsolute,
                                              {
                                                replace: true,
                                                state: { formStep: "4" },
                                              }
                                            );
                                          }}
                                        >
                                          <InlineIcon
                                            icon="akar-icons:link-out"
                                            fontSize={12}
                                          />
                                          Santé et handicap
                                        </b>
                                        :
                                      </>
                                    )}
                                    <b className="value">{x.value}</b>
                                  </li>
                                )
                              )}
                            </ul>
                            <br />
                            <strong>
                              {" "}
                              Veuillez apporter les corrections adéquates à vos
                              modifications pour qu'elles puissent être prises
                              en compte.
                            </strong>
                            <br />
                            Avant l'approbation de vos modifications , votre
                            profil sera inaccessible aux autres membres
                            <br />
                          </>
                        </>
                      ) : (
                        <>
                          <AlertTitle>Profil en cours de modération</AlertTitle>
                          <>
                            Les modifications apportées à votre profil sont
                            toujours <b> en cours de vérification</b> par notre
                            équipe de modération.
                            <br />
                            Avant l'approbation de vos modifications , votre
                            profil sera inaccessible aux autres membres.
                            <br />
                          </>
                        </>
                      )}
                    </>
                  )}
                  {isUserProfileModificationPending && (
                    <>
                      Vous avez effectué des modifications dans votre profil.
                      <br />
                      <strong>
                        {" "}
                        vos modifications sont donc en cours de modération
                      </strong>
                      <br />
                      Durant la modération, votre profil est inaccessible aux
                      autres membres
                      <br />
                    </>
                  )}
                </Alert>
              </Collapse>
            )}

            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={valueTabs}
                  onChange={handleChangeTabs}
                  aria-label="basic tabs example"
                  variant={matches_down_md ? "scrollable" : "fullWidth"}
                  indicatorColor="primary"
                >
                  <Tab
                    icon={
                      <InlineIcon
                        className="icon-tabs"
                        icon={`bi:1-circle${valueTabs === 0 ? "-fill" : ""}`}
                      />
                    }
                    iconPosition="start"
                    label="Votre profil"
                    id="simple-tab-0"
                    aria-controls="simple-tabpanel-0"
                  />
                  <Tab
                    icon={
                      <InlineIcon
                        className="icon-tabs"
                        icon={`bi:2-circle${valueTabs === 1 ? "-fill" : ""}`}
                      />
                    }
                    iconPosition="start"
                    label="Aspect Réligieux"
                    id="simple-tab-1"
                    aria-controls="simple-tabpanel-1"
                  />
                  <Tab
                    icon={
                      <InlineIcon
                        className="icon-tabs"
                        icon={`bi:3-circle${valueTabs === 2 ? "-fill" : ""}`}
                      />
                    }
                    iconPosition="start"
                    label="Pratiques réligieuses"
                    id="simple-tab-2"
                    aria-controls="simple-tabpanel-2"
                  />
                  <Tab
                    icon={
                      <InlineIcon
                        className="icon-tabs"
                        icon={`bi:4-circle${valueTabs === 3 ? "-fill" : ""}`}
                      />
                    }
                    iconPosition="start"
                    label="Santé et handicap"
                    id="simple-tab-3"
                    aria-controls="simple-tabpanel-3"
                  />
                </Tabs>
              </Box>
              <TabPanel value={valueTabs} index={0}>
                <ProfilStep chooseStep={setValueTabs} scrollTop={scrollTop} />
              </TabPanel>
              <TabPanel value={valueTabs} index={1}>
                <AspectStep chooseStep={setValueTabs} scrollTop={scrollTop} />
              </TabPanel>
              <TabPanel value={valueTabs} index={2}>
                <PratiquesStep
                  chooseStep={setValueTabs}
                  scrollTop={scrollTop}
                />
              </TabPanel>
              <TabPanel value={valueTabs} index={3}>
                <SanteStep chooseStep={setValueTabs} scrollTop={scrollTop} />
              </TabPanel>
            </Box>
          </div>
        </div>
      </div>
      {openModerationReport && (
        <CModalRejectedModif
          open={openModerationReport}
          setOpen={setOpenModerationReport}
        />
      )}
    </>
  );
};

export default Formulaire;
