import React, { useEffect, useRef } from "react";
import "./TextDisplayer.styles.scss";
import classNames from "classnames";

type TTextDisplayerProps = {
  value: string;
  rows?: number;
  className?: string;
  style?: React.CSSProperties | undefined;
  forMessage?: boolean;
  maxLength?: number;
};

const TextDisplayer: React.FC<TTextDisplayerProps> = ({
  value,
  rows = 4,
  className = "",
  style,
  forMessage = false,
  maxLength,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      (textAreaRef.current as HTMLTextAreaElement).style.height = "0px";
      const scrollHeight = (textAreaRef.current as HTMLTextAreaElement)
        .scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      (textAreaRef.current as HTMLTextAreaElement).style.height =
        scrollHeight + 30 + "px";
    }
  }, [textAreaRef, value]);

  return (
    <textarea
      className={classNames("c-text-displayer " + className)}
      style={style}
      readOnly
      ref={textAreaRef}
      maxLength={maxLength}
      value={value}
    ></textarea>
  );
};

export default TextDisplayer;
