import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { InlineIcon } from "@iconify/react";
import arrowDown from "@iconify/icons-ep/arrow-down";
import { TMenu } from "../../types";
import useToggle from "@/hooks/useToggleWithTransition.hooks";
import { SideMenuContainer, SideMenuHeader } from "./SideMenu.styles";
import { useAppDispatch } from "@/store";
import { toggleSidebar } from "@/store/reducers/sidebar/sidebar.actions";
import SideMenuLink from "./SideMenuLink";
import useUserRestrictions from "@/hooks/useUserRestriction";

interface SideMenuProps {
  menu: TMenu;
  indexMenuOpen: number | null;
  myIndex: number;
  setIndexMenuOpen: React.Dispatch<React.SetStateAction<number | null>>;
}
const SideMenu = ({
  menu,
  indexMenuOpen,
  myIndex,
  setIndexMenuOpen,
}: SideMenuProps) => {
  const { isUserUnableToHaveAccessToPayment } = useUserRestrictions();

  const dispatch = useAppDispatch();
  const sideMenuContainerRef = useRef<HTMLDivElement>(null);

  const routeToResolve = useCallback((): string => {
    let route = "none";
    if (typeof menu.route === "string") {
      route = menu.route;
    }
    return route;
  }, [menu]);

  const resolved = useResolvedPath(routeToResolve());
  const match = useMatch({ path: resolved.pathname, end: !!menu.exact });

  const [isMatch, setIsMatch] = useState<boolean>(false);

  useEffect(() => {
    let flag = window.location.pathname.indexOf(menu.route as string);
    setIsMatch(flag !== -1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const { setToggle, toggle, state, STATES, timeout, setState } = useToggle();

  useEffect(() => {
    if (!!match) {
      setIndexMenuOpen(myIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myIndex === indexMenuOpen && !toggle) {
      setToggle(true);
    } else if (myIndex !== indexMenuOpen && toggle) {
      setToggle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myIndex, indexMenuOpen]);

  useEffect(() => {
    if (state === STATES.CLOSING && sideMenuContainerRef.current) {
      sideMenuContainerRef.current.style.height = "";
      const timer = setTimeout(() => {
        setState(STATES.CLOSE);
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    } else if (state === STATES.OPENING && sideMenuContainerRef.current) {
      // At same time
      sideMenuContainerRef.current.style.height = "unset"; //1 => remove height,
      const height =
        sideMenuContainerRef.current.getBoundingClientRect().height; //2 => get the height before DOM recalculate (repaint),
      sideMenuContainerRef.current.style.height = ""; // 3 => remove height property for easily create animation after DOM recalculate

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      document.body.offsetHeight; // Force DOM recalculate

      sideMenuContainerRef.current.style.height = height + "px";

      const timer = setTimeout(() => {
        setState(STATES.OPEN);
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, timeout]);

  if (menu.exact === true) {
    return (
      <SideMenuLink
        menu={menu}
        //isActive={!!match}
        isActive={isMatch}
        onClick={() => {
          setIndexMenuOpen(null);
          dispatch(toggleSidebar());
        }}
      />
    );
  }

  return (
    <SideMenuContainer
      duration={timeout}
      open={state !== STATES.CLOSE || !!match}
      ref={sideMenuContainerRef}
      className={
        isUserUnableToHaveAccessToPayment && menu.label === "Mon compte"
          ? "not_display"
          : ""
      }
    >
      <SideMenuHeader
        onClick={() =>
          setIndexMenuOpen(myIndex !== indexMenuOpen ? myIndex : null)
        }
      >
        {menu.label}
        {""}
        <InlineIcon
          icon={arrowDown}
          rotate={!toggle && state === STATES.CLOSE ? 3 : 0}
        />
      </SideMenuHeader>
      {menu.children && (
        <ul>
          {menu.children.map((submenu, idx) => (
            <li
              key={`submenu-${idx}`}
              onClick={() => {
                dispatch(toggleSidebar());
              }}
            >
              <NavLink to={`${menu.route}${submenu.route}`}>
                {submenu.label}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </SideMenuContainer>
  );
};

export default SideMenu;
